import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { Axios } from '../axios'
import { POST_BASKET, URL } from '../settings/endpoints'
import { IBasketRes, ICleanBasketRes } from '../types/models'
import { axiosBaseQuery } from './config'

export default class BasketService {
  static async postBasket(productId: number, productQ: number) {
    return await Axios.post(POST_BASKET, {
      productId: productId,
      quantity: productQ,
    })
  }
}

export const basketApi = createApi({
  reducerPath: 'basketApi',
  baseQuery: axiosBaseQuery({ baseUrl: URL }),
  tagTypes: ['Basket'],
  refetchOnMountOrArgChange: true,
  endpoints: build => ({
    getBasket: build.query<IBasketRes, string | unknown>({
      query: sort => ({
        url: '/basket',
        params: {
          sort,
        },
      }),
      providesTags: ['Basket'],
    }),
    postProductBasket: build.mutation<unknown, { productId: number; quantity: number }>({
      query: arg => ({
        url: '/basket',
        method: 'POST',
        data: { ...arg },
      }),
      invalidatesTags: ['Basket'],
    }),
    putProductBasket: build.mutation<unknown, { id: number; quantity: number }>({
      query: arg => ({
        url: `/basket/${arg.id}`,
        method: 'PUT',
        data: { quantity: arg.quantity },
      }),
      invalidatesTags: ['Basket'],
    }),
    deleteProductBasket: build.mutation<unknown, number>({
      query: id => ({
        url: `/basket/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Basket'],
    }),
    refetchBasket: build.mutation<unknown, unknown>({
      query: () => ({
        url: `/basket/null`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Basket'],
    }),
    cleanBasket: build.mutation<ICleanBasketRes, void>({
      query: () => ({
        url: '/basket/clean',
        method: 'GET',
      }),
      invalidatesTags: ['Basket'],
    }),
  }),
})
