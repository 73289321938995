import React from 'react'
import './PhotoHover.scss'

interface PhotoHover {
  pictureUrl?: string
}

export const PhotoHover: React.FC<PhotoHover> = ({ pictureUrl }) => {
  return (
    <div className='photo-hover'>
      <div className='photo-hover__diamond' />
      <div className='photo-hover__content'>
        <img src={pictureUrl} alt='img' height='282' />
      </div>
    </div>
  )
}
