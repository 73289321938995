import React, { ChangeEvent, FormEvent, useState } from 'react'
import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import './Feedback.scss'
import axios from 'axios'
import { useCustomSnackbar } from '../../hooks/useSnackbar'
import { Axios } from '../../axios'
import { FEEDBACK } from '../../settings/endpoints'
import Loader from '../../commons/Loader/Loader'

const Input = styled('input')({
  display: 'none',
})

export const Feedback = (): JSX.Element => {
  const { successSnack, errorSnack } = useCustomSnackbar()
  const [file, setFile] = useState<File>(null)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setTitle(value)
  }

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target
    setMessage(value)
  }

  const handleBlur = () => {
    if (message) {
      setError(false)
    } else {
      setError(true)
    }
  }

  const resetForm = () => {
    setMessage('')
    setTitle('')
    setFile(null)
  }

  const handleSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files[0]) {
      setFile(event.currentTarget.files[0])
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!message) {
      setError(true)
      return
    }

    setIsLoading(true)
    const formData = new FormData()
    formData.append('message', message)
    if (title) {
      formData.append('title', title)
    }
    if (file) {
      formData.append('file', file)
    }

    try {
      const res = await Axios.post(FEEDBACK, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (res.data.result) {
        successSnack('Сообщение отправлено!')
        resetForm()
      }
    } catch (e: unknown) {
      if (axios.isAxiosError(e)) {
        const errorMessage = e.response?.data?.message || 'Произошла ошибка'
        errorSnack(errorMessage)
        console.error('Ошибка: ', e?.response)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className='feedback'>
        <div className='feedback__title'>Обратная связь</div>
        <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit}>
          <Box mb={3}>
            <Box component='label' mb={1} display='inline-block'>
              Тема
            </Box>
            <div className='feedback__input'>
              <input placeholder='Тема сообщения' name='title' value={title} onChange={handleTitleChange} />
            </div>
          </Box>
          <Box mb={3}>
            <Box component='label' mb={1} display='inline-block'>
              Сообщение
              <Box component='span' sx={{ color: '#f23b2f' }}>
                &nbsp;*
              </Box>
            </Box>
            <div className={error ? 'feedback__input feedback__input_error' : 'feedback__input'}>
              <textarea
                placeholder='Сообщение'
                name='message'
                value={message}
                onChange={handleMessageChange}
                onBlur={handleBlur}
              />
              {error && <span className='feedback__error'>Поле обязательно</span>}
            </div>
          </Box>
          <Box mb={3}>
            <label htmlFor='contained-button-file'>
              <Input accept='image/*' id='contained-button-file' type='file' onChange={handleSelectFile} />
              <Box display='inline-flex' alignItems='center'>
                <Button component='span'>Добавить файл</Button>
                <Box component='span' ml={1}>
                  {file?.name}
                </Box>
              </Box>
            </label>
          </Box>
          <Box display='flex' justifyContent='flex-end'>
            <Button type='submit' variant='contained'>
              Отправить
            </Button>
          </Box>
        </Box>
      </div>
    </>
  )
}
