import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface OrdersState {
  isClearRows: boolean
}

const initialState: OrdersState = {
  isClearRows: false,
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setIsClearRows: (state, action: PayloadAction<boolean>) => {
      state.isClearRows = action.payload
    },
  },
})

export const ordersReducer = ordersSlice.reducer

export const { setIsClearRows } = ordersSlice.actions
