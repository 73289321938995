import { IResponce } from '../commons'
import { IProductDetails } from './product'

export interface IBackground {
  id: number
  url: string
  path: string
  originalName: string
}

export interface IBackgroundRes extends IResponce {
  result: IBackground
}

export interface IWidget {
  title: string
  data: IProductDetails[]
}

export interface IWidgetRes extends IResponce {
  result: IWidget[]
}

export interface IInformer {
  id: number
  name: string
  description: string
  activeTo?: number
  sort: number
  icon?: string
  link?: string
  link_text?: string
}

export interface IInformerRes extends IResponce {
  result: IInformer[]
}

export const BANNER_HIDE_IDS = 'BANNER_HIDE_IDS'
