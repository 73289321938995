import { useImport } from '../../hooks/useImport'
import { FileUpload } from '../fileUpload'
import './UploadExcelButton.scss'

export const UploadExcelButton = (): JSX.Element => {
  const { inputRef, file, setFile, stepUpload, setStepUpload, onFileChange, onUploadClick } = useImport()

  const onFileUploadClose = () => {
    setFile(null)
  }

  return (
    <>
      <button className='upload-excel-button' onClick={onUploadClick}>
        Загрузить заказ из Excel
      </button>
      <input
        className='file-upload__input'
        type='file'
        accept='.xls,.xlsx'
        multiple={false}
        ref={inputRef}
        onChange={onFileChange}
      />
      <FileUpload
        open={!!file}
        file={file}
        setFile={setFile}
        stepUpload={stepUpload}
        setStepUpload={setStepUpload}
        handleClose={onFileUploadClose}
      />
    </>
  )
}
