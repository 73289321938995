import { createAsyncThunk } from '@reduxjs/toolkit'
import { Axios } from '../../axios'
import { INFORMER } from '../../settings/endpoints'
import { IInformer, IInformerRes } from '../../types/models/info'

export const getInformers = createAsyncThunk<IInformer[], void, { rejectValue: string }>(
  'info/getInformers',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await Axios.get<IInformerRes>(INFORMER)

      return data.result
        .sort((a, b) => a.sort - b.sort)
        .filter(banner => banner.activeTo * 1000 && Date.now() <= banner.activeTo * 1000)
    } catch (e) {
      return rejectWithValue('При загрузке баннеров произошла ошибка')
    }
  },
)
