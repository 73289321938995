import React from 'react'
import './OrderDetails.scss'
import { IOrder, IOrderProps } from '../../types/models'
import { formatNumber } from '../../utils/formatNumber'

interface OrderDetails {
  order: IOrder
  productCount: number
}

export const OrderDetails: React.FC<OrderDetails> = ({ order, productCount }) => {
  const getOrderPropValue = (code: IOrderProps['code']) => {
    return order?.props?.find(prop => prop.code === code)?.value
  }

  return (
    <div className='order-details'>
      <div className='order-details__title'>Детали заказа</div>
      <div className='table'>
        <div className='table__row'>
          <div>Кол-во уникальных позиций</div>
          <div>{productCount}</div>
        </div>
        <div className='table__row'>
          <div>Кол-во товаров общее</div>
          <div>{productCount}</div>
        </div>
        {/*<div className='table__row'>*/}
        {/*  <div>Общий вес заказа</div>*/}
        {/*  <div>## кг</div>*/}
        {/*</div>*/}
        <div className='table__row'>
          <div>Дата и время создания</div>
          <div>{order?.dateCreate}</div>
        </div>
        <div className='table__row'>
          <div>Город</div>
          <div>{getOrderPropValue('CITY')}</div>
        </div>
        <div className='table__row'>
          <div>Сотрудник</div>
          <div>{getOrderPropValue('CONTACT_PERSON')}</div>
        </div>
        <div className='table__row'>
          <div>Юр. лицо</div>
          <div>{getOrderPropValue('COMPANY')}</div>
        </div>
        <div className='table__row'>
          <div>Желаемая дата получения</div>
          <div>{getOrderPropValue('DATE_DELIVERY')}</div>
        </div>
        {/*<div className='table__row'>*/}
        {/*  <div>Дата отгрузки товара</div>*/}
        {/*  <div>###</div>*/}
        {/*</div>*/}
        {/*<div className='table__row'>*/}
        {/*  <div>Дата получения товара</div>*/}
        {/*  <div>###</div>*/}
        {/*</div>*/}
        {/*<div className='table__row'>*/}
        {/*  <div>Использовано ВАУ</div>*/}
        {/*  <div className='wow'>### ВАУ</div>*/}
        {/*</div>*/}
        <div className='table__row'>
          <div>Сумма к оплате, руб.</div>
          <div>{formatNumber(order?.price)} ₽</div>
        </div>
        <div className='table__row'>
          <div>НДС, руб.</div>
          <div>{formatNumber(order?.priceTax)} ₽</div>
        </div>
        {/*<div className='table__row'>*/}
        {/*  <div>ВАУ к начислению</div>*/}
        {/*  <div className='wow'>### ВАУ</div>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}
