import React, { memo, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import ClipLoader from 'react-spinners/ClipLoader'
import { css } from '@emotion/react'
import { BasketIcon } from '../../Icons'
import { DefaultButton } from './DefaultButton'

interface BasketButton {
  onClick: () => any
  unitOfMeasure?: string
  count?: number | null
  hover?: boolean
  isAddBasketLoading?: boolean
}

const override = css`
  position: absolute;
`

export const BasketButton: React.FC<BasketButton> = memo(
  ({ onClick, count, hover, unitOfMeasure, isAddBasketLoading = false }) => {
    const [isClicked, setIsClicked] = useState(false)

    useEffect(() => {
      if (isAddBasketLoading === false) {
        setIsClicked(false)
      }
    }, [isAddBasketLoading])

    return (
      <>
        <DefaultButton
          onClick={event => {
            event.preventDefault()
            onClick()
            setIsClicked(true)
          }}
          variant='outlined'
          fullWidth
          sx={{
            transition: 'unset',
            padding: '4px 8px',
            background: count ? '#E5E5E5' : '',
            borderColor: count ? '#E5E5E5' : '',
            height: 38,
          }}
          color={hover ? 'primary' : 'secondary'}
          boxShadow={false}
          // style={isAddBasketLoading && isClicked ? { opacity: '0.5' } : {}}
        >
          {count ? (
            <>
              <Box style={isAddBasketLoading && isClicked ? { opacity: '0.2' } : {}}>
                <Box fontSize='10px' lineHeight='13px' color='text.secondary'>
                  В корзине
                </Box>
                <Box fontSize='12px' lineHeight='15px'>
                  {count} {unitOfMeasure}.
                </Box>
              </Box>
              <ClipLoader color='black' loading={isAddBasketLoading && isClicked} css={override} size={15} />
            </>
          ) : (
            <>
              <BasketIcon hover={hover} />
              <ClipLoader color='black' loading={isAddBasketLoading && isClicked} css={override} size={15} />
            </>
          )}
        </DefaultButton>
      </>
    )
  },
)
