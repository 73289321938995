import React, { useState } from 'react'
import { Field, Formik, FormikHelpers as FormikActions } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import { CheckBox } from '../../components/Input'
import './Login.scss'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import loader from '../../assets/svg/loaders/rectangleBlackLoader.svg'
import { authSlice } from '../../redux/reducers/authReducer'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { fetchUser } from '../../redux/actions/authActions'
import { LOGIN } from '../../settings/endpoints'
import { useCustomSnackbar } from '../../hooks/useSnackbar'
import { setAccessToken } from '../../utils/tokens'
import { Axios } from '../../axios'
import { BasicModal } from '../../commons/mui/BasicModal'
import { ForgotPassword } from '../../components/ForgotPassword'
import banner from '../../assets/svg/bunners/longBanner.svg'

interface Values {
  login: string
  password: string
  rememberMe: boolean
  captcha: string
}

export const Login = () => {
  document.title = 'Авторизация'

  const { setAuth, setCaptcha } = authSlice.actions
  const dispatch = useAppDispatch()
  const { captcha } = useTypedSelector(state => state.authReducer)
  const [isLoading, setIsLoading] = useState(false)
  const [forgotModalOpen, setForgotModalOpen] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const { errorSnack } = useCustomSnackbar()

  const handleSubmit = async (values: Values, actions: FormikActions<Values>) => {
    try {
      setIsLoading(true)

      const res = await Axios.post(LOGIN, {
        login: values.login,
        password: values.password,
        captcha_sid: captcha.captchaSid,
        captcha_word: values.captcha,
        remember: values.rememberMe ? 'Y' : 'N',
      })

      if (res.data) {
        dispatch(setAuth(res.data.result.Authorization))
        if (values.rememberMe) {
          setAccessToken(res.data.result.Authorization)
        } else {
          setAccessToken(res.data.result.Authorization, true)
        }
        dispatch(fetchUser())

        history.push('/')

        // @ts-ignore
        let { from } = location.state || { from: { pathname: '/' } }
        history.replace(from)
      }

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      if (err.response.status === 400) {
        errorSnack(err?.response?.data?.message || 'Введен неверный логин или пароль')
        actions.resetForm()
      }
      if (err.response.status === 402) {
        actions.resetForm()
        errorSnack('Введите символы с картинки')
        dispatch(
          setCaptcha({
            captchaSid: err.response.data.result.captcha_sid,
            url: err.response.data.result.captcha_image,
          }),
        )
      }
    }
  }

  const validate = values => {
    const errors = {}

    if (!values.login) {
      errors['login'] = 'Введите логин'
    }
    if (!values.password) {
      errors['password'] = 'Введите пароль'
    }
    if (captcha.url && !values.captcha) {
      errors['captcha'] = 'Введите символы'
    }
    return errors
  }

  const submitEmail = () => {
    setForgotModalOpen(false)
  }

  return (
    <div className='login'>
      <Box mx={'auto'} mb={'48px'} mt={'10vh'} maxWidth={'468px'}>
        <a href={'https://www.informat.ru/personal/login/'}>
          <img alt={'old_personal_login'} src={banner} style={{ width: '100%', height: 'auto' }} />
        </a>
      </Box>
      <Formik
        initialValues={{ login: '', password: '', rememberMe: true, values: false, captcha: '' }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ handleSubmit, isSubmitting, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <div className='login__form'>
              <label htmlFor='login'>Логин</label>
              <Field
                id='login'
                className={touched.login && errors.login ? 'input input_error' : 'input'}
                type='text'
                name='login'
              />
              <label htmlFor='password'>Пароль</label>
              <Field
                id='password'
                type='password'
                name='password'
                className={touched.password && errors.password ? 'input input_error' : 'input'}
                autoComplete='on'
              />
              <Grid container spacing={2} justifyContent='space-between'>
                <Grid item>
                  <div className='remember-me'>
                    <CheckBox id='rememberMe' name='rememberMe' style='checkbox' formik />
                    <label htmlFor='rememberMe'>Запомнить меня</label>
                  </div>
                </Grid>
                <Grid item>
                  <Box
                    sx={{ cursor: 'pointer', fontWeight: 600, fontSize: 14 }}
                    onClick={() => setForgotModalOpen(true)}
                  >
                    Забыли пароль?
                  </Box>
                </Grid>
              </Grid>
              {captcha.url && (
                <div className='captcha'>
                  <img src={captcha.url} alt='captcha' />
                  <Field
                    id='captcha'
                    className={touched.captcha && errors.captcha ? 'input input_error' : 'input'}
                    type='text'
                    name='captcha'
                    placeholder='Введите символы с изображения...'
                  />
                </div>
              )}
              <div className='submit'>
                <button type='submit' className='submit__btn' disabled={isSubmitting}>
                  {isLoading ? <img src={loader} alt='' height='30' width='30' /> : 'Вход'}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <BasicModal open={forgotModalOpen} handleClose={() => setForgotModalOpen(false)}>
        <ForgotPassword submitEmail={submitEmail} />
      </BasicModal>
    </div>
  )
}
