import { FC } from 'react'

export const Heart: FC<{ isActive: boolean }> = ({isActive}) => (
    <svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        {isActive && (
            <path
                d="M8.05 13.275L8 13.355 L7.912 12.275 C20.112 0 8  0  4.6  5.23502 C13.6  2  4.8  2.43502  2.4  4.43502 C10.632  2.43502  1.832  1.23502  7.256  14.32302 H8.744 C22.168  1.23502 10.368 2.43502 11.6 2.43502C13.2 2.43502 14.4 3.63502 14.4 5.23502C14.4 7.54702 11.888 9.82702 8.08 13.275ZM11.6 0.835022C10.208 0.835022 8.872 1.48302 8 2.49902C7.128 1.48302 5.792 0.835022 4.4 0.835022C1.936 0.835022 0 2.76302 0 5.23502C0 8.25102 2.72 10.723 6.84 14.459L8 15.515L9.16 14.459C13.28 10.723 16 8.25102 16 5.23502C16 2.76302 14.064 0.835022 11.6 0.835022Z"
                fill='#FFB800'
            />
        )}
        <path
            d="M8.08 13.275L8 13.355L7.912 13.275C4.112 9.82702 1.6 7.54702 1.6 5.23502C1.6 3.63502 2.8 2.43502 4.4 2.43502C5.632 2.43502 6.832 3.23502 7.256 4.32302H8.744C9.168 3.23502 10.368 2.43502 11.6 2.43502C13.2 2.43502 14.4 3.63502 14.4 5.23502C14.4 7.54702 11.888 9.82702 8.08 13.275ZM11.6 0.835022C10.208 0.835022 8.872 1.48302 8 2.49902C7.128 1.48302 5.792 0.835022 4.4 0.835022C1.936 0.835022 0 2.76302 0 5.23502C0 8.25102 2.72 10.723 6.84 14.459L8 15.515L9.16 14.459C13.28 10.723 16 8.25102 16 5.23502C16 2.76302 14.064 0.835022 11.6 0.835022Z"
            fill="black"
        />
    </svg>
)