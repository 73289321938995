import React, { FC, useState } from 'react'
import { newsApi } from '../../api/NewsService'
import Loader from '../../commons/Loader/Loader'
import { NewsItem } from '../../components/NewsItem/NewsItem'
import { TableTools } from '../../containers/bottomTools'
import { BackgroundLayout } from '../../layouts/backgroundLayout'
import './News.scss'

export const News: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(20)

  const { data, isFetching } = newsApi.useGetNewsListQuery({
    currentPage,
    perPage,
  })

  const news = data?.result.data || []

  return (
    <BackgroundLayout>
      <Loader isLoading={isFetching} />
      <div className='news'>
        <h2 className='news__title'>Новости</h2>
        <div className='news__content'>
          <ul className='news__grid'>
            {news.map(item => (
              <NewsItem
                key={item.id}
                id={item.id}
                name={item.name}
                activeFrom={item.activeFrom}
                previewPicture={item.picture}
              />
            ))}
          </ul>
        </div>
        <div className='catalog-content__bottom-tools'>
          <TableTools
            pagination={{
              currentPage,
              perPage,
              totalPages: data?.result.pagination.totalPages || 0,
              totalRows: data?.result.pagination.totalRows,
            }}
            onPageChange={newPage => setCurrentPage(newPage)}
            onPerPageChange={newPerPage => setPerPage(newPerPage)}
          />
        </div>
      </div>
    </BackgroundLayout>
  )
}
