import React from 'react'
import Popup from 'reactjs-popup'
import './Select.scss'
import { PopupActions } from 'reactjs-popup/dist/types'

export interface Select {
  currentValue?: number
  dropdownDirection?: 'up' | 'down'
  handleSelect: (value: number) => void
  data: number[]
  optionsPadding: string
  optionsWidth: string
}

export const Select: React.FC<Select> = ({ currentValue, dropdownDirection, data, optionsWidth, handleSelect }) => {
  const buttonClassNames =
    'select-button' + ' ' + (dropdownDirection === 'up' && 'select-button_up-arrow') ||
    (dropdownDirection === 'down' && 'select-button_down-arrow')

  const handleClick = (close: PopupActions['close'], value: number) => {
    handleSelect(value)
    close()
  }

  const contentStyle = { width: `${optionsWidth}px` }

  return (
    <div className='select'>
      <Popup
        trigger={<button className={buttonClassNames}>{currentValue}</button>}
        position='top center'
        arrow={false}
        {...{ contentStyle }}
      >
        {close => (
          <div className='select-options'>
            <div className='select-options__container'>
              {data.map((value, index) => (
                <div className='select-options__item' onClick={() => handleClick(close, value)} key={index}>
                  <span>{value}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </Popup>
    </div>
  )
}
