import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { URL } from '../settings/endpoints'
import { IWidgetRes } from '../types/models/info'
import { axiosBaseQuery } from './config'

export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: axiosBaseQuery({ baseUrl: URL }),
  tagTypes: ['Widgets'],
  endpoints: build => ({
    getWidgets: build.query<IWidgetRes, void>({
      query: () => ({
        url: '/info/main',
      }),
      providesTags: result => ['Widgets'],
    }),
  }),
})
