import React, { useState } from 'react'
import './ConformButtonGroup.scss'

export const ConformButtonGroup = () => {
  const [status, setStatus] = useState({ accept: false, reject: false })

  const acceptHandler = () => {
    setStatus({ ...status, accept: true, reject: false })
  }

  const rejectHandler = () => {
    setStatus({ ...status, accept: false, reject: true })
  }

  return (
    <div className='conform-button-group'>
      <button
        className={`${
          (status.reject && 'accept accept_passive') ||
          (status.accept && 'accept accept_active') ||
          (!status.accept && 'accept')
        }`}
        onClick={acceptHandler}
      >
        Согласовать
      </button>
      <button className={`${status.reject ? 'reject reject_active' : 'reject'}`} onClick={rejectHandler}>
        Отклонить
      </button>
    </div>
  )
}
