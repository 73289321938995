import { authSlice } from '../reducers/authReducer'
import { Axios } from '../../axios'
import { GET_USER } from '../../settings/endpoints'
import { AppDispatch } from '../reducers'

export const fetchUser = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await Axios.get(GET_USER)
      dispatch(authSlice.actions.setUser(response.data.result))
    } catch (err) {
      return err
    }
  }
}

// export const fetchUser = createAsyncThunk('user', async (_, thunk) => {
//   const response = await Axios.get(GET_USER)
//   return response.data.result
// })
