import { Box, Typography } from '@mui/material'
// import React, { MouseEvent, useState } from 'react'
// import ClipLoader from 'react-spinners/ClipLoader'
// import { basketApi } from '../../api/BasketService'
// import { BasketIcon } from '../../commons/Icons'
// import { DefaultButton } from '../../commons/mui/Button/DefaultButton'
import { ClipLoader } from 'react-spinners'
import React, {useState} from 'react'
import { TextFormat } from '../../commons/TextFromat/TextFormat'
import { ISearchOffer } from '../../types/models/search'
import { useCatalog } from '../../hooks/useCatalog'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { FullProductCard } from '../../containers/fullProductCard'
import { getBasketCount } from '../../utils/getBasketCount'
import { BasicModal } from '../../commons/mui/BasicModal'
// import { formatNumber } from '../../utils/formatNumber'

interface SearchCompletionsItemProps {
  offer: ISearchOffer
  onOfferClick: () => void
}

// const override = css`
//   position: absolute;
// `

export const SearchCompletionsItem = ({ offer, onOfferClick }: SearchCompletionsItemProps): JSX.Element => {
  // const [isHover, setIsHover] = useState<boolean>(false)
  // const [addToBasket, { isLoading }] = basketApi.usePostProductBasketMutation()

  // const onBasketClick = (event: MouseEvent<HTMLButtonElement>) => {
  //   event.stopPropagation()
  //   if (!isLoading) {
  //     addToBasket({ productId: offer.id, quantity: 1 })
  //   }
  // }
  const { products } = useTypedSelector(state => state.productReducer)

  const { fetchProduct } = useActions()

  const {
    isFullProductCard,
    location,
    productQ,
    basketList,
    isAddBasketLoading,
    openFullProductCard,
    setProductQHandler,
    setProductQuantity,
    addToBasket,
    product,
    isLoading
  } = useCatalog(products)

  const [isClicked, setIsClicked] = useState(false)

  const onItemClick = async () => {
    if (!isFullProductCard) {
      setIsClicked(true)
      await openFullProductCard(offer.id,false)
      onOfferClick()
    }
  }

  const handleCloseFullCard = async () => {
    await openFullProductCard(undefined,false)
  }
  return (
    <div key={offer.id} className='search-completions__offer' onClick={onItemClick}>
      <div className='search-completions__offer-image' style={{ backgroundImage: `url(${offer.image_url})` }} />
      <div className='search-completions__offer-content'>
        <TextFormat text={offer.text.html} stringNumber={2} isInner />
        <Box display='flex' alignItems='center' justifyContent='space-between' mt='2px'>
          <Typography fontSize={12} sx={{ opacity: 0.4 }}>
            {offer.externalId}
          </Typography>
          {/*todo временно скрыта цена и кнопка в корзину (https://bv.intradesk.ru/task/list/232392)*/}
          {/*<Box display='flex' alignItems='center'>*/}
          {/*  <Typography>{formatNumber(offer.price)} ₽</Typography>*/}
          {/*  {offer.price !== offer.priceDiscount && (*/}
          {/*    <Typography className='search-completions__offer-old-price' fontSize={12} ml={1}>*/}
          {/*      {formatNumber(offer.priceDiscount)} ₽*/}
          {/*    </Typography>*/}
          {/*  )}*/}
          {/*</Box>*/}
        </Box>
      </div>
      {/*{offer.id && (*/}
      {/*  <DefaultButton*/}
      {/*    sx={{*/}
      {/*      transition: 'unset',*/}
      {/*      padding: '4px 8px',*/}
      {/*      height: 38,*/}
      {/*      marginLeft: 2,*/}
      {/*    }}*/}
      {/*    variant='outlined'*/}
      {/*    color={isHover ? 'primary' : 'secondary'}*/}
      {/*    boxShadow={false}*/}
      {/*    onClick={onBasketClick}*/}
      {/*    onMouseEnter={() => setIsHover(true)}*/}
      {/*    onMouseLeave={() => setIsHover(false)}*/}
      {/*  >*/}
      {/*    <BasketIcon hover={isHover} />*/}
      {/*    <ClipLoader color='black' loading={isLoading} css={override} size={15} />*/}
      {/*  </DefaultButton>*/}
      {/*)}*/}
      <ClipLoader color='black' loading={isLoading && isClicked} size={10} />
      <BasicModal handleClose={ handleCloseFullCard } open={!!isFullProductCard}>
        <FullProductCard
            product={product}
            productQ={productQ[product.id]}
            productLocation={location}
            setProductQ={setProductQHandler}
            addToBasket={addToBasket}
            setProductQuantity={setProductQuantity}
            basketCount={getBasketCount(product, basketList)}
            isAddBasketLoading={isAddBasketLoading}
            handleClose={handleCloseFullCard}
            basketId={basketList.find(item => item.productId === product.id)?.id}
        />
      </BasicModal>
    </div>
  )
}
