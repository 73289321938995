import React, { useEffect, useRef, useState, ChangeEvent } from 'react'
import { CSSTransition } from 'react-transition-group'
import './ButtonQ.scss'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

interface IButtonQ {
  onChange: (productId: number, value: number) => void
  onPlusClick?: (count: number) => void
  onMinusClick?: (count: number) => void
  productId: number
  value: number
  defaultControlValue: number
  packages: string[]
  unitOfMeasure: string
  isBasket?: boolean
  hover?: boolean
}

export const ButtonQ: React.FC<IButtonQ> = ({
  onChange,
  productId,
  value,
  hover,
  packages,
  unitOfMeasure,
  defaultControlValue,
  onPlusClick,
  onMinusClick,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [controlValue, setControlValue] = useState<number>()
  const ref = useRef(null)
  const buttonRef = useRef(null)

  useOnClickOutside(ref, event => {
    if (!buttonRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  })

  const handleSelect = (value: number) => {
    setControlValue(value)
    onChange(productId, value)
    setIsOpen(false)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) >= 0) {
      onChange(productId, Number(event.target.value))

      if (onMinusClick && onPlusClick) {
        setControlValue(Number(event.target.value))
      }
    }
  }

  const onChangeHandler = (currentValue: number) => {
    if (currentValue >= 0 && (!onMinusClick || !onPlusClick)) {
      onChange(productId, currentValue)
    }
  }

  useEffect(() => {
    setControlValue(defaultControlValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultControlValue])

  return (
    <div className={`button-q ${hover ? 'button-q_hover' : ''}`}>
      <button
        className='button-q__minus button'
        onClick={event => {
          event.preventDefault()
          onChangeHandler(value - controlValue)
          onMinusClick && onMinusClick(controlValue)
        }}
      >
        <MinusIco />
      </button>
      <button
        className='button-q__btn button'
        onClick={event => {
          event.preventDefault()
          setIsOpen(!isOpen)
        }}
        ref={buttonRef}
      >
        <input
          className='button-q__input'
          type='number'
          value={value}
          onChange={handleInputChange}
          onWheel={e => e.currentTarget.blur()}
        />
      </button>
      <button
        className='button-q__plus button'
        onClick={event => {
          event.preventDefault()
          onChangeHandler(value + controlValue)
          onPlusClick && onPlusClick(controlValue)
        }}
      >
        <PlusIco />
      </button>
      <CSSTransition in={isOpen} timeout={200} classNames='show-el' unmountOnExit>
        <div className='button-q__select' ref={ref}>
          <div
            className='button-q__dropdown'
            onClick={event => {
              event.preventDefault()
              //event.stopPropagation()
            }}
          >
            {packages?.map((pack, index) => (
              <div onClick={() => handleSelect(Number(pack))} key={index}>
                {pack + ' ' + unitOfMeasure}
              </div>
            ))}
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

const PlusIco = () => (
  <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1L4 4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6H4L4 9C4 9.55228 4.44771 10 5 10C5.55228 10 6 9.55228 6 9V6H9C9.55229 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4H6V1Z'
      fill='black'
    />
  </svg>
)

const MinusIco = () => (
  <svg width='10' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='10' height='2' rx='1' fill='black' />
  </svg>
)
