import React, { useEffect, useState } from 'react'
import './BasketItem.scss'
import { shallowEqual } from 'react-redux'
import { Box } from '@mui/material'
import { basketApi } from '../../../api/BasketService'
import { DefaultButton } from '../../../commons/mui/Button/DefaultButton'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { productActions } from '../../../redux/actions/productActions'
import { ConformButtonGroup } from '../../conformButtonGroup'
import { BasketDownload } from '../../basketDownload'
import { BasketList } from '../BasketList'
import { ButtonAccordion } from '../../../commons/ButtonAccordion'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import {
  IBasket,
  IBasketSortVariant,
  IProduct,
  IProductDetails,
  IProductIds,
  IProductLocation,
  IProductQ,
  IStock,
} from '../../../types/models'
import { FullProductCard } from '../../../containers/fullProductCard'
import { ModalDefault } from '../../../commons/modals/modalDefault'
import { HoverPopover } from '../../../commons/mui/HoverPopover'
import { getBasketCount } from '../../../utils/getBasketCount'
import ProductService, { productApi } from '../../../api/ProductService'
import Loader from '../../../commons/Loader/Loader'
import { isMsk } from '../../../utils/isMsk'
import { formatNumber } from '../../../utils/formatNumber'

interface IBasketItem {
  openFull: () => void
  isFull: boolean
  isIndividual: boolean
  basket: IBasket
  basketProducts: IProductIds
  location: IProductLocation
  stock: IStock
  isWarning: boolean
  setDeleteId: (id: number) => void
  addToBasket: ({}: { productId: number; quantity: number }) => unknown
  setProductQuantity: ({}: { id: number; quantity: number }) => unknown
  currentSort: IBasketSortVariant
  setCurrentSortHandler: (payload: IBasketSortVariant) => void
  openForm: (isOpen: boolean) => void
}

export const BasketItem: React.FC<IBasketItem> = ({
  openFull,
  isFull,
  isIndividual,
  basket,
  location,
  stock,
  isWarning,
  setDeleteId,
  addToBasket,
  setProductQuantity,
  basketProducts,
  currentSort,
  setCurrentSortHandler,
  openForm,
}) => {
  const dispatch = useAppDispatch()

  const user = useTypedSelector(state => state.authReducer.user, shallowEqual)
  const { isBasketFetched } = useTypedSelector(state => state.basketReducer, shallowEqual)

  const [fullProductId, setFullProductId] = useState<number>(null)
  const { data: productData, isFetching: isProductFetching } = productApi.useGetProductByIdQuery(fullProductId, {
    skip: fullProductId === null,
  })

  const [cleanBasket, { isLoading: cleanBasketLoading }] = basketApi.useCleanBasketMutation()

  const product: IProductDetails = productData?.result

  const [productQ, setProductQ] = useState<IProductQ>({ 0: null })

  const setProductQHandler = (productId: number, productQ: number) => {
    const products = {}
    products[productId] = productQ
    setProductQ(prev => ({
      ...prev,
      ...products,
    }))
  }

  const openHandler = async (id: number) => {
    const res = await ProductService.getProductBreadCrumbs(id)
    dispatch(productActions.setProductBreadcrumbs(res.data.result))
    setFullProductId(id)
  }

  useEffect(() => {
    const productQ: IProductQ = {}
    if (isBasketFetched && basketProducts)
      Object.values(basketProducts).forEach((product: IProduct) => (productQ[product.id] = Number(product.packages[0])))
    setProductQ(productQ)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBasketFetched])

  return (
    <div className={isFull ? 'basket-item basket-item_active' : 'basket-item'}>
      <div className='basket-item__container'>
        <div className='item-info'>
          <div className='item-head'>
            {isIndividual ? (
              <div className='item-head__title'>Моя корзина</div>
            ) : (
              <ButtonAccordion content='Моя корзина' isActive={isFull} setIsActive={openFull} />
            )}
            <div className='item-head__info' style={isIndividual ? null : { paddingLeft: '24px' }}>
              <div className='item-head__email'>{user.lastName + ' ' + user.name}</div>
            </div>
          </div>
          <Box display='flex' alignItems='center' p='0 15px'>
            <table className='count-table'>
              <thead>
                <tr>
                  <th className='count-table__count'>Позиций</th>
                  <th className='count-table__price'>Сумма</th>
                  {/* <th className='count-table__limit'>Лимит</th> */}
                  {/* <th className='count-table__limit'></th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Box display='flex' columnGap='10px'>
                      <span>{basket.listCount && basket.listCount}</span>
                      {isWarning && (
                        <HoverPopover
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          trigger={<ErrorIco />}
                        >
                          <Box width='310px' p='14px 31px 27px 30px' textAlign='center'>
                            <Box textAlign='center' fontWeight='fontWeightRegular'>
                              Внимание!
                            </Box>
                            <Box mt='15px' mb='12px'>
                              Некоторые товары, добавленные в корзину ранее, больше не в наличии. Пожалуйста, выберите
                              аналоги.
                            </Box>
                            <DefaultButton onClick={() => openForm(true)} fullWidth color='primary'>
                              Подобрать аналоги
                            </DefaultButton>
                          </Box>
                        </HoverPopover>
                      )}
                    </Box>
                  </td>
                  <td>{basket.priceTotal && `${formatNumber(basket.priceTotal)} ₽`}</td>
                  {/* <td>{basket.limit && `${basket.limit} ₽`}</td> */}
                </tr>
                <tr>
                  <td />
                  <td />
                  {/* <td className='count-table__limit-bar'>
                  <svg width='140' height='4' viewBox='0 0 140 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <rect width='140' height='4' rx='2' fill='#E0E0E0' />
                    <rect width='50.241' height='4' rx='2' fill='#FFB800' />
                  </svg>
                </td> */}
                </tr>
              </tbody>
            </table>
            <DefaultButton
              color='secondary'
              sx={{
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '13px',
              }}
              disabled={cleanBasketLoading}
              onClick={() => cleanBasket()}
            >
              Очистить
              <br />
              корзину
            </DefaultButton>
          </Box>
        </div>

        {!isIndividual && (
          <div className='basket-item__tools-block'>
            <div className='tools-block'>
              <ConformButtonGroup />
              <div className='download'>
                <div className='download-block__left-col'>
                  <div className='title'>Состав корзины</div>
                  <BasketDownload />
                </div>
                <div className='download-block__right-col'>
                  <div className='title'>С характеристиками</div>
                  <BasketDownload />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='basket-item__basket-list'>
        {isFull ||
          (isIndividual && basketProducts && (
            <BasketList
              basketList={basket.list}
              priceTotal={basket.priceTotal}
              priceVat={basket.priceVat}
              setDeleteId={setDeleteId}
              isMsk={isMsk(user.location.city)}
              setProductQuantity={setProductQuantity}
              basketProducts={basketProducts}
              basketLocation={location}
              basketStock={stock}
              onRowClick={openHandler}
              currentSort={currentSort}
              setCurrentSortHandler={setCurrentSortHandler}
            />
          ))}
      </div>
      <Loader isLoading={isProductFetching} />
      <ModalDefault handleOpen={() => setFullProductId(null)} isOpen={!!fullProductId && !isProductFetching}>
        {!!product && (
          <FullProductCard
            product={product}
            productLocation={location[product?.id]}
            setProductQ={setProductQHandler}
            addToBasket={addToBasket}
            setProductQuantity={setProductQuantity}
            productQ={productQ[product?.id]}
            basketCount={getBasketCount(product, basket.list)}
            basketId={basket.list.find(item => item.productId === product?.id)?.id}
          />
        )}
      </ModalDefault>
    </div>
  )
}

const ErrorIco = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z'
      fill='#F23B2F'
    />
  </svg>
)
