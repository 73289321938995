import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Axios } from '../../axios'
import Loader from '../../commons/Loader/Loader'
import { BasicModal } from '../../commons/mui/BasicModal'
import { DefaultButton } from '../../commons/mui/Button/DefaultButton'
import { StyledPaper } from '../../commons/mui/Styled'
import { useBasket } from '../../hooks/useBasket'
import { StepUpload } from '../../hooks/useImport'
import { useCustomSnackbar } from '../../hooks/useSnackbar'
import { IMPORT } from '../../settings/endpoints'
import { IBasketImportData, IBasketImportRes } from '../../types/models'
import { getNoun } from '../../utils/getNoun'
import EyeIcon from './eye.svg'
import './FileUpload.scss'

interface IFileUploadProps {
  open: boolean
  file: File
  setFile: (file: File | null) => void
  stepUpload: StepUpload
  setStepUpload: (stepUpload: StepUpload) => void
  handleClose: () => void
}

type Clean = 'Y' | 'N'

export const FileUpload = ({
  open,
  file,
  setFile,
  setStepUpload,
  stepUpload,
  handleClose,
}: IFileUploadProps): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const { refetch } = useBasket()
  const { errorSnack } = useCustomSnackbar()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [importData, setImportData] = useState<IBasketImportData | null>(null)

  const errorList = importData ? importData.errors.join(', ') : ''
  const errorCount = importData ? importData.errors.length : 0
  const addedCount = importData ? importData.added.length + importData.updated.length : 0

  const onFileSubmit = async (clean: Clean) => {
    if (!file) return

    try {
      setIsLoading(true)
      const formData = new FormData()
      formData.append('file', file)
      formData.append('clean', clean)

      const res = await Axios.post<IBasketImportRes>(IMPORT, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (res.status === 200 && res.data.result) {
        setImportData(res.data.result)
        setStepUpload('finish')
        refetch()
      } else {
        setImportData(null)
      }
    } catch (e) {
      errorSnack('При загрузке произошла ошибка')
      console.log(e)
      onCloseModal()
    } finally {
      setIsLoading(false)
    }
  }

  const onBasketCheck = async (clean: Clean) => {
    await onFileSubmit(clean)
    setStepUpload('finish')
  }

  const onCloseModal = () => {
    handleClose()
    setTimeout(() => {
      setStepUpload('basketCheck')
      setFile(null)
    }, 150)
  }

  const onGoToBasket = () => {
    onCloseModal()
    history.push('/basket')
  }

  const onGoToCatalog = () => {
    handleClose()
    onCloseModal()
    if (location.pathname.includes('basket')) {
      history.push('/catalog')
    }
  }

  const getNounForPosition = (count: number) => {
    return `${count} ${getNoun(count, 'позиция', 'позиции', 'позиций')}`
  }

  useEffect(() => {
    if (stepUpload === 'submitting') {
      const submit = async () => await onFileSubmit('N')
      submit()
    }
  }, [stepUpload])

  return (
    <BasicModal open={open} handleClose={onCloseModal}>
      <StyledPaper>
        <Loader isLoading={isLoading} />
        <form className='file-upload'>
          {stepUpload === 'submitting' && <div className='file-upload__title'>Загрузка...</div>}
          {stepUpload === 'basketCheck' && (
            <>
              <div className='file-upload__title'>В вашей корзине есть товары</div>
              <div className='file-upload__wrapper'>
                <DefaultButton type='button' onClick={() => onBasketCheck('N')}>
                  Добавить к товарам в корзине
                </DefaultButton>
                <DefaultButton type='button' color='secondary' onClick={() => onBasketCheck('Y')}>
                  Очистить корзину
                </DefaultButton>
              </div>
            </>
          )}
          {stepUpload === 'finish' && importData && (
            <div className='file-upload-finish'>
              <div className='file-upload-finish__header'>
                {!!errorCount && (
                  <div className='file-upload-finish__col'>
                    <div className='file-upload-finish__title'>{getNounForPosition(errorCount)}</div>
                    <div className='file-upload-finish__subtitle'>
                      <span>не {getNoun(errorCount, 'распознана', 'распознаны', 'распознано')}</span>
                      <img src={EyeIcon} alt='' />
                    </div>
                  </div>
                )}
                {!!addedCount && (
                  <div className='file-upload-finish__col'>
                    <div className='file-upload-finish__title'>{getNounForPosition(addedCount)}</div>
                    <div className='file-upload-finish__subtitle'>
                      <span>{getNoun(addedCount, 'добавлена', 'добавлены', 'добавлены')} в корзину</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='file-upload-finish__failed-list'>{errorList}</div>
              <DefaultButton type='button' onClick={onGoToBasket}>
                Перейти в корзину
              </DefaultButton>
              <DefaultButton type='button' color='secondary' onClick={onGoToCatalog}>
                Продолжить покупки
              </DefaultButton>
            </div>
          )}
        </form>
      </StyledPaper>
    </BasicModal>
  )
}
