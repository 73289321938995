import React from 'react'
import './BasketDownlad.scss'

export const BasketDownload = () => {
  return (
    <div className='basket-download'>
      <button className='basket-download__excel'>Excel</button>
      <button className='basket-download__email'>Email</button>
    </div>
  )
}
