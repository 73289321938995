import React, { useCallback, useEffect, useState } from 'react'
import './Catalog.scss'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { useHistory, useLocation } from 'react-router-dom'
import { ProductsList } from '../../components/ProductList'
import { TableTools } from '../../containers/bottomTools'
import { ProductCard } from '../../containers/productCard'
import { FullProductCard } from '../../containers/fullProductCard'
import { StockInfo } from '../../components/stockInfo'
import { ModalDefault } from '../../commons/modals/modalDefault'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Axios } from '../../axios'
import { GET_SECTION, GET_URL_REWRITE } from '../../settings/endpoints'
import { useActions } from '../../hooks/useActions'
import { ISectionRes } from '../../types/models'
import { IUrlRewriteRes } from '../../types/models/IUrlRewrite'
import { getBasketCount } from '../../utils/getBasketCount'
import { BasicModal } from '../../commons/mui/BasicModal'
import { useCustomSnackbar } from '../../hooks/useSnackbar'
import { filterSlice } from '../../redux/reducers/filterReducer'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useCatalog } from '../../hooks/useCatalog'
import { IErrorResponse } from '../../types/commons'
import { CatalogContainer } from '../../containers/catalogContainer'
import { formatText } from '../../utils/formatText'

export const Catalog = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { pathname, search } = useLocation()

  const { filters, product, products, pagination, filterDirection } = useTypedSelector(state => state.productReducer)
  const { filter, allFilters, isSubmit: isFilterSubmit, selectors } = useTypedSelector(state => state.filterReducer)
  const { fetchProducts, setSectionId, setExpandedSectionId, setCurrentPage, setPerPage } = useActions()
  const { setAllFilters } = filterSlice.actions

  const {
    isLoading,
    isFullProductCard,
    location,
    productQ,
    basketList,
    isBasketError,
    basketError,
    isAddBasketLoading,
    isErrorAddToBasket,
    errorAddToBasket,
    openFullProductCard,
    setProductQHandler,
    setProductQuantity,
    addToBasket,
  } = useCatalog(products)
  const { errorSnack } = useCustomSnackbar()

  const [isStockInfo, setIsStockInfo] = useState(false)
  const [layout, setLayout] = useState<'table' | 'grid'>('grid')
  const [initParams, setInitParams] = useState(false) // флаг для инициализаци параметров из url
  const [skipFilters, setSkipFilters] = useState(true)
  const [pageTitle, setPageTitle] = useState('Каталог')

  const openStockInfo = useCallback(() => {
    setIsStockInfo(!isStockInfo)
  }, [isStockInfo])

  // Открывает карточку товара в зависимости от url
  useEffect(() => {
    const fetchUrlRewriteRes = async () => {
      try {
        if (history.location.pathname !== '/catalog') {
          const { data } = await Axios.get<IUrlRewriteRes>(GET_URL_REWRITE, {
            params: { path: history.location.pathname },
          })
          setExpandedSectionId(data.result.treeIds)
          if (data.result.page === 'product') {
            await openFullProductCard(data.result.variables.productId)
            setSectionId(data.result.variables.sectionId)
          }
          if (data.result.page === 'section') setSectionId(data.result.variables.sectionId)
        }
        setInitParams(true) // сообщаем, что параметры из url проинициализированны
        setSkipFilters(false)
      } catch (e) {
        setInitParams(true)
        setSkipFilters(false)
        return e
      }
    }
    fetchUrlRewriteRes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  // установка фильтров из параметров url
  useEffect(() => {
    if (search.length > 0) {
      try {
        const newFilters = []
        const decodeSearch = decodeURI(search.split('=')[1])
        const arr = decodeSearch.split('&')
        const propsFilter: string[] = []
        let priceFilter: string
        arr.forEach(item => {
          if (item.indexOf('min') !== -1 && item.indexOf('max') !== -1) {
            priceFilter = item
          } else {
            propsFilter.push(item)
          }
        })

        if (propsFilter.length) {
          propsFilter.forEach(item => {
            newFilters.push(item.split('_'))
          })
        }

        if (priceFilter) {
          const priceArr = priceFilter.split('_')
          if (priceArr.length === 6) {
            const min = [priceArr[0], priceArr[1], priceArr[2]]
            const max = [priceArr[3], priceArr[4], priceArr[5]]
            newFilters.push([min, max])
          }
        }

        dispatch(setAllFilters(newFilters))
      } catch (_) {
        history.push(pathname)
      }
    }
    else {
      dispatch(setAllFilters([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    if (initParams && isFilterSubmit) {
      if (filters.sectionId) {
        Axios.get<ISectionRes>(GET_SECTION(filters.sectionId)).then(res => {
          setPageTitle(pathname === '/catalog' ? 'Каталог' : formatText(res.data.result.name))
          document.title = pathname === '/catalog' ? 'Каталог' : formatText(res.data.result.name)
        })
      }

      if (allFilters.length) {
        const strArray = allFilters.map(item => item.join('_'))
        const minMaxSrt = strArray[strArray.length - 1]
        if (minMaxSrt && minMaxSrt.indexOf('min') !== -1 && minMaxSrt.indexOf('max') !== -1) {
          strArray[strArray.length - 1] = minMaxSrt.replaceAll(',', '_')
        }
        const encodeStr = encodeURI(strArray.join('&'))
        history.push({ pathname, search: `filters=${encodeStr}` })
      }
      fetchProducts(
        filterDirection?.value,
        pathname === '/catalog' ? null : filters.sectionId,
        pagination.currentPage,
        pagination.perPage,
        selectors,
        // filter,
        allFilters,
        true,
      )
      if (pathname === '/catalog') {
        document.title = pageTitle
        setSectionId(null)
        setExpandedSectionId([])
      }
      // dispatch(setIsSubmit(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initParams,
    filters.sectionId,
    selectors,
    pagination.currentPage,
    pagination.perPage,
    JSON.stringify(filterDirection),
    filter,
    isFilterSubmit,
    pathname,
    allFilters,
  ])

  useEffect(() => {
    if (isErrorAddToBasket) {
      const errorMessage = (errorAddToBasket as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', errorAddToBasket)
    }
  }, [errorAddToBasket, errorSnack, isErrorAddToBasket])

  useEffect(() => {
    if (isBasketError) {
      const errorMessage = (basketError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', basketError)
    }
  }, [basketError, errorSnack, isBasketError])

  return (
    <CatalogContainer isToolbar skipFilters={skipFilters} layout={layout} setLayout={setLayout}>
      <div className='catalog'>
        <BasicModal handleClose={() => openFullProductCard()} open={!!isFullProductCard}>
          <FullProductCard
            product={product}
            productQ={productQ[product.id]}
            productLocation={location}
            setProductQ={setProductQHandler}
            addToBasket={addToBasket}
            setProductQuantity={setProductQuantity}
            basketCount={getBasketCount(product, basketList)}
            isAddBasketLoading={isAddBasketLoading}
            handleClose={openFullProductCard}
            basketId={basketList.find(item => item.productId === product.id)?.id}
          />
        </BasicModal>

        {/* <Loader isLoading={isLoading || isBasketFetching || isAddBasketLoading} /> */}
        {/* <Loader isLoading={isLoading} /> */}

        <ModalDefault handleOpen={() => openStockInfo} isOpen={isStockInfo}>
          <StockInfo />
        </ModalDefault>

        <div className='catalog-content'>
          <div className='catalog-content__content'>
            {products.length !== 0 ? (
              <SwitchTransition mode='out-in'>
                <CSSTransition key={layout} timeout={200} classNames='show-el' unmountOnExit>
                  <>
                    {layout === 'grid' ? (
                      <div className='catalog-content__grid'>
                        {products?.map(product => {
                          return (
                            <ProductCard
                              key={product.id}
                              product={product}
                              productQ={productQ}
                              basketCount={getBasketCount(product, basketList)}
                              basketId={basketList.find(item => item.productId === product.id)?.id}
                              isLoadingCard={isLoading}
                              addToBasket={addToBasket}
                              setProductQuantity={setProductQuantity}
                              setProductQ={setProductQHandler}
                              openFullProductCard={() => openFullProductCard(product.id, false)}
                            />
                          )
                        })}
                      </div>
                    ) : (
                      <ProductsList
                        openFullProductCard={openFullProductCard}
                        products={products}
                        addToBasket={addToBasket}
                        setProductQuantity={setProductQuantity}
                        setProductQ={setProductQHandler}
                        productQ={productQ}
                        basketList={basketList}
                        isAddBasketLoading={isAddBasketLoading}
                        isLoadingCard={isLoading}
                      />
                    )}
                  </>
                </CSSTransition>
              </SwitchTransition>
            ) : (
              <div className='empty-response'>По вашему запросу ничего не найдено...</div>
            )}
          </div>
          <div className='catalog-content__bottom-tools'>
            <TableTools pagination={pagination} onPerPageChange={setPerPage} onPageChange={setCurrentPage} />
          </div>
        </div>
      </div>
    </CatalogContainer>
  )
}
