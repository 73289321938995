import React, { useEffect, useState, MouseEvent } from 'react'
import './CatalogFilter.scss'
import { useHistory, useLocation } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ISectionFiltersRes } from '../../types/models/section/filters/ISectionFiltersRes'
import { StyledSlider, StyledSwitch } from '../../commons/mui/Styled'
import { CustomCheckbox } from '../../commons/mui/Checkbox/CustomCheckbox'
import { filterSlice } from '../../redux/reducers/filterReducer'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { FilterNote } from '../../components/FilterNote'
import { useActions } from '../../hooks/useActions'
import { MultiselectFilter } from '../../components/MultiselectFilter'

interface CatalogFilter {
  setIsOpen: (isOpen: boolean) => void
  filters: ISectionFiltersRes['result']
}

export const CatalogFilter: React.FC<CatalogFilter> = ({ filters, setIsOpen }) => {
  const { selectors, allFilters } = useTypedSelector(state => state.filterReducer)

  const history = useHistory()
  const { pathname } = useLocation()
  const min = +filters?.price.values.min.value || 0
  const max = +filters?.price.values.max.value || 0

  const dispatch = useAppDispatch()
  const {
    setFilter,
    resetFilter,
    setIsSubmit,
    setSelector,
    deleteSelector,
    resetSelectors,
    deleteDuplicates,
    unsetFilter,
  } = filterSlice.actions
  const { fetchProducts } = useActions()
  const {
    pagination,
    filters: productFilters,
    filterDirection,
    selectors: selectorsList,
    totalRows,
    isLoading,
  } = useTypedSelector(state => state.productReducer)
  const [price, setPrice] = useState<number[]>([min, max])
  const [buttonY, setButtonY] = useState(0)
  const [showFilterNote, setShowFilterNote] = useState(false)

  useEffect(() => {
    if (allFilters.length > 0) {
      setFilter({
        ['p' + filters?.price?.id]: {
          ['min']: allFilters[allFilters.length - 1][0][2],
          ['max']: allFilters[allFilters.length - 1][1][2],
        },
      })
      setPrice([allFilters[allFilters.length - 1][0][2], allFilters[allFilters.length - 1][1][2]])
    } else {
      setFilter({
        ['p' + filters?.price?.id]: { ['min']: min ?? 0, ['max']: max ?? 100000 },
      })
    }
    dispatch(deleteDuplicates('price'))
    dispatch(setIsSubmit(false))
    return () => {
      dispatch(setIsSubmit(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchProducts(
      filterDirection.value,
      pathname === '/catalog' ? null : productFilters.sectionId,
      pagination.currentPage,
      pagination.perPage,
      selectors,
      allFilters,
      true,
      true,
    )
  }, [selectors, productFilters.sectionId, pagination.currentPage, pagination.perPage, filterDirection, allFilters])

  const handleChange = (isChecked, selectorName) => {
    isChecked ? dispatch(setSelector(selectorName)) : dispatch(deleteSelector(selectorName))
  }

  const onApplyFilters = () => {
    if (filters) {
      dispatch(
        setFilter({
          ['p' + filters.price.id]: { ['min']: price[0], ['max']: price[1] },
        }),
      )
      dispatch(deleteDuplicates('price'))
    }
    setIsOpen(false)
  }

  const onResetFilters = () => {
    dispatch(resetFilter())
    dispatch(resetSelectors())
    setPrice([min, max])
    setIsOpen(false)
    history.push(pathname)
  }

  const onClickFilterItem = (e: MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect()
    const clientHeight = e.currentTarget.clientHeight
    setButtonY(rect.y + clientHeight / 2)
    setShowFilterNote(true)
  }

  const onCloseMultiselectFilter = (y: number) => {
    setButtonY(y)
    setShowFilterNote(true)
  }

  return (
    <div className='catalog-filter'>
      <div className='catalog-filter__container'>
        {showFilterNote && (
          <FilterNote coordsY={buttonY} count={totalRows} isLoading={isLoading} onClick={onApplyFilters} />
        )}
        <div className='catalog-filter__main-block'>
          <span className='catalog-filter__title'>Фильтр</span>
          {filters?.props.map(prop => {
            return (
              <div className='catalog-filter__item' key={prop.id}>
                <label className='catalog-filter__item-title'>{prop.name}</label>
                {Object.entries(prop.values).length > 10 ? (
                  <MultiselectFilter prop={prop} onCloseMultiselectFilter={onCloseMultiselectFilter} />
                ) : (
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                    {Object.entries(prop.values).map(v => (
                      <div className='filter-toggle__item' key={v[1].htmlValueAlt}>
                        <CustomCheckbox
                          checked={allFilters.filter(i => i[1].includes(v[1].htmlValueAlt)).length !== 0}
                          onChange={(_, data: any) => {
                            if (allFilters.filter(i => i[1].includes(v[1].htmlValueAlt)).length !== 0) {
                              dispatch(unsetFilter([prop.id, v[1].htmlValueAlt, v[1].value]))
                            } else {
                              dispatch(setFilter({ [prop.id]: { [v[1].htmlValueAlt]: v[1].value } }))
                              dispatch(deleteDuplicates({}))
                            }
                          }}
                          onClick={onClickFilterItem}
                        />
                        <span>{v[1].value}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )
          })}

          {filters && (
            <div className='catalog-filter__item'>
              <span className='catalog-filter__item-title'>Цена</span>
              <div className='catalog-filter__price-slider price-slider'>
                <StyledSlider
                  min={min}
                  max={max}
                  value={price}
                  defaultValue={[min, max]}
                  disableSwap
                  onChange={(event: any) => {
                    setPrice(event.target.value)
                  }}
                />
                <div className='text-input'>
                  <div className='text-input__item '>
                    <span>от</span>
                    <input
                      type='number'
                      step={0.01}
                      value={price[0]}
                      onChange={event => {
                        setPrice([+event.target.value, price[1]])
                      }}
                    />
                  </div>
                  <div className='text-input__item '>
                    <span>до</span>
                    <input
                      type='number'
                      step={0.01}
                      value={price[1]}
                      onChange={event => setPrice([price[0], +event.target.value])}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className='catalog-filter__add'>
            <span className='catalog-filter__item-title'>Дополнительно</span>
            <div className='catalog-filter__toggle-block filter-toggle'>
              {selectorsList?.map(selector => (
                <div className='filter-toggle__item' key={selector.code}>
                  <StyledSwitch
                    checked={selectors.includes(selector.code)}
                    onChange={e => handleChange(e.target.checked, selector.code)}
                  />
                  <span>{selector.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='catalog-filter__btn-block'>
          <div className='filter-button-block'>
            <button className='filter-button-block__btn-apply' type='submit' onClick={() => onApplyFilters()}>
              Применить
            </button>
            <button className='filter-button-block__btn-reset' type='reset' onClick={() => onResetFilters()}>
              Сбросить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
