import React, { Fragment } from 'react'
import './SectionsFull.scss'
import { shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import { TextFormat } from '../../commons/TextFromat/TextFormat'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import downArrow from '../../assets/svg/downArrowSmall.svg'
import rightArrow from '../../assets/svg/RightArrow.svg'
import { useActions } from '../../hooks/useActions'
import { ISection } from '../../types/models'
import { filterSlice } from '../../redux/reducers/filterReducer'
import { useAppDispatch } from '../../hooks/useAppDispatch'

interface SideBarFull {
  sections: ISection[]
  expandSectionId: number[]
  expandSection: (id: number) => void
  singleSection?: boolean
  singleSectionId?: number
  firstLvlId?: number
  isSingleLevel?: boolean
  hideSection?: () => void
}

export const SectionsFull: React.FC<SideBarFull> = ({
  singleSection,
  singleSectionId,
  sections,
  expandSectionId,
  expandSection,
  isSingleLevel,
  hideSection,
}) => {
  const dispatch = useAppDispatch()
  const { filters } = useTypedSelector(state => state.productReducer, shallowEqual)
  const { bannersCount } = useTypedSelector(state => state.infoReducer)
  const { resetFilter } = filterSlice.actions
  const { setSectionId } = useActions()

  const handleClick = (id: number) => {
    if (hideSection) {
      hideSection()
    }
    dispatch(resetFilter())
    setSectionId(id)
  }

  return (
    <div
      className='sections-full'
      style={{ height: `calc(100vh - ${bannersCount ? 120 + bannersCount * 60 : 120}px)` }}
    >
      {sections
        .filter(section => (singleSectionId ? section.id === singleSectionId : section))
        .map(sectionFirstLvl => (
          <div className='first-level' key={sectionFirstLvl.id}>
            {isSingleLevel ? (
              <Link to={sectionFirstLvl.pageUrl} className='first-level__item item' style={{ paddingLeft: '16px' }}>
                <span>
                  <TextFormat text={sectionFirstLvl.name} isInner />
                </span>
              </Link>
            ) : (
              <div
                className='first-level__item item'
                onClick={() => {
                  expandSection(sectionFirstLvl.id)
                }}
                style={{
                  background: `url(${
                    expandSectionId.includes(sectionFirstLvl.id) ? downArrow : rightArrow
                  }) no-repeat center left 15px`,
                }}
              >
                <span>
                  <TextFormat text={sectionFirstLvl.name} isInner />
                </span>
              </div>
            )}

            {!isSingleLevel && (expandSectionId.includes(sectionFirstLvl.id) || singleSection) && (
              <div className='second-level'>
                {sectionFirstLvl?.children?.map(sectionSecondLvl => (
                  <Fragment key={sectionSecondLvl.id}>
                    <div
                      className='second-level__item item'
                      onClick={() => expandSection(sectionSecondLvl.id)}
                      style={{
                        background: `url(${
                          expandSectionId.includes(sectionSecondLvl.id) ? downArrow : rightArrow
                        }) no-repeat center left 15px`,
                      }}
                    >
                      <span>{sectionSecondLvl.name}</span>
                    </div>

                    {expandSectionId.includes(sectionSecondLvl.id) && (
                      <div className='third-level'>
                        {sectionSecondLvl.children.map(sectionThirdLvl => (
                          <Link
                            to={sectionThirdLvl.pageUrl}
                            className={`third-level__item item ${
                              sectionThirdLvl.id === filters.sectionId ? 'third-level__item_active' : undefined
                            }`}
                            key={sectionThirdLvl.id}
                            onClick={() => {
                              handleClick(sectionThirdLvl.id)
                            }}
                          >
                            <span dangerouslySetInnerHTML={{ __html: sectionThirdLvl.name }} />
                          </Link>
                        ))}
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  )
}
