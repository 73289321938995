import React, { useState } from 'react'
import './DownloadSelect.scss'
import { CSSTransition } from 'react-transition-group'
import { FileUpload } from '../../components/fileUpload'
import { useImport } from '../../hooks/useImport'

export const DownloadSelect = () => {
  const { inputRef, file, setFile, stepUpload, setStepUpload, onFileChange, onUploadClick } = useImport()
  const [isOpen, setIsOpen] = useState(false)

  const onFileUploadClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      {isOpen && <div className='dropdown-background' onClick={() => setIsOpen(false)} />}
      <div className='download-select'>
        <button className='download-btn' onClick={() => setIsOpen(true)} />
        <CSSTransition in={isOpen} timeout={200} classNames='show-el' mountOnEnter unmountOnExit>
          <div className='dropdown'>
            <input
              className='file-upload__input'
              type='file'
              accept='.xls,.xlsx'
              multiple={false}
              ref={inputRef}
              onChange={onFileChange}
            />
            <div className='download-select__in'>
              {/*<span>Загрузить</span>*/}
              <button onClick={onUploadClick}>Загрузить заказ из Excel</button>
            </div>
            {/*<div className='download-select__out'>*/}
            {/*  <span>Выгрузить</span>*/}
            {/*  <DownloadProduct button={<button>Скачать прайслист</button>} products={products} />*/}
            {/*</div>*/}
          </div>
        </CSSTransition>
      </div>
      <FileUpload
        open={!!file}
        file={file}
        setFile={setFile}
        stepUpload={stepUpload}
        setStepUpload={setStepUpload}
        handleClose={onFileUploadClose}
      />
    </>
  )
}
