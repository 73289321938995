import React, { useEffect, useState } from 'react'
import './BackgroundLayout.scss'
import { Axios } from '../../axios'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { BACKGROUND } from '../../settings/endpoints'
import { IBackgroundRes } from '../../types/models/info'
import bgImg from '../../assets/png/ordersBackground.png'

export const BackgroundLayout = ({ children }) => {
  const [img, setImg] = useState('')
  const [loading, setLoading] = useState(true)

  const { bannersCount } = useTypedSelector(state => state.infoReducer)

  useEffect(() => {
    Axios.get<IBackgroundRes>(BACKGROUND).then(res => {
      setLoading(false)
      if (res.data.result.path) {
        setImg(res.data.result.path)
      }
    })
  }, [])

  if (loading) {
    return <></>
  }

  return (
    <div
      style={{
        background: `url(${img || bgImg}) no-repeat center top`,
        height: `calc(100vh - ${bannersCount ? 120 + bannersCount * 60 : 60}px)`,
      }}
      className='background-layout'
    >
      {children}
    </div>
  )
}
