import React, { ChangeEvent, useEffect } from 'react'
import './CatalogToolbar.scss'
import { shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import { Search } from '../../components/search'
import { DownloadSelect } from '../downloadSelect'
import { FilterDirection } from '../filterDirection'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { StyledSwitch } from '../../commons/mui/Styled'
import { filterSlice } from '../../redux/reducers/filterReducer'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { ProductSelectorCode, TCatalogLayout } from '../../types/models'
import zenopad from '../../assets/jpg/bunners/banner_zcenopad.jpg'

interface CatalogToolbar {
  layout: any
  setLayout?: (layout: TCatalogLayout) => void
  openStockInfo: any
  openFilter: (isOpen: boolean) => void
  isFiltersAvailable?: boolean
  searchOnly?: boolean
}

export const CatalogToolbar: React.FC<CatalogToolbar> = ({
  openFilter,
  setLayout,
  layout,
  isFiltersAvailable = true,
  searchOnly,
}) => {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const { selectors: selectorsList } = useTypedSelector(state => state.productReducer, shallowEqual)
  const auth = useTypedSelector(state => state.authReducer, shallowEqual)
  const { selectors } = useTypedSelector(state => state.filterReducer)
  const { setSelector, deleteSelector } = filterSlice.actions
  const { fetchSelectors } = useActions()

  const handleChange = (isChecked: boolean, selectorName: ProductSelectorCode) => {
    isChecked ? dispatch(setSelector(selectorName)) : dispatch(deleteSelector(selectorName))
  }

  const setLayoutHandler = (layout: TCatalogLayout) => {
    if (setLayout) {
      setLayout(layout)
    }
  }

  useEffect(() => {
    fetchSelectors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='catalog-toolbar'>
      <div className='catalog-toolbar__items'>
        <div className='filters'>
          <div className='filters__search-container'>
            <Search />
          </div>
          {pathname === '/' && auth?.user && (
            <Box ml={'30px'}>
              <img src={zenopad} alt={'zenopad'} />
            </Box>
          )}
          {!searchOnly && (
            <>
              <div className='filters__filter-direction'>
                <FilterDirection />
              </div>
              <div className='toggle-group'>
                {selectorsList.map(selector => (
                  <div className='toggle-group__item' key={selector.code}>
                    <StyledSwitch
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleChange(e.target.checked, selector.code)
                      }}
                      checked={selectors.includes(selector.code)}
                    />
                    <div className='toggle-group__label'>{selector.name}</div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        {!searchOnly && (
          <div className='tools'>
            <div className='tools__download-btn'>
              <DownloadSelect />
            </div>
            <div className='button-group'>
              <button
                className={`button-group__table ${layout === 'table' && 'button-group__btn_active'}`}
                onClick={() => setLayoutHandler('table')}
              />
              <button
                className={`button-group__grid ${layout === 'grid' && 'button-group__btn_active'}`}
                onClick={() => setLayoutHandler('grid')}
              />
            </div>

            {isFiltersAvailable && (
              <div className='filter-btn'>
                <button onClick={() => openFilter(true)}>Фильтр</button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
