import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SearchState {
  queryValue: string
}

const initialState: SearchState = {
  queryValue: '',
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setQueryValue: (state, action: PayloadAction<string>) => {
      state.queryValue = action.payload
    },
  },
})

export const searchReducer = searchSlice.reducer
export const { setQueryValue } = searchSlice.actions
