import { Switch } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 25,
  height: 13,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 13,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {},
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#F7F7F7',
      },
      '& .MuiSwitch-thumb': {
        background: '#FEDB43',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 13,
    height: 13,
    borderRadius: 13 / 2,
    border: '1px solid black',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: 13 / 2,
    border: '1px solid #E5E5E5',
    opacity: 1,
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
}))
