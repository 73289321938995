import React, { Fragment, useEffect, useState } from 'react'
import './FullProductCard.scss'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { ButtonQ } from '../../commons/AmountButton'
import { TextFormat } from '../../commons/TextFromat/TextFormat'
import { ImageSlider } from '../../components/ImageSlider'
import { IProductDetails, IProductLocation, IProductPrice, IProductPriceRes } from '../../types/models'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { PriceBlock } from '../../components/PriceBlock'
import { favoriteApi } from '../../api/FavoriteService'
import { DownloadIcon, FileIcon } from '../../commons/Icons'
import { StyledPaper } from '../../commons/mui/Styled'
import { TextButton } from '../../commons/mui/Button/TextButton'
import { BasicModal } from '../../commons/mui/BasicModal'
import { BasketButton } from '../../commons/mui/Button/BasketButton'
import { Labels } from '../../components/Labels'
import { isMsk } from '../../utils/isMsk'
import { Axios } from '../../axios'
import { POST_PRODUCT_PRICE } from '../../settings/endpoints'
import { Heart } from '../../components/Heart'

const Warning = () => (
  <svg width='9' height='18' viewBox='0 0 9 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect opacity='0.2' width='9' height='18' rx='2' fill='#FFB800' />
    <path
      d='M3.71598 9.58399L3.37998 5.692L3.29598 3.62H5.70398L5.61998 5.692L5.28398 9.58399H3.71598ZM4.49998 14.168C4.08931 14.168 3.75331 14.028 3.49198 13.748C3.23064 13.468 3.09998 13.1227 3.09998 12.712C3.09998 12.3013 3.23064 11.956 3.49198 11.676C3.75331 11.396 4.08931 11.256 4.49998 11.256C4.91064 11.256 5.24664 11.396 5.50798 11.676C5.76931 11.956 5.89998 12.3013 5.89998 12.712C5.89998 13.1227 5.76931 13.468 5.50798 13.748C5.24664 14.028 4.91064 14.168 4.49998 14.168Z'
      fill='#FFAA00'
    />
  </svg>
)

const Bonus = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='7.5' cy='8.5' r='7.5' fill='#7629D9' />
    <path
      d='M2.1353 11L3.4853 8.66L2.2073 6.5H3.8633L4.1873 7.13C4.2473 7.256 4.3073 7.385 4.3673 7.517C4.4273 7.649 4.4873 7.778 4.5473 7.904H4.5833C4.6253 7.778 4.6703 7.649 4.7183 7.517C4.7663 7.385 4.8113 7.256 4.8533 7.13L5.0693 6.5H6.6713L5.3933 8.84L6.7433 11H5.0873L4.7273 10.37C4.6613 10.244 4.5953 10.115 4.5293 9.983C4.4633 9.851 4.3973 9.722 4.3313 9.596H4.2953C4.2473 9.722 4.1963 9.851 4.1423 9.983C4.0883 10.109 4.0373 10.238 3.9893 10.37L3.7373 11H2.1353ZM7.46365 11V10.118C7.79965 9.806 8.11165 9.512 8.39965 9.236C8.69365 8.96 8.94865 8.702 9.16465 8.462C9.38065 8.216 9.54865 7.985 9.66865 7.769C9.79465 7.547 9.85765 7.34 9.85765 7.148C9.85765 6.89 9.79165 6.701 9.65965 6.581C9.53365 6.455 9.36565 6.392 9.15565 6.392C8.96365 6.392 8.79265 6.449 8.64265 6.563C8.49865 6.671 8.35765 6.794 8.21965 6.932L7.39165 6.104C7.54165 5.948 7.68865 5.813 7.83265 5.699C7.97665 5.579 8.12665 5.483 8.28265 5.411C8.44465 5.333 8.61265 5.276 8.78665 5.24C8.96665 5.204 9.16165 5.186 9.37165 5.186C9.65965 5.186 9.92365 5.231 10.1636 5.321C10.4036 5.411 10.6106 5.537 10.7846 5.699C10.9586 5.861 11.0936 6.059 11.1896 6.293C11.2856 6.521 11.3336 6.776 11.3336 7.058C11.3336 7.286 11.2826 7.52 11.1806 7.76C11.0846 7.994 10.9586 8.228 10.8026 8.462C10.6466 8.696 10.4696 8.924 10.2716 9.146C10.0796 9.368 9.88765 9.578 9.69565 9.776C9.83965 9.758 10.0016 9.743 10.1816 9.731C10.3676 9.713 10.5236 9.704 10.6496 9.704H11.6036V11H7.46365Z'
      fill='white'
    />
  </svg>
)

interface FullProductCard {
  product: IProductDetails
  productLocation: IProductLocation[0]
  productQ: number
  addToBasket: ({}: { productId: number; quantity: number }) => unknown
  setProductQuantity?: ({}: { id: number; quantity: number }) => unknown
  setProductQ: (productId: number, productQ: number) => void
  basketCount: number
  isAddBasketLoading?: boolean
  handleClose?: () => void
  basketId?: number
}

export const FullProductCard: React.FC<FullProductCard> = ({
  product,
  productLocation,
  setProductQ,
  addToBasket,
  setProductQuantity,
  productQ,
  basketCount,
  isAddBasketLoading = false,
  handleClose,
  basketId,
}) => {
  const { productBreadCrumbs } = useTypedSelector(state => state.productReducer)
  const { data } = favoriteApi.useGetFavoriteQuery(null)
  const [postFavorite] = favoriteApi.usePostFavoriteMutation()
  const [deleteFavorite] = favoriteApi.useDeleteFavoriteMutation()
  const [openDownloadDocuments, setOpenDownLoadDocuments] = useState<boolean>(false)
  const [productPriceData, setProductPriceData] = useState<IProductPrice>(null)
  useEffect(() => {
    if (product.id) {
      Axios.post<IProductPriceRes>(POST_PRODUCT_PRICE, {
        id: [product.id],
      }).then(res => {
        setProductPriceData({ ...res.data.result })
      })
    }
  }, [product.id])
  const productPrice = productPriceData ?? {}
  const { city } = useTypedSelector(state => state.authReducer.user.location)

  const isFavorite = data?.result.includes(product.id)

  const categoryLink = productBreadCrumbs[productBreadCrumbs.length - 2]
    ? productBreadCrumbs[productBreadCrumbs.length - 2].pageUrl
    : '/catalog'

  const handleClickToCategory = () => {
    handleClose && handleClose()
  }

  const onBasketClick = () => {
    addToBasket({ productId: product?.id, quantity: productQ ?? +product?.packages[0] })
  }

  const onMinusClick = (quantity: number) => {
    if (basketCount > 0 && basketId && setProductQuantity) {
      setProductQuantity({ id: basketId, quantity: basketCount - quantity })
    }
  }

  const onPlusClick = (quantity: number) => {
    if (basketCount > 0 && basketId && setProductQuantity) {
      setProductQuantity({ id: basketId, quantity: basketCount + quantity })
    } else {
      addToBasket({ productId: product?.id, quantity: productQ ?? +product?.packages[0] })
    }
  }

  return (
    <StyledPaper radius='big' hideOverflowX={true}>
      <BasicModal open={openDownloadDocuments} handleClose={() => setOpenDownLoadDocuments(false)} exitButton={false}>
        <StyledPaper
          radius='small'
          width='300px'
          p='13px 15px'
          display='flex'
          flexDirection='column'
          rowGap='16px'
          style={{ cursor: 'pointer' }}
        >
          {product.certificates.map(item => (
            <Box
              key={item.number}
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              fontWeight='fontWeightRegular'
              onClick={() => window.open(item.url)}
            >
              <Box display='flex'>
                <FileIcon />
                <Box pl='5.8px'>{item.type + ' ' + item.number}</Box>
              </Box>
              <DownloadIcon />
            </Box>
          ))}
        </StyledPaper>
      </BasicModal>
      <Box display='flex' width='1020px' columnGap='24px'>
        <Box flex='0 0 484px' m='29px 0 29px 30px' maxWidth='484px'>
          <Box position='relative'>
            <Box display='flex' position='absolute' align-items='center'>
              <Labels labels={product.labels} />
              {/*<div className='markers__bonus'>*/}
              {/*  <span>+56</span>*/}
              {/*  <Bonus />*/}
              {/*</div>*/}
            </Box>

            <ImageSlider pictureDetail={product.pictureDetail} morePicture={product.morePictures} />
          </Box>

          <Box className='specification'>
            <span className='specification__title'>Характеристики</span>
            <Box className='specification__table-container'>
              <table className='specification__table'>
                <tbody>
                  {product.specifications?.map(specification => (
                    <tr className='specification__item' key={specification.id}>
                      <td>{specification.name}</td>
                      <td>{specification.value}</td>
                    </tr>
                  ))}
                  {/*<tr>*/}
                  {/*  <td>Условия хранения и перевозки</td>*/}
                  {/*  <td>*/}
                  {/*    ####*/}
                  {/*<div className='ico-container'>*/}
                  {/*  <img src={frostIco} alt='ico' />*/}
                  {/*  <img src={waterIco} alt='ico' />*/}
                  {/*</div>*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>

        <Box className='full-product-card__right-col'>
          <Box className='product-head-block'>
            <Link to={categoryLink} className='product-head-block__category' onClick={handleClickToCategory}>
              {productBreadCrumbs[productBreadCrumbs.length - 2]?.name}
            </Link>
            <Box className='product-head-block__title'>
              <h1>
                <TextFormat text={product.name} isInner />
              </h1>
            </Box>
            <Box className='product-head-block__bread-crumbs'>
              {productBreadCrumbs?.map(breadcrumb => (
                <Fragment key={breadcrumb.id}>
                  <TextFormat text={breadcrumb.name} isInner />
                  {/*<span key={breadcrumb.id}>{breadcrumb.name}</span>*/}
                  &nbsp;
                  <span>/</span>
                  &nbsp;
                </Fragment>
              ))}
            </Box>
          </Box>

          <Box className='full-product-card__product-position product-position'>
            <Box className='product-position__properties'>
              <Box>Артикул:</Box>
              <Box>Штрихкод:</Box>
              <Box>Код товара:</Box>
              <Box>Торговая марка:</Box>
              <Box>Упаковка:</Box>
              <Box>Наличие:</Box>
            </Box>
            <Box className='product-position__data'>
              <Box>{product.article}</Box>
              <Box>{product.barcode}</Box>
              <Box>{product.externalId}</Box>
              <Box>{product.tm}</Box>
              <Box>{product.packages.filter(w => parseInt(w) > 1).join('/')}</Box>
              <Box>
                {isMsk(city) ? 'В наличии' : 'Ваш город'}:{' '}
                <span style={product.location?.amount ? null : { color: '#F23B2F' }}>
                  {product.location?.amount ? `${product.location.amount} (${product.unitOfMeasure})` : 'НЕТ'}
                </span>
                &nbsp;{' '}
                {!isMsk(city) && (
                  <>
                    Склад (Мск):{' '}
                    <span style={product.stock?.amount ? null : { color: '#F23B2F' }}>
                      {product.stock?.amount ? `${product.stock.amount} (${product.unitOfMeasure})` : 'НЕТ'}
                    </span>
                  </>
                )}
              </Box>
            </Box>
            <Box className='product-position__options'>
              <Box
                className='product-position__favorite'
                onClick={() => {
                  isFavorite ? deleteFavorite(product.id) : postFavorite(product.id)
                }}
              >
                {isFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
                <Heart isActive={isFavorite} />
              </Box>
            </Box>
          </Box>

          <Box className='full-product-card__purchase purchase'>
            <Box className='purchase__price-block'>
              <span className='purchase__price-title'>Цена ваша</span>
              <Box className='purchase__price'>
                <PriceBlock
                  fullPrice={productPrice[product.id]?.price}
                  priceDiscount={productPrice[product.id]?.priceDiscount}
                  percentDiscount={productPrice[product.id]?.percentDiscount}
                  layout='card'
                />
              </Box>
            </Box>
            <Box className='purchase__btn-block'>
              <Box className='purchase__quantity-btn'>
                <ButtonQ
                  onChange={setProductQ}
                  productId={product.id}
                  value={productQ ?? +product?.packages[0]}
                  defaultControlValue={+product?.packages[0]}
                  hover={true}
                  packages={product.packages}
                  unitOfMeasure={product.unitOfMeasure}
                  onMinusClick={onMinusClick}
                  onPlusClick={onPlusClick}
                />
              </Box>
              <Box className='purchase__basket-btn'>
                <BasketButton
                  onClick={onBasketClick}
                  hover={true}
                  count={basketCount}
                  unitOfMeasure={product.unitOfMeasure}
                  isAddBasketLoading={isAddBasketLoading}
                />
                {/*<DefaultButton*/}
                {/*  onClick={() => addToBasket(product?.id, productQ)}*/}
                {/*  color='primary'*/}
                {/*  variant='outlined'*/}
                {/*  fullWidth*/}
                {/*>*/}
                {/*  <BasketIcon hover={true} />*/}
                {/*</DefaultButton>*/}
              </Box>
            </Box>
          </Box>

          <Box p='20px' pl='0' borderBottom='1px solid' borderColor='borderGray'>
            <Typography variant='h4'>Описание</Typography>
            <Box className='full-product-card__description' dangerouslySetInnerHTML={{ __html: product.detailText }} />
          </Box>
          {product.certificates.length > 0 && (
            <Box fontWeight='fontWeightRegular' display='flex' alignItems='center' columnGap='10px' mt='20px' mb='20px'>
              <TextButton startIco={<DownloadIcon />} onClick={() => setOpenDownLoadDocuments(true)}>
                Скачать документы
              </TextButton>
            </Box>
          )}
        </Box>
      </Box>
    </StyledPaper>
  )
}
