import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Axios } from '../../axios'
import Loader from '../../commons/Loader/Loader'
import { GOODSCODE } from '../../settings/endpoints'
import { IResponce } from '../../types/commons'

interface IGoodscodeRes extends IResponce {
  result: string
}

export const Goodscode = (): JSX.Element => {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const pathname = location.pathname.replace('/', '')
    const searchParams = location.search

    Axios.get<IGoodscodeRes>(GOODSCODE(`${pathname}${searchParams}`))
      .then(res => {
        if (res?.status === 200 && res?.data?.result) {
          history.push(res.data.result)
        } else {
          history.push('/catalog')
        }
      })
      .catch(() => {
        history.push('/catalog')
      })
  }, [])
  return (
    <div>
      <Loader isLoading />
    </div>
  )
}
