import { SnackbarOrigin, useSnackbar, VariantType } from 'notistack'

export const useCustomSnackbar = (anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'left' }) => {
  const { enqueueSnackbar } = useSnackbar()

  const constructor = (message: string, variant: VariantType, ms = 5000) => {
    enqueueSnackbar(message, {
      autoHideDuration: ms,
      variant,
      anchorOrigin,
      preventDuplicate: true,
    })
  }

  const errorSnack = (message: string, ms = 5000) => {
    constructor(message, 'error', ms)
  }

  const successSnack = (message: string, ms = 5000) => {
    constructor(message, 'success', ms)
  }

  const infoSnack = (message: string, ms = 5000) => {
    constructor(message, 'info', ms)
  }

  const warnSnack = (message: string, ms = 5000) => {
    constructor(message, 'warning', ms)
  }

  return {
    successSnack,
    errorSnack,
    warnSnack,
    infoSnack,
  }
}
