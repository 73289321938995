import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { Axios } from '../axios'
import { POST_BASKET, URL } from '../settings/endpoints'
import { IBasketRes } from '../types/models'
import { axiosBaseQuery } from './config'

export const basketApiOld = createApi({
  reducerPath: 'basketApiOld',
  baseQuery: axiosBaseQuery({ baseUrl: URL }),
  tagTypes: ['BasketOld'],
  endpoints: build => ({
    getBasket: build.query<IBasketRes, unknown>({
      query: () => ({
        url: '/basket/old',
      }),
      providesTags: ['BasketOld'],
    }),
    postProductBasket: build.mutation<unknown, { productId: number; quantity: number }>({
      query: arg => ({
        url: '/basket',
        method: 'POST',
        data: { ...arg },
      }),
      invalidatesTags: ['BasketOld'],
    }),
    putProductBasket: build.mutation<unknown, { id: number; quantity: number }>({
      query: arg => ({
        url: `/basket/${arg.id}`,
        method: 'PUT',
        data: { quantity: arg.quantity },
      }),
      invalidatesTags: ['BasketOld'],
    }),
    deleteProductBasket: build.mutation<unknown, number>({
      query: id => ({
        url: `/basket/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BasketOld'],
    }),
  }),
})
