import { Slider } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledSlider = styled(Slider)(({ theme }) => ({
  '.MuiSlider-thumb': {
    height: '13px',
    width: '13px',
    border: '1px solid black',
    boxShadow: 'none !important',
    backgroundColor: '#FEDB43',
    '&.Mui-active': {
      boxShadow: 'none',
    },
  },
  '.MuiSlider-track': {
    backgroundColor: '#FEDB43',
    border: 'none',
  },
  '.MuiSlider-rail': {
    opacity: '1',
    backgroundColor: '#ECECEC',
  },
}))
