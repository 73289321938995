import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { hideBanner } from '../../redux/reducers/infoReducer'
import CloseIcon from './close-icon.svg'
import './Banner.scss'

export const Banner: FC = () => {
  const dispatch = useAppDispatch()

  const informers = useTypedSelector(state => state.infoReducer.informers)

  const handleClose = (id: number) => {
    dispatch(hideBanner(id))
  }

  return (
    <>
      {informers.map(banner => (
        <div className='banner' key={banner.id}>
          {banner?.icon && <img src={banner.icon} alt='' />}
          {banner?.name && (
            <div className='banner__text'>
              {banner.name}
              {banner?.description && <span>{banner.description}</span>}
            </div>
          )}
          {banner?.link && banner?.link_text && (
            <Link to={banner.link} className='banner__link'>
              {banner.link_text}
            </Link>
          )}
          <button className='banner__close' onClick={() => handleClose(banner.id)}>
            <img src={CloseIcon} alt='' />
          </button>
        </div>
      ))}
    </>
  )
}
