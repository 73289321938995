import React from 'react'
import './ProductsList.scss'
import { IBasketItem, IProduct, IProductQ } from '../../types/models'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { isMsk } from '../../utils/isMsk'
import { ProductListItem } from './ProductListItem'

interface ProductsList {
  products: IProduct[]
  productQ: IProductQ
  openFullProductCard: (id: number) => void
  addToBasket: ({}: { productId: number; quantity: number }) => unknown
  setProductQuantity: ({}: { id: number; quantity: number }) => unknown
  setProductQ: (productId: number, productQ: number) => void
  basketList: IBasketItem[]
  isAddBasketLoading?: boolean
  isLoadingCard?: boolean
}

export const ProductsList: React.FC<ProductsList> = ({
  products,
  addToBasket,
  setProductQuantity,
  productQ,
  setProductQ,
  basketList,
  isAddBasketLoading = false,
  isLoadingCard = false,
}) => {
  const { city } = useTypedSelector(state => state.authReducer.user.location)

  // const columns = useMemo(
  //   () =>
  //     [
  //       {
  //         Header: 'Фото',
  //         accessor: 'pictureDetail',
  //         Cell: ({ cell }) => (
  //           <div className='products-list__photo'>
  //             <Popup
  //               trigger={() => (
  //                 <div>
  //                   <img src={cell.row.values.pictureDetail || defaultImage} alt='' width='35' height='35' />
  //                 </div>
  //               )}
  //               on={['hover', 'focus']}
  //               position='right center'
  //             >
  //               <div className='products-list__full-photo'>
  //                 <PhotoHover pictureUrl={cell.row.values.pictureDetail || defaultImage} />
  //               </div>
  //             </Popup>
  //           </div>
  //         ),
  //       },
  //       {
  //         Header: 'Код',
  //         accessor: 'externalId',
  //       },
  //       {
  //         Header: 'Артикул',
  //         accessor: 'article',
  //       },
  //       {
  //         id: 'label',
  //         Cell: props => <>{props.row.original.new && <img src={newIco} alt='ico' />}</>,
  //       },
  //       {
  //         Header: 'Название',
  //         accessor: 'name',
  //         Cell: props => <TextFormat stringNumber={1} text={props.row.original.name} isInner />,
  //       },
  //       {
  //         Header: 'На складе',
  //         Cell: props => {
  //           const product = props.row.original
  //           return <AmountBlock amount={+product?.location?.amount} unitOfMeasure={product.unitOfMeasure} />
  //         },
  //       },
  //       {
  //         Header: 'Под заказ',
  //         Cell: props => {
  //           const product = props.row.original
  //           return <AmountBlock amount={+product.stock?.amount} unitOfMeasure={product.unitOfMeasure} />
  //         },
  //       },
  //       {
  //         Header: 'Бренд',
  //         accessor: 'tm',
  //       },
  //       {
  //         Header: 'Цена Ваша',
  //         accessor: '',
  //         Cell: props => {
  //           const product = props.row.original
  //           return (
  //             <PriceBlock
  //               priceDiscount={product.price?.priceDiscount}
  //               fullPrice={product.price?.price}
  //               percentDiscount={product.price?.percentDiscount}
  //               layout='table'
  //             />
  //           )
  //         },
  //       },
  //       {
  //         Header: 'Кол-во',
  //         accessor: 'packages',
  //         Cell: props => {
  //           const product = props.row.original
  //
  //           return (
  //             <div className='products-list__quantity-selector' onClick={event => event.stopPropagation()}>
  //               <ButtonQ
  //                 onChange={setProductQ}
  //                 productId={product?.id}
  //                 value={productQ[product?.id]}
  //                 defaultControlValue={+product?.packages[0]}
  //                 packages={product?.packages}
  //                 unitOfMeasure={product?.unitOfMeasure}
  //                 hover={props.hover === product?.id}
  //               />
  //             </div>
  //           )
  //         },
  //       },
  //       {
  //         Header: 'В корзину',
  //         Cell: props => {
  //           const product = props.row.original
  //           const hover = props.hover === product?.id
  //           const basketCount = basketList.find(item => item.productId === product.id)?.quantity
  //           return (
  //             <div className='products-list__btn' onClick={event => event.stopPropagation()}>
  //               {/*<Button*/}
  //               {/*  onClick={() => addToBasket(product?.id, productQ[product?.id])}*/}
  //               {/*  type='basket'*/}
  //               {/*  isHover={props.hover === product?.id}*/}
  //               {/*/>*/}
  //               <BasketButton
  //                 onClick={() => addToBasket(product?.id, productQ[product?.id])}
  //                 hover={hover}
  //                 count={basketCount}
  //                 unitOfMeasure={product.unitOfMeasure}
  //               />
  //             </div>
  //           )
  //         },
  //       },
  //     ] as Column<IProduct>[],
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [basketList],
  // )

  return (
    <div className='products-list'>
      <table className='table'>
        <thead>
          <tr>
            <th>Фото</th>
            <th>Код</th>
            <th>Артикул</th>
            <th />
            <th>Название</th>
            <th>{isMsk(city) ? 'В наличии' : 'Ваш город'}</th>
            <th>{!isMsk(city) && 'Склад (Мск)'}</th>
            <th>Бренд</th>
            <th>Цена ваша</th>
            <th>Кол-во</th>
            <th>В корзину</th>
          </tr>
        </thead>
        <tbody>
          {products.map(product => {
            const basketCount = basketList?.find(item => item.productId === product.id)?.quantity
            return (
              <ProductListItem
                key={product.id}
                product={product}
                productQ={productQ[product?.id]}
                setProductQ={setProductQ}
                addToBasket={addToBasket}
                setProductQuantity={setProductQuantity}
                basketCount={basketCount}
                isAddBasketLoading={isAddBasketLoading}
                isLoadingCard={isLoadingCard}
                basketId={basketList?.find(item => item.productId === product.id)?.id}
                isMsk={isMsk(city)}
              />
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
