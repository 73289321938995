import React from 'react'
import { formatText } from '../../utils/formatText'

interface TextFormat {
  text: string
  isInner?: boolean
  stringNumber?: number
  maxLength?: number
}

export const TextFormat: React.FC<TextFormat> = ({ text, stringNumber, maxLength, isInner }) => {
  const replacedText = formatText(text)
  return (
    <>
      {isInner ? (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: stringNumber,
            WebkitBoxOrient: 'vertical',
          }}
          dangerouslySetInnerHTML={{
            __html:
              !maxLength && replacedText.length > maxLength ? replacedText.substr(0, maxLength) + '...' : replacedText,
          }}
        />
      ) : (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: stringNumber,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {!maxLength && text.length > maxLength ? text.substr(0, maxLength) + '...' : text}
        </span>
      )}
    </>
  )
}
