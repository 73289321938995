import React, { useState } from 'react'
import './BasketList.scss'
import Popup from 'reactjs-popup'
import defaultImage from '../../../assets/png/defaultImage.png'
import { ButtonQ } from '../../../commons/AmountButton'
import { IBasketSortVariant, IProductIds, IProductLocation, IStock, TBasketList } from '../../../types/models'
import { PhotoHover } from '../../photoHover'
import { PriceBlock } from '../../PriceBlock'
import { AmountBlock } from '../../../commons/CountBlock/AmountBlock'
import { DeleteIcon } from '../../../commons/Icons'
import { DefaultButton } from '../../../commons/mui/Button/DefaultButton'
import { TextFormat } from '../../../commons/TextFromat/TextFormat'
import { Labels } from '../../Labels'
import { formatNumber } from '../../../utils/formatNumber'
import upArrow from '../../../assets/svg/ico/filterDirection/upArrow.svg'
import downArrow from '../../../assets/svg/ico/filterDirection/downArrow.svg'

interface IBasketList {
  basketList: TBasketList
  basketProducts: IProductIds
  priceTotal: number
  priceVat: number
  basketLocation: IProductLocation
  basketStock: IStock
  setProductQuantity: ({}: { id: number; quantity: number }) => unknown
  onRowClick: (id: number) => Promise<void>
  setDeleteId: (productId: number) => void
  isMsk?: boolean
  currentSort: IBasketSortVariant
  setCurrentSortHandler: (payload: IBasketSortVariant) => void
}

export const BasketList: React.FC<IBasketList> = ({
  basketList,
  priceTotal,
  priceVat,
  setDeleteId,
  setProductQuantity,
  basketProducts,
  basketLocation,
  basketStock,
  onRowClick,
  isMsk,
  currentSort,
  setCurrentSortHandler,
}) => {
  const [hover, setHover] = useState<number>(null)

  const sortHandler = (field: string) => {
    if (currentSort.field === field) {
      setCurrentSortHandler({
        field,
        value: `${currentSort.field}${currentSort.order === 'ASC' ? '_desc' : '_asc'}`,
        order: currentSort.order === 'ASC' ? 'DESC' : 'ASC',
      })
    } else {
      setCurrentSortHandler({
        field,
        value: `${field}_asc`,
        order: 'ASC',
      })
    }
  }

  return (
    <>
      <div className='basket-list'>
        <table className='table'>
          <thead>
            <tr>
              <th>№</th>
              <th>Фото</th>
              <th>Код</th>
              <th>Артикул</th>
              <th />
              <th onClick={() => sortHandler('name')}>
                <div className='basket-list__sort-th'>
                  <span>Название</span>
                  {currentSort.field === 'name' && (
                    <img
                      src={
                        (currentSort.value === 'name_asc' && upArrow) ||
                        (currentSort.value === 'name_desc' && downArrow)
                      }
                      alt='ico'
                    />
                  )}
                </div>
              </th>
              <th>Бренд</th>
              <th>{isMsk ? 'В наличии' : 'Ваш город'}</th>
              <th>{!isMsk && 'Склад (Мск)'}</th>
              <th>Кол-во</th>
              <th onClick={() => sortHandler('price')}>
                <div className='basket-list__sort-th'>
                  <span>Цена за шт.</span>
                  {currentSort.field === 'price' && (
                    <img
                      src={
                        (currentSort.value === 'price_asc' && upArrow) ||
                        (currentSort.value === 'price_desc' && downArrow)
                      }
                      alt='ico'
                    />
                  )}
                </div>
              </th>
              <th onClick={() => sortHandler('totalPrice')}>
                <div className='basket-list__sort-th'>
                  <span>Цена Итого</span>
                  {currentSort.field === 'totalPrice' && (
                    <img
                      src={
                        (currentSort.value === 'totalPrice_asc' && upArrow) ||
                        (currentSort.value === 'totalPrice_desc' && downArrow)
                      }
                      alt='ico'
                    />
                  )}
                </div>
              </th>
              <th>Удалить</th>
            </tr>
          </thead>
          <tbody>
            {basketList.length !== 0 &&
              basketList?.map((item, i) => {
                const basketProduct = basketProducts[item.productId]
                const location = basketLocation[item.productId]
                const stock = basketStock[item.productId]
                return (
                  <tr
                    onClick={() => onRowClick(item.productId)}
                    onMouseOver={() => setHover(item.id)}
                    onMouseLeave={() => setHover(null)}
                    key={item.id}
                  >
                    <td>{i + 1}</td>
                    <td>
                      <div className='products-list__photo'>
                        <Popup
                          trigger={() => (
                            <div>
                              <img src={basketProduct?.pictureDetail || defaultImage} alt='' width='35' height='35' />
                            </div>
                          )}
                          on={['hover', 'focus']}
                          position='right center'
                        >
                          <div className='products-list__full-photo'>
                            <PhotoHover pictureUrl={basketProduct?.pictureDetail || defaultImage} />
                          </div>
                        </Popup>
                      </div>
                    </td>
                    <td>{item.externalId}</td>
                    <td>{basketProduct?.article}</td>
                    <td style={{ paddingTop: 3, paddingBottom: 3 }}>
                      <Labels labels={basketProduct?.labels || []} />
                    </td>
                    <td>
                      <TextFormat stringNumber={1} text={basketProduct?.name || ''} isInner />
                    </td>
                    <td className='basket-list__tm'>{basketProduct?.tm}</td>
                    <td>
                      <AmountBlock amount={+location?.amount} unitOfMeasure={basketProduct?.unitOfMeasure} />
                    </td>
                    <td>
                      {!isMsk && <AmountBlock amount={+stock?.amount} unitOfMeasure={basketProduct?.unitOfMeasure} />}
                    </td>
                    <td>
                      <div className='basket-list__quantity-selector' onClick={event => event.stopPropagation()}>
                        <ButtonQ
                          onChange={(productId, value) => setProductQuantity({ id: productId, quantity: value })}
                          productId={item.id}
                          value={item.quantity}
                          defaultControlValue={+basketProduct?.packages[0]}
                          packages={basketProduct?.packages}
                          unitOfMeasure={basketProduct?.unitOfMeasure}
                          hover={hover === item.id}
                        />
                      </div>
                    </td>
                    <td>
                      <PriceBlock
                        priceDiscount={item.price}
                        fullPrice={item.priceBase}
                        percentDiscount={item?.percentDiscount}
                        layout='table'
                      />
                    </td>
                    <td>
                      <PriceBlock
                        priceDiscount={item.totalPrice}
                        fullPrice={item.totalPrice + item.totalDiscount}
                        percentDiscount={item?.percentDiscount}
                        layout='table'
                      />
                    </td>
                    <td>
                      <div className='delete-btn'>
                        <DefaultButton
                          sx={{ width: 65 }}
                          onClick={e => {
                            e.stopPropagation()
                            setDeleteId(item.id)
                          }}
                          boxShadow={false}
                          color='secondary'
                        >
                          <DeleteIcon />
                        </DefaultButton>
                      </div>
                    </td>
                  </tr>
                )
              })}
          </tbody>
          <tfoot>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td>
                <div className='basket-list__vat-price'>НДС</div>
              </td>
              <td>
                <div className='basket-list__vat-price'>{formatNumber(priceVat)} ₽</div>
              </td>
              <td />
            </tr>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td>
                <div className='basket-list__total-price'>Итого</div>
              </td>
              <td>
                <div className='basket-list__total-price'>{formatNumber(priceTotal)} ₽</div>
              </td>
              <td />
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  )
}
