import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { IMakeOrderFormRes, IOrderDataRes, IOrdersRes, IOrderSurvey, IPostMakeOrderFormRes } from '../types/models'
import { URL } from '../settings/endpoints'
import { IResponce } from '../types/commons'
import { axiosBaseQuery } from './config'

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: axiosBaseQuery({ baseUrl: URL }),
  tagTypes: ['Orders', 'OrderById', 'OrderSurvey'],
  endpoints: build => ({
    getOrders: build.query<IOrdersRes, { isArchive?: boolean; currentPage?: number; perPage?: number }>({
      query: ({ isArchive, currentPage, perPage }) => ({
        url: '/order',
        params: {
          archive: isArchive,
          currentPage,
          perPage,
        },
      }),
      providesTags: result => ['Orders'],
    }),
    getOrderById: build.query<IOrderDataRes | undefined, number>({
      query: (id: number) => ({
        url: `/order/${id}`,
      }),
      providesTags: ['OrderById'],
    }),
    postSurvey: build.mutation<IResponce | undefined, IOrderSurvey>({
      query: (data: IOrderSurvey) => ({
        url: `/order/rating`,
        params: data,
        method: 'POST',
      }),
    }),
    putArchiveOrder: build.mutation<unknown, number>({
      query: id => ({
        url: `/order/archive`,
        method: 'PUT',
        params: { id },
      }),
      invalidatesTags: ['Orders', 'OrderById'],
    }),
    cancelOrder: build.mutation<unknown, number>({
      query: id => ({
        url: `/order/${id}/canceled`,
        method: 'GET',
      }),
      invalidatesTags: ['Orders', 'OrderById'],
    }),
    deleteArchiveOrder: build.mutation<unknown, number>({
      query: id => ({
        url: `/order/archive`,
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['Orders', 'OrderById'],
    }),
    getMakeOrderForm: build.query<IMakeOrderFormRes, null>({
      query: () => ({
        url: `/order/make`,
        method: 'GET',
      }),
    }),
    postOrder: build.mutation<IPostMakeOrderFormRes, unknown>({
      query: formData => {
        return {
          url: `/order/make`,
          method: 'POST',
          data: formData,
        }
      },
      invalidatesTags: ['Orders'],
    }),
  }),
})
