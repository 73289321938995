import React from 'react'
import './ButtonAccordion.scss'

interface IButtonAccordion {
  content: string
  isActive: boolean
  setIsActive: (value: boolean) => void
}

export const ButtonAccordion: React.FC<IButtonAccordion> = ({ content, isActive, setIsActive }) => {
  return (
    <div onClick={() => setIsActive(!isActive)} className='button-accordion'>
      <div className='button-accordion__ico'>{isActive ? <ArrowActive /> : <ArrowPassive />}</div>
      {content}
    </div>
  )
}

const ArrowActive = () => {
  return (
    <svg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.5 1.88462L6.5 6.5L1.5 1.88461'
        stroke='black'
        stroke-width='3'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

const ArrowPassive = () => {
  return (
    <svg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.38462 2L7 7L2.38462 12'
        stroke='#C4C4C4'
        stroke-width='3'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
