import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getTokens } from '../../utils/tokens'

export type Captcha = {
  captchaSid: string
  url: string
}

export type IUser = {
  id: number
  login: string
  name: string
  email: string
  lastName: string
  phone: string
  dateRegister: string
  externalId: string
  organization: string
  organizationShort: string
  location: {
    externalId: string
    qt: string
    agreementId: string
    limitedAssort: string
    locationId: number
    locationExternalId: string
    locationEmail: string
    locationPhone: string
    locationPhoneFooter: string
    city: string
    geoCity: string
    userType: number
    salesChanel: string
    showDisc: string
    cityOnlyPack: string
    deliveryFree: string
    deliveryPrice: string
    priceId: number
    priceCode: string
  }
}

export interface AuthState {
  authorization: string
  error: string
  captcha: Captcha
  user: IUser
}

const { accessToken } = getTokens()

export const initialState: AuthState = {
  authorization: accessToken || '',
  error: '',
  captcha: {
    captchaSid: '',
    url: '',
  },
  user: {
    id: null,
    login: '',
    name: '',
    email: '',
    lastName: '',
    phone: '',
    dateRegister: '',
    externalId: '',
    organization: '',
    organizationShort: '',
    location: {
      externalId: '',
      qt: '',
      agreementId: '',
      limitedAssort: '',
      locationId: null,
      locationExternalId: '',
      locationEmail: '',
      locationPhone: '',
      locationPhoneFooter: '',
      city: '',
      geoCity: '',
      userType: null,
      salesChanel: '',
      showDisc: '',
      cityOnlyPack: '',
      deliveryFree: '',
      deliveryPrice: '',
      priceId: null,
      priceCode: '',
    },
  },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<string>) => {
      state.authorization = action.payload
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
    },
    setCaptcha: (state, action: PayloadAction<Captcha>) => {
      state.captcha = action.payload
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload
    },
  },
})

export const authReducer = authSlice.reducer
