import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { Fade } from '@mui/material'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  transform: 'translate(-50%, -50%)',
}

interface ModalProps {
  open: boolean
  handleClose: () => void
  exitButton?: boolean
}

const ExitIco = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15'>
    <g id='Слой_2' data-name='Слой 2' fill='#fff'>
      <g id='Слой_1-2' data-name='Слой 1'>
        <g id='Vector'>
          <path
            className='cls-1'
            d='M8.87,7.5l5.85-5.84a1,1,0,0,0,0-1.38,1,1,0,0,0-1.38,0L7.5,6.13,1.66.28A1,1,0,0,0,.29.28a1,1,0,0,0,0,1.38L6.13,7.5.29,13.34a1,1,0,0,0,0,1.37A.94.94,0,0,0,1,15a1,1,0,0,0,.69-.29L7.5,8.87l5.84,5.84a1,1,0,0,0,1.38,0,1,1,0,0,0,0-1.37Z'
          />
        </g>
      </g>
    </g>
  </svg>
)

export const BasicModal: React.FC<ModalProps> = ({ children, open, handleClose, exitButton = true }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Fade in={open}>
        <Box style={style}>
          {children}
          {exitButton && (
            <Box
              p='15px'
              width='44px'
              height='44px'
              role='button'
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
            >
              <ExitIco />
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  )
}
