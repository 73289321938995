import React, { useState, useEffect } from 'react'
import './ProductCard.scss'
import { ClipLoader } from 'react-spinners'
import { css } from '@emotion/react'
import { favoriteApi } from '../../api/FavoriteService'
import { ButtonQ } from '../../commons/AmountButton'
import defaultPhoto from '../../assets/png/defaultImage.png'
import { IProduct, IProductQ } from '../../types/models'
import { PriceBlock } from '../../components/PriceBlock'
import { BasketButton } from '../../commons/mui/Button/BasketButton'
import { TextFormat } from '../../commons/TextFromat/TextFormat'
import { Labels } from '../../components/Labels'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { isMsk } from '../../utils/isMsk'
import { Heart } from '../../components/Heart'

interface ProductCard {
  product: IProduct
  productQ: IProductQ
  setProductQ: (productId: number, productQ) => void
  addToBasket: ({}: { productId: number; quantity: number }) => void
  setProductQuantity: ({}: { id: number; quantity: number }) => unknown
  basketCount: number
  isLoadingCard?: boolean
  basketId?: number
  openFullProductCard? : () => Promise<void>
}

const override = css`
  position: absolute;
  top: -15%;
  left: 44%;
`

export const ProductCard: React.FC<ProductCard> = ({
  product,
  productQ,
  setProductQ,
  addToBasket,
  setProductQuantity,
  basketCount,
  isLoadingCard = false,
  basketId,
  openFullProductCard
}) => {
  const { city } = useTypedSelector(state => state.authReducer.user.location)
  const [isHover, setIsHover] = useState(false)

  const { price, location, stock } = product

  const [isClicked, setIsClicked] = useState(false)
  const [newStock, setNewStock] = useState<number>()

  const { data } = favoriteApi.useGetFavoriteQuery(null)
  const [postFavorite] = favoriteApi.usePostFavoriteMutation()
  const [deleteFavorite] = favoriteApi.useDeleteFavoriteMutation()

  const isFavorite = data?.result.includes(product.id)

  useEffect(() => {
    if (isLoadingCard === false) {
      setIsClicked(false)
    }
  }, [isLoadingCard])

  const onBasketClick = () => {
    addToBasket({ productId: product.id, quantity: productQ[product.id] })
  }

  const onMinusClick = (quantity: number) => {
    if (basketCount > 0 && basketId) {
      setProductQuantity({ id: basketId, quantity: basketCount - quantity })
    }
  }

  const onPlusClick = (quantity: number) => {
    if (basketCount > 0 && basketId) {
      setProductQuantity({ id: basketId, quantity: basketCount + quantity })
    } else {
      addToBasket({ productId: product.id, quantity: productQ[product.id] })
    }
  }

  const handleCardClick = async () => {
    setIsClicked(true)
    await openFullProductCard()
  }
  return (
    <div
      className='product-card'
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={ handleCardClick }
    >
      <div
        className='product-card__container'
        style={isLoadingCard && isClicked ? { opacity: '0.5' } : {}}
      >
        <div className='product-card__head'>
          <div className='product-card__markers-container'>
            <div className='markers'>
              <Labels labels={product.labels} />
              {/*<div className='markers__bonus'>*/}
              {/*  <span>+56</span>*/}
              {/*  <Bonus />*/}
              {/*</div>*/}
            </div>
          </div>
          <div
            className='product-card__favorite'
            onClick={event => {
              event.preventDefault()
              isFavorite ? deleteFavorite(product.id) : postFavorite(product.id)
            }}
          >
            <Heart isActive={isFavorite} />
          </div>
          <div className='product-card__photo'>
            <img src={product.pictureDetail || defaultPhoto} height='150' alt='image' />
          </div>
        </div>

        <div className='product-card__content' style={{ position: 'relative' }}>
          <ClipLoader color='black' loading={isLoadingCard && isClicked} css={override} size={25} />
          <div className='product-card__title'>
            <TextFormat
              stringNumber={3}
              text={product.name.length > 90 ? product.name.substr(0, 90) + '...' : product.name}
              isInner
            />
            {/*<h2>{product.name.length > 90 ? product.name.substr(0, 90) + '...' : product.name}</h2>*/}
          </div>

          <div className='product-card__id'>
            <span className='product-card__code'>{product.externalId}</span>
            <span className='product-card__vendor-code'>{product.article}</span>
          </div>

          <div className='count-block'>
            <div className='count-block__price-block'>
              <span className='count-block__title'>Цена Ваша</span>
              <PriceBlock
                fullPrice={price?.price}
                priceDiscount={price?.priceDiscount}
                percentDiscount={price?.percentDiscount}
                layout='card'
              />
            </div>
            <div className='right-coll'>
              <div className='right-coll__storage-block'>
                <span className='right-coll__title'>{isMsk(city) ? 'В наличии' : 'Ваш город'}</span>
                <div style={location?.amount ? null : { color: '#F23B2F' }} className='right-coll__amount'>
                  {location?.amount ? `${location?.amount} (${product.unitOfMeasure})` : 'НЕТ'}
                </div>
              </div>
              {!isMsk(city) && (
                <div className='right-coll__order-block'>
                  <span className='right-coll__title'>Склад (Мск)</span>
                  <div
                    style={stock?.amount ? null : newStock ? null : { color: '#F23B2F' }}
                    className='right-coll__amount'
                  >
                    {stock?.amount
                      ? `${stock?.amount} (${product.unitOfMeasure})`
                      : newStock
                      ? `${newStock} (${product.unitOfMeasure})`
                      : 'НЕТ'}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='buttons-container' onClick={e => e.stopPropagation()}>
            <div className='buttons-container__quantity-btn'>
              <ButtonQ
                onChange={setProductQ}
                productId={product.id}
                value={productQ[product.id]}
                defaultControlValue={+product?.packages[0]}
                hover={true}
                packages={product.packages}
                unitOfMeasure={product.unitOfMeasure}
                onMinusClick={onMinusClick}
                onPlusClick={onPlusClick}
              />
            </div>
            <div className='buttons-container__basket-btn'>
              <BasketButton
                onClick={onBasketClick}
                hover={isHover}
                count={basketCount}
                unitOfMeasure={product.unitOfMeasure}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
