import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { URL } from '../settings/endpoints'
import { ISectionFiltersRes } from '../types/models/section/filters/ISectionFiltersRes'
import { axiosBaseQuery } from './config'

export const sectionApi = createApi({
  reducerPath: 'sectionApi',
  baseQuery: axiosBaseQuery({ baseUrl: `${URL}/section` }),
  endpoints: build => ({
    getSectionFilters: build.query<ISectionFiltersRes, number>({
      query: (id: number) => ({
        url: `/${id}/filters`,
      }),
    }),
  }),
})
