import React, { SVGProps } from 'react'

export const Bonus = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='7.5' cy='8.5' r='7.5' fill='#7629D9' />
        <path
            d='M2.1353 11L3.4853 8.66L2.2073 6.5H3.8633L4.1873 7.13C4.2473 7.256 4.3073 7.385 4.3673 7.517C4.4273 7.649 4.4873 7.778 4.5473 7.904H4.5833C4.6253 7.778 4.6703 7.649 4.7183 7.517C4.7663 7.385 4.8113 7.256 4.8533 7.13L5.0693 6.5H6.6713L5.3933 8.84L6.7433 11H5.0873L4.7273 10.37C4.6613 10.244 4.5953 10.115 4.5293 9.983C4.4633 9.851 4.3973 9.722 4.3313 9.596H4.2953C4.2473 9.722 4.1963 9.851 4.1423 9.983C4.0883 10.109 4.0373 10.238 3.9893 10.37L3.7373 11H2.1353ZM7.46365 11V10.118C7.79965 9.806 8.11165 9.512 8.39965 9.236C8.69365 8.96 8.94865 8.702 9.16465 8.462C9.38065 8.216 9.54865 7.985 9.66865 7.769C9.79465 7.547 9.85765 7.34 9.85765 7.148C9.85765 6.89 9.79165 6.701 9.65965 6.581C9.53365 6.455 9.36565 6.392 9.15565 6.392C8.96365 6.392 8.79265 6.449 8.64265 6.563C8.49865 6.671 8.35765 6.794 8.21965 6.932L7.39165 6.104C7.54165 5.948 7.68865 5.813 7.83265 5.699C7.97665 5.579 8.12665 5.483 8.28265 5.411C8.44465 5.333 8.61265 5.276 8.78665 5.24C8.96665 5.204 9.16165 5.186 9.37165 5.186C9.65965 5.186 9.92365 5.231 10.1636 5.321C10.4036 5.411 10.6106 5.537 10.7846 5.699C10.9586 5.861 11.0936 6.059 11.1896 6.293C11.2856 6.521 11.3336 6.776 11.3336 7.058C11.3336 7.286 11.2826 7.52 11.1806 7.76C11.0846 7.994 10.9586 8.228 10.8026 8.462C10.6466 8.696 10.4696 8.924 10.2716 9.146C10.0796 9.368 9.88765 9.578 9.69565 9.776C9.83965 9.758 10.0016 9.743 10.1816 9.731C10.3676 9.713 10.5236 9.704 10.6496 9.704H11.6036V11H7.46365Z'
            fill='white'
        />
    </svg>
)

export const Warning = () => (
    <svg width='9' height='18' viewBox='0 0 9 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect opacity='0.2' width='9' height='18' rx='2' fill='#FFB800' />
        <path
            d='M3.71598 9.58399L3.37998 5.692L3.29598 3.62H5.70398L5.61998 5.692L5.28398 9.58399H3.71598ZM4.49998 14.168C4.08931 14.168 3.75331 14.028 3.49198 13.748C3.23064 13.468 3.09998 13.1227 3.09998 12.712C3.09998 12.3013 3.23064 11.956 3.49198 11.676C3.75331 11.396 4.08931 11.256 4.49998 11.256C4.91064 11.256 5.24664 11.396 5.50798 11.676C5.76931 11.956 5.89998 12.3013 5.89998 12.712C5.89998 13.1227 5.76931 13.468 5.50798 13.748C5.24664 14.028 4.91064 14.168 4.49998 14.168Z'
            fill='#FFAA00'
        />
    </svg>
)

export const DownArrowSelect = () => (
    <svg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M10 1L5.66667 5L1.33333 1' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
)

export const BasketIcon: React.FC<{ hover: boolean }> = ({ hover }) => {
    const fill = hover ? '#000' : '#E0E0E0'
    return (
        <svg width='24' height='21' viewBox='0 0 24 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.03831 16.8101C7.96281 16.8101 7.09131 17.6522 7.09131 18.6899C7.09131 19.7283 7.96281 20.5705 9.03831 20.5705C10.1138 20.5705 10.9853 19.7283 10.9853 18.6899C10.9853 17.6522 10.1138 16.8101 9.03831 16.8101Z'
                fill={fill}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17.541 16.8101C16.4655 16.8101 15.5933 17.6522 15.5933 18.6899C15.5933 19.7283 16.4655 20.5705 17.541 20.5705C18.6165 20.5705 19.488 19.7283 19.488 18.6899C19.488 17.6522 18.6165 16.8101 17.541 16.8101Z'
                fill={fill}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M22.5037 6.49688H10.4782C10.4782 6.49688 9.76644 6.65184 9.76644 7.40639C9.76644 8.16239 10.4782 8.38326 10.4782 8.38326H21.2609L20.6542 10.3594H10.0567C10.0567 10.3594 9.39369 10.5035 9.39369 11.2067C9.39369 11.9105 10.0567 12.1162 10.0567 12.1162H20.0939L20.1164 12.1147L19.5479 13.9656H8.60694L7.88169 8.38326L7.64544 6.49688C7.64544 6.49688 7.43394 4.7575 7.31469 4.03626C7.19469 3.31574 5.32494 2.41274 1.30644 0.583566C0.360692 0.200497 -0.429058 1.86891 1.30644 2.74367L4.47519 4.17312C4.47519 4.17312 5.21244 4.58515 5.27619 5.14201C5.33919 5.69815 6.62469 14.9034 6.62469 14.9034C6.62469 14.9034 6.67569 16.1829 7.88169 16.1829H19.9762C20.9872 16.1829 21.1844 15.1626 21.1844 15.1626L23.5814 8.28984C23.5814 8.28984 24.2924 6.49688 22.5037 6.49688Z'
                fill={fill}
            />
        </svg>
    )
}

export const SurveyIcon: React.FC<SVGProps<any>> = props => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52.13 49.96'>
        <path
            fill={'#f2f2f2'}
            style={{ transition: 'fill .4s ease' }}
            fillRule={'evenodd'}
            d='m28.24,2.85l5.71,11.56c.35.72,1.04,1.21,1.83,1.33l12.76,1.85c1.99.29,2.79,2.74,1.35,4.15l-9.23,9c-.57.56-.83,1.36-.7,2.15l2.18,12.71c.34,1.99-1.74,3.5-3.53,2.56l-11.41-6c-.71-.37-1.55-.37-2.26,0l-11.41,6c-1.78.94-3.87-.58-3.53-2.56l2.18-12.71c.14-.79-.13-1.59-.7-2.15L2.24,21.75c-1.44-1.41-.65-3.86,1.35-4.15l12.76-1.85c.79-.12,1.48-.61,1.83-1.33l5.71-11.56c.89-1.81,3.47-1.81,4.36,0Z'
        />
        <path d='m39.74,49.96c-.62,0-1.25-.15-1.83-.46l-11.41-6c-.27-.14-.6-.14-.87,0l-11.42,6c-1.33.7-2.92.59-4.14-.3-1.22-.89-1.82-2.36-1.56-3.84l2.18-12.71c.05-.3-.05-.61-.27-.82L1.19,22.82c-1.08-1.05-1.46-2.6-1-4.03.47-1.43,1.68-2.46,3.17-2.68l12.76-1.85c.3-.04.57-.23.7-.51l5.71-11.56c.67-1.35,2.02-2.19,3.52-2.19s2.86.84,3.52,2.19l5.71,11.56c.14.28.4.47.7.51l12.76,1.85c1.49.22,2.71,1.24,3.17,2.68.47,1.43.08,2.98-.99,4.03l-9.24,9c-.22.21-.32.52-.27.82l2.18,12.71c.25,1.49-.34,2.96-1.56,3.84-.69.5-1.5.75-2.31.75Zm-13.68-9.56c.63,0,1.26.15,1.83.45l11.42,6c.46.25.84.03.98-.07.14-.1.46-.4.37-.91l-2.18-12.71c-.22-1.27.2-2.57,1.13-3.48l9.24-9c.37-.36.29-.79.24-.95-.05-.17-.24-.56-.75-.63l-12.76-1.85c-1.28-.19-2.39-.99-2.96-2.15l-5.71-11.56c-.23-.47-.66-.52-.83-.52s-.6.05-.83.52l-5.71,11.56c-.57,1.16-1.68,1.96-2.96,2.15l-12.76,1.85c-.52.08-.7.47-.75.63-.05.17-.14.59.24.95l9.23,9c.93.9,1.35,2.21,1.13,3.48l-2.18,12.71c-.09.51.23.81.37.91s.52.32.98.07l11.42-6c.57-.3,1.2-.45,1.83-.45Z' />
    </svg>
)

export const DeleteIcon: React.FC<SVGProps<any>> = props => (
    <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            d='M5 0V1H0V3H1V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H13C13.5304 18 14.0391 17.7893 14.4142 17.4142C14.7893 17.0391 15 16.5304 15 16V3H16V1H11V0H5ZM3 3H13V16H3V3ZM5 5V14H7V5H5ZM9 5V14H11V5H9Z'
            fill='#C4C4C4'
        />
    </svg>
)

export const ExitIco = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15'>
        <g id='Слой_2' data-name='Слой 2' fill='#fff'>
            <g id='Слой_1-2' data-name='Слой 1'>
                <g id='Vector'>
                    <path
                        className='cls-1'
                        d='M8.87,7.5l5.85-5.84a1,1,0,0,0,0-1.38,1,1,0,0,0-1.38,0L7.5,6.13,1.66.28A1,1,0,0,0,.29.28a1,1,0,0,0,0,1.38L6.13,7.5.29,13.34a1,1,0,0,0,0,1.37A.94.94,0,0,0,1,15a1,1,0,0,0,.69-.29L7.5,8.87l5.84,5.84a1,1,0,0,0,1.38,0,1,1,0,0,0,0-1.37Z'
                    />
                </g>
            </g>
        </g>
    </svg>
)

export const DownloadIcon: React.FC = () => (
    <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0)'>
            <path
                d='M5.00004 6.66665C4.91462 6.66665 4.83295 6.63165 4.7742 6.56998L2.5867 4.27831C2.39712 4.07998 2.53795 3.74998 2.81254 3.74998H3.95837V1.35415C3.95837 1.06706 4.19212 0.833313 4.4792 0.833313H5.52087C5.80795 0.833313 6.0417 1.06706 6.0417 1.35415V3.74998H7.18754C7.46212 3.74998 7.60295 4.07998 7.41337 4.27831L5.22587 6.56998C5.16712 6.63165 5.08545 6.66665 5.00004 6.66665Z'
                fill='black'
            />
            <path
                d='M9.27083 9.16666H0.729167C0.327083 9.16666 0 8.83958 0 8.4375V8.22916C0 7.82708 0.327083 7.5 0.729167 7.5H9.27083C9.67292 7.5 10 7.82708 10 8.22916V8.4375C10 8.83958 9.67292 9.16666 9.27083 9.16666Z'
                fill='black'
            />
        </g>
        <defs>
            <clipPath id='clip0'>
                <rect width='10' height='10' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export const FileIcon: React.FC = () => (
    <svg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M1.4 0C1.0287 0 0.672601 0.1475 0.41005 0.41005C0.1475 0.672601 0 1.0287 0 1.4V12.6C0 12.9713 0.1475 13.3274 0.41005 13.5899C0.672601 13.8525 1.0287 14 1.4 14H9.8C10.1713 14 10.5274 13.8525 10.79 13.5899C11.0525 13.3274 11.2 12.9713 11.2 12.6V4.2L7 0H1.4ZM1.4 1.4H6.3V4.9H9.8V12.6H1.4V1.4ZM2.8 7V8.4H8.4V7H2.8ZM2.8 9.8V11.2H6.3V9.8H2.8Z'
            fill='#A5A5A5'
        />
    </svg>
)

export const DownArrow: React.FC = () => (
    <svg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M7.6665 1L4.4165 4L1.1665 1' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
)

export const PrintIcon: React.FC = () => (
    <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M17 5C18.66 5 20 6.34 20 8V14H16V18H4V14H0V8C0 6.34 1.34 5 3 5H4V0H16V5H17ZM6 2V5H14V2H6ZM14 16V12H6V16H14ZM16 12H18V8C18 7.45 17.55 7 17 7H3C2.45 7 2 7.45 2 8V12H4V10H16V12ZM17 8.5C17 9.05 16.55 9.5 16 9.5C15.45 9.5 15 9.05 15 8.5C15 7.95 15.45 7.5 16 7.5C16.55 7.5 17 7.95 17 8.5Z'
            fill='#C4C4C4'
        />
    </svg>
)

export const FilterIcon: React.FC = () => (
    <svg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M15.2281 0.834388C15.2281 0.373564 14.8545 0 14.3937 0H0.834455C0.662633 0 0.494958 0.0530757 0.354426 0.151913C-0.0225017 0.417018 -0.113126 0.937528 0.151979 1.31446L5.06823 8.30395L5.08204 13.3889C5.0829 13.7063 5.17728 14.0164 5.35332 14.2805C5.84818 15.0228 6.8511 15.2234 7.59342 14.7285L9.34934 13.5579C9.81587 13.2469 10.0951 12.7223 10.0924 12.1617L10.0746 8.33469L15.0733 1.31856C15.174 1.17717 15.2281 1.00793 15.2281 0.834388ZM8.40336 7.80444L8.4237 12.1694L6.75062 13.2848L6.73564 7.77378L2.44151 1.66878H12.7748L8.40336 7.80444Z'
            fill='black'
        />
    </svg>
)

export const PutArchiveIcon: React.FC = () => (
    <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M14.1667 15H0.833333V5.83333H2.5V13.3333H12.5V5.83333H14.1667V15ZM0 0H15V5H0V0ZM1.66667 1.66667V3.33333H13.3333V1.66667H1.66667ZM6.25 6.66667V9.16667H4.16667L7.5 12.5L10.8333 9.16667H8.75V6.66667'
            fill='black'
        />
    </svg>
)

export const RepeatIcon: React.FC = () => (
    <svg width='19' height='16' viewBox='0 0 19 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M0 8C0 12.4178 3.64619 16 8.14286 16C10.3052 16 12.3771 15.1644 13.9333 13.6889L12.5762 12.3556C11.4271 13.5556 9.82571 14.2222 8.14286 14.2222C2.49714 14.2222 -0.325714 7.52 3.66429 3.6C7.65429 -0.32 14.4762 2.46222 14.4762 8H11.7619L15.381 11.5556H15.4714L19 8H16.2857C16.2857 3.58222 12.6395 0 8.14286 0C3.64619 0 0 3.58222 0 8Z'
            fill='black'
        />
    </svg>
)

export const UnselectIcon: React.FC = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M8 14.4C6.30261 14.4 4.67475 13.7257 3.47452 12.5255C2.27428 11.3253 1.6 9.69739 1.6 8C1.6 6.30261 2.27428 4.67475 3.47452 3.47452C4.67475 2.27428 6.30261 1.6 8 1.6C9.69739 1.6 11.3253 2.27428 12.5255 3.47452C13.7257 4.67475 14.4 6.30261 14.4 8C14.4 9.69739 13.7257 11.3253 12.5255 12.5255C11.3253 13.7257 9.69739 14.4 8 14.4ZM8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z'
            fill='black'
        />
    </svg>
)

export const ArchiveIcon: React.FC = () => (
    <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M14.1667 15H0.833333V5.83333H2.5V13.3333H12.5V5.83333H14.1667V15ZM0 0H15V5H0V0ZM5.41667 6.66667H9.58333C9.81667 6.66667 10 6.85 10 7.08333V8.33333H5V7.08333C5 6.85 5.18333 6.66667 5.41667 6.66667ZM1.66667 1.66667V3.33333H13.3333V1.66667H1.66667Z'
            fill='black'
        />
    </svg>
)

export const DateInputIcon: React.FC = () => (
    <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M12 1.5H11.25V0H9.75V1.5H3.75V0H2.25V1.5H1.5C0.6675 1.5 0 2.175 0 3V13.5C0 13.8978 0.158035 14.2794 0.43934 14.5607C0.720644 14.842 1.10218 15 1.5 15H12C12.3978 15 12.7794 14.842 13.0607 14.5607C13.342 14.2794 13.5 13.8978 13.5 13.5V3C13.5 2.60218 13.342 2.22064 13.0607 1.93934C12.7794 1.65804 12.3978 1.5 12 1.5ZM12 13.5H1.5V6H12V13.5ZM12 4.5H1.5V3H12V4.5ZM6.75 8.25H10.5V12H6.75V8.25Z'
            fill='black'
        />
    </svg>
)

export const CancelIcon = () => (
    <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M7.5 0C11.6475 0 15 3.3525 15 7.5C15 11.6475 11.6475 15 7.5 15C3.3525 15 0 11.6475 0 7.5C0 3.3525 3.3525 0 7.5 0ZM10.1925 3.75L7.5 6.4425L4.8075 3.75L3.75 4.8075L6.4425 7.5L3.75 10.1925L4.8075 11.25L7.5 8.5575L10.1925 11.25L11.25 10.1925L8.5575 7.5L11.25 4.8075L10.1925 3.75Z'
            fill='#F23B2F'
        />
    </svg>
)
