const ACCESS_TOKEN = 'ACCESS_TOKEN'
const REFRESH_TOKEN = 'REFRESH_TOKEN'

export const getTokens = () => ({
  accessToken: localStorage.getItem(ACCESS_TOKEN) || sessionStorage.getItem(ACCESS_TOKEN),
  refreshToken: localStorage.getItem(REFRESH_TOKEN) || sessionStorage.getItem(REFRESH_TOKEN),
})

export const setAccessToken = (accessToken: string, isSession = false) => {
  if (isSession) {
    sessionStorage.setItem(ACCESS_TOKEN, accessToken)
  } else {
    localStorage.setItem(ACCESS_TOKEN, accessToken)
  }
}

export const setRefreshToken = (refreshToken: string, isSession = false) => {
  if (isSession) {
    sessionStorage.setItem(REFRESH_TOKEN, refreshToken)
  } else {
    localStorage.setItem(REFRESH_TOKEN, refreshToken)
  }
}

export const clearTokens = (): void => {
  localStorage.removeItem(ACCESS_TOKEN)
  localStorage.removeItem(REFRESH_TOKEN)
  sessionStorage.removeItem(ACCESS_TOKEN)
  sessionStorage.removeItem(REFRESH_TOKEN)
}
