import React, { useState } from 'react'
import './Header.scss'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
// import { useMediaQuery } from 'react-responsive'
import { Box } from '@mui/material'
import logo from '../../assets/png/new-logo.jpg'
import person from '../../assets/svg/Person.svg'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { basketApi } from '../../api/BasketService'
import { formatNumber } from '../../utils/formatNumber'
import { logout } from '../../axios'
import banner from '../../assets/svg/bunners/shortBanner.svg'

export const Header = () => {
  const user = useTypedSelector(state => state.authReducer.user)
  const { authorization } = useTypedSelector(state => state.authReducer)
  const { data } = basketApi.useGetBasketQuery('id_asc', { skip: authorization === '' })
  const basket = data?.result
  const [isPopup, setIsPopup] = useState(false)
  // const [isSticker, setIsSticker] = useState(false)
  const location = useLocation()
  // const isLaptop = useMediaQuery({ maxWidth: 1440 })
  // const isDesktop = useMediaQuery({ minWidth: 1920 })

  // let leadriveCityId = user.location.locationId

  // const openSticker = () => {
  //   setIsSticker(!isSticker)
  // }

  const logoutHandler = async () => {
    await logout()
  }

  return (
    <div className='header'>
      <CSSTransition in={isPopup} timeout={200} classNames='show-el' unmountOnExit>
        <div className='popup-background' onClick={() => setIsPopup(false)} />
      </CSSTransition>

      <Link to='/'>
        <div className='logo'>
          <img src={logo} alt='logo' />
        </div>
      </Link>
      {!/\/login/.test(location.pathname) && (
        <div className='header__container'>
          <div className='navbar'>
            {/* <div className='navbar__button-block'>
              <button className='navbar__btn-phone' />
              <button className='navbar__btn-mail' />
            </div> */}

            <div className='navbar__link-block'>
              {/*<a href='#'>АКЦИИ</a>*/}
              {/*<a href='#' className='wow-color'>*/}
              {/*  ВАУ*/}
              {/*</a>*/}
              <NavLink to='/news' activeClassName='link_active'>
                НОВОСТИ
              </NavLink>
              <NavLink to='/catalog' activeClassName='link_active'>
                КАТАЛОГ
              </NavLink>
              {/*<a href='#'>ФИНАНСЫ</a>*/}
              <NavLink to='/orders' activeClassName='link_active'>
                ЗАКАЗЫ
              </NavLink>
              <NavLink to='/feedback' activeClassName='link_active'>
                ОБРАТНАЯ СВЯЗЬ
              </NavLink>
            </div>
          </div>

          <div className='header-info'>
            <Box mx={'12px'}>
              <a href={'https://www.informat.ru/personal/login/'}>
                <img alt={'old_personal_login'} src={banner} height={'48px'} />
              </a>
            </Box>
            {/*{isDesktop && /\/basket/.test(location.pathname) && (*/}
            {/*  <div className='header-info__debt'>*/}
            {/*    <Debt isDesktop={isDesktop} />*/}
            {/*  </div>*/}
            {/*)}*/}

            {/*{!isLaptop && (*/}
            {/*  <div className='header-info__stickers'>*/}
            {/*    <div className='button' onClick={openSticker}>*/}
            {/*      <img src={stickers} alt='stickers' />*/}
            {/*    </div>*/}
            {/*    <div className='stickers-dropdown'>*/}
            {/*      <CSSTransition in={isSticker} timeout={400} classNames='drop-down' unmountOnExit>*/}
            {/*        <Sticker />*/}
            {/*      </CSSTransition>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}

            {/*<div className='header-info__level'>*/}
            {/*  <img src={level} alt='level' />*/}
            {/*  <div className='score'>*/}
            {/*    <div className='score__points'>82 000</div>*/}
            {/*    <div className='score__level'>До уровня II</div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <Box mr='50px'>
              <button onClick={logoutHandler}>Выход</button>
            </Box>
            {/*<div className='popup' onClick={() => setIsPopup(true)}>*/}
            {/*  <span className='popup__score'>23</span>*/}

            {/*  <CSSTransition in={isPopup} timeout={100} classNames='show-el' unmountOnExit>*/}
            {/*    <div className='popup__window'>*/}
            {/*      <PopupWindow />*/}
            {/*    </div>*/}
            {/*  </CSSTransition>*/}
            {/*</div>*/}

            <div className='header-info__identification'>
              <div className='identification'>
                <div className='identification__person'>
                  <img src={person} alt='person' />
                  <div className='identification__name'>{user.organizationShort}</div>
                </div>
                <div className='identification__bank'>{user.organization}</div>
                <div className='identification__city'>{user.location?.city && `г. ${user.location.city}`}</div>
              </div>
            </div>

            <NavLink to='/basket'>
              <div
                className={/\/basket/.test(location.pathname) ? 'header-basket header-basket_active' : 'header-basket'}
              >
                <div className='header-basket__number'>{basket?.listCount}</div>
                <div className='header-basket__sum'>{formatNumber(basket?.priceTotal)}</div>
              </div>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  )
}
