import { IBasket, IProductLocation, IProductIds, IProductPrice, ProductLabel } from '../../types/models'

export interface BasketState {
  basket: IBasket
  location: IProductLocation
  price: IProductPrice
  isLoading: boolean
  isBasketFetched: boolean
}

export const initialState: BasketState = {
  basket: {
    basketId: null,
    limit: null,
    listCount: null,
    priceTotal: null,
    priceVat: null,
    priceNotDiscount: null,
    priceDiscount: null,
    discountPercent: null,
    priceSaved: null,
    list: [
      {
        id: null,
        name: '',
        productId: null,
        externalId: '',
        quantity: null,
        priceBase: null,
        price: null,
        weight: null,
        notes: null,
        currency: '',
        vatRate: null,
        discount: null,
        totalPrice: null,
        totalDiscount: 0,
        percentDiscount: null,
        tm: '',
      },
    ],
    product: {
      0: {
        id: null,
        sort: null,
        barcode: '',
        name: '',
        code: '',
        detailText: '',
        unitOfMeasure: '',
        erpCategoryId: null,
        sectionId: null,
        previewText: '',
        picture: '',
        pictureDetail: '',
        morePictures: [''],
        labels: ['' as ProductLabel],
        pageUrl: '',
        externalId: '',
        article: '',
        producer: '',
        new: false,
        superSale: '',
        forTender: '',
        tm: '',
        packages: [''],
        certificates: [
          {
            number: '',
            url: '',
            type: '',
          },
        ],
        specifications: [
          {
            id: null,
            name: null,
            code: null,
            sort: null,
            externalId: null,
            value: null,
          },
        ],
      },
    },
  },
  price: {
    0: {
      ruleId: null,
      productId: null,
      priceGroupId: null,
      priceBase: null,
      price: null,
      priceDiscount: null,
      percentDiscount: null,
      percentExtra: null,
      currency: '',
    },
  },
  location: {
    0: {
      amount: null,
      locationId: null,
      productId: null,
    },
  },
  isLoading: false,
  isBasketFetched: false,
}

export enum ActionTypes {
  SET_BASKET = 'SET_BASKET',
  SET_BASKET_PRODUCT = 'SET_BASKET_PRODUCT',
  SET_BASKET_LOCATION = 'SET_BASKET_LOCATION',
  SET_BASKET_PRODUCT_Q = 'SET_BASKET_PRODUCT_Q',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_BASKET_FETCHED = 'SET_IS_BASKET_FETCHED',
}

export interface ActionSetBasket {
  type: ActionTypes.SET_BASKET
  payload: IBasket
}

export interface ActionSetBasketProduct {
  type: ActionTypes.SET_BASKET_PRODUCT
  payload: IProductIds
}

export interface ActionSetBasketLocation {
  type: ActionTypes.SET_BASKET_LOCATION
  payload: IProductLocation
}

export interface ActionBooleanPayload {
  type: ActionTypes.SET_IS_BASKET_FETCHED | ActionTypes.SET_IS_LOADING
  payload: boolean
}

export interface ActionSetProductQ {
  type: ActionTypes.SET_BASKET_PRODUCT_Q
  payload: {
    productId: number
    productQ: number
  }
}

export type BasketAction =
  | ActionSetBasket
  | ActionSetBasketProduct
  | ActionBooleanPayload
  | ActionSetBasketLocation
  | ActionSetProductQ

export const basketReducer = (state = initialState, action: BasketAction): BasketState => {
  switch (action.type) {
    case ActionTypes.SET_BASKET:
      return { ...state, basket: action.payload }
    case ActionTypes.SET_BASKET_LOCATION:
      return { ...state, location: action.payload }
    case ActionTypes.SET_BASKET_PRODUCT_Q:
      return {
        ...state,
        basket: {
          ...state.basket,
          list: state.basket.list.map(product =>
            product.id === action.payload.productId ? { ...product, productQ: action.payload.productQ } : product,
          ),
        },
      }
    case ActionTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload }
    case ActionTypes.SET_IS_BASKET_FETCHED:
      return { ...state, isBasketFetched: action.payload }
    default:
      return state
  }
}
