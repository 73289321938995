import { ChangeEvent, useRef, useState } from 'react'
import { useBasket } from './useBasket'

export type StepUpload = 'basketCheck' | 'submitting' | 'finish'

export const useImport = () => {
  const { basket } = useBasket()

  const [file, setFile] = useState<File>()
  const [stepUpload, setStepUpload] = useState<StepUpload>('basketCheck')
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onUploadClick = () => {
    inputRef.current?.click()
  }

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }

    setFile(event.target.files[0])

    if (basket.list.length) {
      setStepUpload('basketCheck')
    } else {
      setStepUpload('submitting')
    }
  }

  return {
    file,
    setFile,
    stepUpload,
    setStepUpload,
    inputRef,
    onFileChange,
    onUploadClick,
  }
}
