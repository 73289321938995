import React, { useEffect, useState } from 'react'
import './Orders.scss'
import printJS from 'print-js'
import { useLocation } from 'react-router-dom'
import { OrdersToolbar } from '../../containers/ordersToolbar'
import { OrdersList } from '../../containers/ordersList'
import { BackgroundLayout } from '../../layouts/backgroundLayout'
import { Axios } from '../../axios'
import { GET_ORDER, POST_PRODUCT_STOCK } from '../../settings/endpoints'
import { IOrder, IOrderDataRes, IProductStockRes } from '../../types/models'
import Loader from '../../commons/Loader/Loader'
import { orderApi } from '../../api/OrderService'
import { IErrorResponse, IPagination } from '../../types/commons'
import { useCustomSnackbar } from '../../hooks/useSnackbar'
import OrderSurvey from '../../containers/orderSurvey/OrderSurvey'

export const Orders = () => {
  document.title = 'Заказы'
  const { errorSnack } = useCustomSnackbar()
  const location = useLocation()
  const [survey, setSurvey] = useState<null | string>(null)
  const [printDataLoading, setPrintDataLoading] = useState<boolean>(false)
  const [selected, setSelected] = useState<IOrder[]>([])
  const [isArchive, setIsArchive] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const {
    data: resData,
    isLoading,
    isFetching,
    error: dataError,
    isError: isDataError,
  } = orderApi.useGetOrdersQuery({ isArchive: isArchive, currentPage, perPage })
  const { pagination, status, data } = resData?.result ?? {}
  const [putArchive, { isLoading: isPutArchiveLoading, error: putArchiveError, isError: isPutArchiveError }] =
    orderApi.usePutArchiveOrderMutation()
  const [
    deleteArchive,
    { isLoading: isDeleteArchiveLoading, error: deleteArchiveError, isError: isDeleteArchiveError },
  ] = orderApi.useDeleteArchiveOrderMutation()

  const [
    postSurvey,
    {
      isLoading: isPostSurveyLoading,
      error: postSurveyError,
      isError: isPostSurveyError,
      isSuccess: isPostSurveySuccess,
    },
  ] = orderApi.usePostSurveyMutation()

  useEffect(() => {
    if (isDataError) {
      const errorMessage = (dataError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', dataError)
    }
  }, [dataError, errorSnack, isDataError])

  useEffect(() => {
    const orderId = (location?.state as { openSurvey?: string })?.openSurvey
    if (orderId) {
      setSurvey(orderId)
    }
  }, [location?.state])

  useEffect(() => {
    if (isPutArchiveError) {
      const errorMessage = (putArchiveError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', putArchiveError)
    }
  }, [errorSnack, isPutArchiveError, putArchiveError])

  useEffect(() => {
    if (isDeleteArchiveError) {
      const errorMessage = (deleteArchiveError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', deleteArchiveError)
    }
  }, [deleteArchiveError, errorSnack, isDeleteArchiveError])

  useEffect(() => {
    if (postSurveyError) {
      const errorMessage = (postSurveyError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', postSurveyError)
    }
  }, [postSurveyError, errorSnack, isPostSurveyError])

  const handleArchive = () => {
    selected.forEach(el => putArchive(el.id))
  }

  const handleDeleteArchive = () => {
    selected.forEach(el => deleteArchive(el.id))
  }

  const handlePrint = async (id: number) => {
    setPrintDataLoading(true)
    const res = await Axios.get<IOrderDataRes>(GET_ORDER(id))
    setPrintDataLoading(false)

    const data = res.data.result.list.map(item => {
      return { ...item, ...res.data.result.product[item.productId] }
    })

    const ids = data.map(item => item.productId)

    const stockRes = await Axios.post<IProductStockRes>(POST_PRODUCT_STOCK, {
      id: ids,
    })

    const result = data.map(item => {
      return { ...item, stockCount: stockRes.data.result[item.productId].amount }
    })

    printJS({
      printable: Object.values(result),
      properties: [
        { field: 'externalId', displayName: 'Код' },
        { field: 'article', displayName: 'Артикул' },
        { field: 'name', displayName: 'Название' },
        { field: 'tm', displayName: 'Бренд' },
        { field: 'stockCount', displayName: 'Под заказ' },
        { field: 'quantity', displayName: 'Кол-во' },
        { field: 'price', displayName: 'Цена за шт.' },
        { field: 'totalPrice', displayName: 'Цена Итого' },
      ],
      type: 'json',
      gridHeaderStyle: 'border: 1px solid black; table-layout: auto',
      gridStyle: 'border: 1px solid black; table-layout: auto;',
    })
  }

  return (
    <BackgroundLayout>
      <Loader
        isLoading={
          printDataLoading ||
          isLoading ||
          isFetching ||
          isPutArchiveLoading ||
          isDeleteArchiveLoading ||
          isPostSurveyLoading
        }
      />
      <div className='orders'>
        <OrdersToolbar
          selectedCount={selected.length}
          setIsArchive={setIsArchive}
          isArchive={isArchive}
          putArchive={handleArchive}
          deleteArchive={handleDeleteArchive}
        />
        <OrdersList
          handlePrint={handlePrint}
          orders={data ?? []}
          orderStatuses={status ?? []}
          selected={selected}
          setSelected={setSelected}
          pagination={pagination ?? ({} as IPagination)}
          setCurrentPage={setCurrentPage}
          setPerPage={setPerPage}
        />
      </div>
      <OrderSurvey
        open={survey}
        setOpen={setSurvey}
        postSurvey={data => postSurvey(data)}
        isPostSurveySuccess={isPostSurveySuccess}
      />
    </BackgroundLayout>
  )
}
