import React from 'react'
import './TableTools.scss'
import ReactPaginate from 'react-paginate'
import { Box } from '@mui/material'
import download from '../../assets/svg/DownloadIco.svg'
import { Select } from '../../components/Buttons'
import { IPagination } from '../../types/commons'

interface TableTools {
  pagination: IPagination
  onPageChange: (selectedPage: number) => void
  onPerPageChange: (perPage: number) => void
  isDownload?: boolean
}

export const TableTools: React.FC<TableTools> = ({ pagination, onPageChange, onPerPageChange, isDownload }) => {
  const handleChangePage = ({ selected }) => {
    // Прибавляем 1 т.к в либе используются индексы
    onPageChange(selected + 1)
  }

  const handleSelectPerPage = (perPage: number) => {
    onPerPageChange(perPage)
  }

  return (
    <Box className='table-tools'>
      {isDownload ? (
        <div className='download-block'>
          <div className='download-block__title'>Скачать изображения</div>
          <div className='download-block__tools'>
            <div className='download-block__tools-item'>
              <img src={download} alt='ico' />
              <span>Текущий раздел</span>
            </div>
            <div className='download-block__tools-item'>
              <img src={download} alt='ico' />
              <span>Все</span>
            </div>
          </div>
        </div>
      ) : (
        <Box minWidth='200px' />
      )}

      {pagination.totalPages > 1 && (
        <ReactPaginate
          pageCount={pagination.totalPages}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handleChangePage}
          forcePage={pagination.currentPage - 1}
          breakLabel='...'
          previousLabel='Назад'
          previousLinkClassName='paginator__back'
          nextLabel='Далее'
          nextLinkClassName='paginator__next'
          containerClassName='paginator'
          pageLinkClassName='paginator__page button'
          breakLinkClassName='paginator__page button'
          activeLinkClassName='paginator__page_active'
        />
      )}

      <Box className='element-counter'>
        <span className='element-counter__title'>Показывать по</span>
        <Box height='40px' width='80px'>
          <Select
            currentValue={pagination.perPage}
            dropdownDirection='up'
            handleSelect={handleSelectPerPage}
            data={[10, 20, 30, 40, 50]}
            optionsPadding='45'
            optionsWidth='80'
          />
        </Box>
      </Box>
    </Box>
  )
}
