import React, { ReactElement } from 'react'
import './Select.scss'
import { CSSTransition } from 'react-transition-group'

export interface Select {
  trigger: ReactElement
  setIsOpen: (isOpen: boolean) => void
  isOpen: boolean
}

export const Select: React.FC<Select> = ({ children, trigger, setIsOpen, isOpen }) => {
  return (
    <>
      {isOpen && (
        <div
          className='dropdown-background'
          onClick={() => {
            setIsOpen(false)
          }}
        />
      )}
      <div className='select'>
        <div className='select__trigger'>{trigger}</div>
        <CSSTransition in={isOpen} timeout={200} classNames='show-el' unmountOnExit>
          <div className='dropdown'>{children}</div>
        </CSSTransition>
      </div>
    </>
  )
}
