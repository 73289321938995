import React, { useEffect, useState } from 'react'
import './Table.scss'
import { Column, Row, useRowSelect, useTable } from 'react-table'
import { IProduct } from '../../types/models'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { setIsClearRows } from '../../redux/reducers/ordersReducer'

interface Table {
  columns: readonly Column<any>[]
  data?: any
  onRowClick: (id: number) => void
  isFooter: boolean
  setSelected?: (data: any) => void
}

export const Table: React.FC<Table> = ({ data, columns, onRowClick, isFooter, setSelected }) => {
  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
    state: { selectedRowIds },
    selectedFlatRows,
    toggleAllRowsSelected,
  } = useTable<any>(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ['id', 'unitOfMeasure'],
      },
    },
    useRowSelect,
  )

  const [hover, setHover] = useState<number>(null)
  const dispatch = useAppDispatch()
  const { isClearRows } = useTypedSelector(state => state.ordersReducer)

  useEffect(() => {
    setSelected && setSelected(selectedFlatRows.map(d => d.original))
  }, [selectedRowIds])

  useEffect(() => {
    // если есть флаг о необходимости очистить выбранные заказы, то очищаем
    if (isClearRows) {
      toggleAllRowsSelected(false)
      // после очистки убираем флаг
      dispatch(setIsClearRows(false))
    }
  }, [isClearRows])

  return (
    <table className='table'>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row: Row<IProduct>) => {
          prepareRow(row)
          return (
            <tr
              key={row.id}
              {...row.getRowProps()}
              onClick={() => onRowClick(row.original.id)}
              onDoubleClick={null}
              onMouseEnter={() => setHover(row.original.id)}
              onMouseLeave={() => setHover(null)}
            >
              {row.cells.map(cell => {
                return (
                  <td key={cell.row.id} {...cell.getCellProps()}>
                    {cell.render('Cell', { hover: hover })}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
      {isFooter && (
        <tfoot>
          {footerGroups.map(group => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map(column => (
                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      )}
    </table>
  )
}
