import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IProduct, IProductQ } from '../types/models'
import { basketApi } from '../api/BasketService'
import { useActions } from './useActions'
import { useTypedSelector } from './useTypedSelector'

export const useCatalog = (products: IProduct[], pageTitle = 'Каталог') => {
  const history = useHistory()
  const [locationSearch, setLocationSearch] = useState('')

  const [isFullProductCard, setIsFullProductCard] = useState(false)
  const [location, setLocation] = useState<any>()

  const { product, productQ, isLoading } = useTypedSelector(state => state.productReducer)
  const { fetchProduct, setProductBreadcrumbs, setProductQ } = useActions()

  const [addToBasket, { isLoading: isAddBasketLoading, isError: isErrorAddToBasket, error: errorAddToBasket }] =
    basketApi.usePostProductBasketMutation()
  const [setProductQuantity] = basketApi.usePutProductBasketMutation()
  const { data: basketData, isError: isBasketError, error: basketError } = basketApi.useGetBasketQuery(null)

  const openFullProductCard = async (id?: number, isRedirect = true) => {
    setLocationSearch(history.location.search)
    if (id) {
      await fetchProduct(id)
      setIsFullProductCard(true)
      if (isRedirect) {
        const currentProduct = products.find(product => product.id === id)
        document.title = currentProduct?.name || pageTitle
      }
    } else {
      setIsFullProductCard(false)
      document.title = pageTitle
      setProductBreadcrumbs([
        { id: null, level: null, parentId: null, type: null, name: null, code: null, pageUrl: null },
      ]) // очистка breadcrumbs после закрытия карточки товара
    }
    setLocationSearch('')
  }

  const setProductQHandler = (productId: number, productQ: number) => {
    const products = {}
    products[productId] = productQ
    setProductQ(products)
  }

  useEffect(() => {
    const productQ: IProductQ = {}
    // количество товара по ключу id
    products?.forEach((product: IProduct) => (productQ[product.id] = Number(product.packages[0])))
    setProductQ(productQ)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  useEffect(() => {
    if (product.id && products) {
      const tmp = products.filter(i => i.id === product.id)[0]
      if (tmp) {
        setLocation(tmp.location)
      }
    }
  }, [product, products])

  return {
    isLoading,
    isFullProductCard,
    product,
    location,
    productQ,
    basketList: basketData?.result?.list || [],
    isBasketError,
    basketError,
    isAddBasketLoading,
    isErrorAddToBasket,
    errorAddToBasket,
    openFullProductCard,
    addToBasket,
    setProductQHandler,
    setProductQuantity,
  }
}
