import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { authApi } from '../../api/AuthService'
import { favoriteApi } from '../../api/FavoriteService'
import { newsApi } from '../../api/NewsService'
import { searchApi } from '../../api/SearchService'
import { sectionApi } from '../../api/SectionService'
import { productApi } from '../../api/ProductService'
import { orderApi } from '../../api/OrderService'
import { basketApi } from '../../api/BasketService'
import { basketApiOld } from '../../api/BasketServiceOld'
import { mainApi } from '../../api/MainService'
import { authReducer } from './authReducer'
import { infoReducer } from './infoReducer'
import { productReducer } from './productReducer'
import { basketReducer } from './basketReducer'
import { filterReducer } from './filterReducer'
import { ordersReducer } from './ordersReducer'
import { searchReducer } from './searchReducer'

export const rootReducer = combineReducers({
  authReducer,
  filterReducer,
  [authApi.reducerPath]: authApi.reducer,
  [favoriteApi.reducerPath]: favoriteApi.reducer,
  [sectionApi.reducerPath]: sectionApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [basketApi.reducerPath]: basketApi.reducer,
  [basketApiOld.reducerPath]: basketApiOld.reducer,
  [mainApi.reducerPath]: mainApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  searchReducer: searchReducer,
  infoReducer: infoReducer,
  productReducer: productReducer,
  basketReducer,
  ordersReducer,
})

export const setUpStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(
        authApi.middleware,
        favoriteApi.middleware,
        sectionApi.middleware,
        productApi.middleware,
        orderApi.middleware,
        basketApi.middleware,
        mainApi.middleware,
        searchApi.middleware,
        newsApi.middleware,
      ),
  })
}
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setUpStore>
export type AppDispatch = AppStore['dispatch']
