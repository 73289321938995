import React, { useCallback, useState } from 'react'
import './OrdersToolbar.scss'
import { ModalRightSide } from '../../commons/modals/modalRightSide'
import { OrderFilter } from '../orderFilter'
import { PutArchiveIcon, FilterIcon, RepeatIcon, UnselectIcon, ArchiveIcon } from '../../commons/Icons'
import { DefaultButton } from '../../commons/mui/Button/DefaultButton'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { setIsClearRows } from '../../redux/reducers/ordersReducer'

interface OrdersToolbar {
  selectedCount: number
  isArchive: boolean
  setIsArchive: (isArchive: boolean) => void
  putArchive: () => unknown
  deleteArchive: () => unknown
}

export const OrdersToolbar: React.FC<OrdersToolbar> = ({
  selectedCount,
  isArchive,
  setIsArchive,
  putArchive,
  deleteArchive,
}) => {
  const dispatch = useAppDispatch()
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setIsFilterOpen(!isFilterOpen)
  }, [isFilterOpen])

  const clearSelectedRows = () => {
    // если есть выбранные заказы, то устанавливаем флаг о необходимости очистить выбранные заказы
    if (selectedCount > 0) {
      dispatch(setIsClearRows(true))
    }
  }

  function declOfNum(number, titles) {
    // Реализует подбор окончаний
    let cases = [2, 0, 1, 1, 1, 2]
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
  }

  return (
    <>
      <ModalRightSide handleOpen={handleOpen} isOpen={isFilterOpen}>
        <OrderFilter />
      </ModalRightSide>
      <div className='orders-toolbar'>
        <div className='orders-toolbar__container'>
          <div className='left-col'>
            <div className='title'>
              <span className='title__name'> {isArchive ? 'Архив заказов' : 'Мои заказы'}</span>
              {/*<span className='title__count'>7</span>*/}
            </div>
            <span className='select-count'>
              {(selectedCount > 1 ? 'Выбраны' : 'Выбран') +
                ' ' +
                selectedCount +
                ' ' +
                declOfNum(selectedCount, ['заказ', 'заказа', 'заказов'])}
            </span>
            <div className='button-block'>
              <DefaultButton
                color='secondary'
                startIcon={<PutArchiveIcon />}
                onClick={isArchive ? deleteArchive : putArchive}
              >
                {isArchive ? 'Достать из архива' : 'В архив'}
              </DefaultButton>
              {/* <DefaultButton color='secondary' startIcon={<RepeatIcon />}>
                Повторить
              </DefaultButton> */}
              <DefaultButton color='secondary' startIcon={<UnselectIcon />} onClick={clearSelectedRows}>
                Снять выделение
              </DefaultButton>
            </div>
          </div>
          <div className='right-col'>
            <DefaultButton color='secondary' startIcon={<ArchiveIcon />} onClick={() => setIsArchive(!isArchive)}>
              {isArchive ? 'Все заказы' : 'Архив заказов'}
            </DefaultButton>
            <DefaultButton
              color='secondary'
              onClick={handleOpen}
              startIcon={<FilterIcon />}
              sx={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              Фильтр
            </DefaultButton>
          </div>
        </div>
      </div>
    </>
  )
}
