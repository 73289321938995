import React, { FC } from 'react'
import { Link } from 'react-router-dom'

interface NewsItemProps {
  id: string
  name: string
  activeFrom: string
  previewPicture: string
}

export const NewsItem: FC<NewsItemProps> = props => {
  const { id, previewPicture, name, activeFrom } = props

  return (
    <li>
      <div className='news-item'>
        <div className='news-item__image'>
          <Link to={`/news/${id}`}>
            <img src={previewPicture} alt='' />
          </Link>
        </div>
        <div className='news-item__text'>
          <Link to={`/news/${id}`} className='news-item__text-link'>
            {name}
          </Link>
        </div>
        {activeFrom && <span className='news-item__date'>{activeFrom.split(' ')[0]}</span>}
      </div>
    </li>
  )
}
