import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { URL } from '../settings/endpoints'
import {
  ISearchCompletionsQuery,
  ISearchCompletionsRes,
  ISearchHistoryRes,
  ISearchQuery,
  ISearchRes,
} from '../types/models/search'
import { axiosBaseQuery } from './config'

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: axiosBaseQuery({ baseUrl: URL }),
  tagTypes: ['Search'],
  endpoints: build => ({
    getSearch: build.query<ISearchRes, ISearchQuery>({
      query: params => ({
        url: '/search',
        params,
      }),
    }),
    getSearchHistory: build.query<ISearchHistoryRes, void>({
      query: () => ({
        url: '/search/history',
      }),
    }),
    getSearchCompletions: build.query<ISearchCompletionsRes, ISearchCompletionsQuery>({
      query: params => ({
        url: '/search/completions',
        params,
      }),
    }),
  }),
})
