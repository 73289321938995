import React from 'react'
import './Widget.scss'
import { TableTools } from '../../containers/bottomTools'
import { IPagination } from '../../types/commons'
import { IProductDetails } from '../../types/models'
import { ProductCard } from '../../containers/productCard'
import { useCatalog } from '../../hooks/useCatalog'
import { getBasketCount } from '../../utils/getBasketCount'
import { FullProductCard } from '../../containers/fullProductCard'
import { BasicModal } from '../../commons/mui/BasicModal'

interface WidgetProps {
  title: string
  products: IProductDetails[]
  pagination?: IPagination
  onPageChange?: (selectedPage: number) => void
  onPerPageChange?: (perPage: number) => void
}
export const Widget = ({ title, products, pagination, onPageChange, onPerPageChange }: WidgetProps): JSX.Element => {
  const {
    product,
    location,
    isLoading,
    isFullProductCard,
    isAddBasketLoading,
    basketList,
    productQ,
    openFullProductCard,
    setProductQHandler,
    setProductQuantity,
    addToBasket
  } = useCatalog(products)
  return (
    <div className='widget'>
      <h2 className='widget__title'>{title}</h2>
      <div className='widget__grid'>
        {products.map(product => (
          <ProductCard
            key={product.id}
            product={product}
            productQ={productQ}
            basketCount={getBasketCount(product, basketList)}
            basketId={basketList.find(item => item.productId === product.id)?.id}
            isLoadingCard={isLoading}
            addToBasket={addToBasket}
            setProductQuantity={setProductQuantity}
            setProductQ={setProductQHandler}
            openFullProductCard={() => openFullProductCard(product.id, false)}
          />
        ))}
      </div>
      <BasicModal handleClose={() => openFullProductCard(undefined, false)} open={!!isFullProductCard}>
        <FullProductCard
          product={product}
          productQ={productQ[product.id]}
          productLocation={location}
          setProductQ={setProductQHandler}
          addToBasket={addToBasket}
          setProductQuantity={setProductQuantity}
          basketCount={getBasketCount(product, basketList)}
          isAddBasketLoading={isAddBasketLoading}
          handleClose={openFullProductCard}
          basketId={basketList.find(item => item.productId === product.id)?.id}
        />
      </BasicModal>
      {pagination && onPageChange && onPerPageChange && (
        <div>
          <TableTools pagination={pagination} onPageChange={onPageChange} onPerPageChange={onPerPageChange} />
        </div>
      )}
    </div>
  )
}
