import React from 'react'
import './ModalRightSide.scss'
import { CSSTransition } from 'react-transition-group'
import { ExitButtonWhite } from '../../../components/exitButtonWhite'

export const ModalRightSide = ({ children, isOpen, handleOpen }) => {
  return (
    <CSSTransition in={isOpen} timeout={400} classNames='modal-right-side' unmountOnExit>
      <div className='modal-right-side' onClick={() => handleOpen(!isOpen)}>
        <div className='modal-right-side__window'>
          <ExitButtonWhite handleClick={() => handleOpen(false)} />
          <div className='modal-right-side__content' onClick={e => e.stopPropagation()}>
            {children}
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}
