import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { ClipLoader } from 'react-spinners'
import { css } from '@emotion/react'
import defaultImage from '../../assets/png/defaultImage.png'
import { PhotoHover } from '../photoHover'
import { TextFormat } from '../../commons/TextFromat/TextFormat'
import { AmountBlock } from '../../commons/CountBlock/AmountBlock'
import { PriceBlock } from '../PriceBlock'
import { ButtonQ } from '../../commons/AmountButton'
import { BasketButton } from '../../commons/mui/Button/BasketButton'
import { IProduct } from '../../types/models'
import { Labels } from '../Labels'

interface ProductListItem {
  product: IProduct
  productQ: number
  setProductQ: (id: number, q: number) => void
  addToBasket: ({}: { productId: number; quantity: number }) => unknown
  setProductQuantity: ({}: { id: number; quantity: number }) => unknown
  basketCount: number
  isAddBasketLoading?: boolean
  isLoadingCard?: boolean
  isMsk?: boolean
  basketId?: number
}

const override = css`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const ProductListItem: React.FC<ProductListItem> = ({
  product,
  productQ,
  setProductQ,
  addToBasket,
  setProductQuantity,
  basketCount,
  isAddBasketLoading = false,
  isLoadingCard = false,
  isMsk,
  basketId,
}) => {
  const [hover, setHover] = useState<boolean>(false)
  const [isClicked, setIsClicked] = useState(false)

  useEffect(() => {
    if (isLoadingCard === false) {
      setIsClicked(false)
    }
  }, [isLoadingCard])

  const onBasketClick = () => {
    addToBasket({ productId: product?.id, quantity: productQ })
  }

  const onMinusClick = (quantity: number) => {
    if (basketCount > 0 && basketId) {
      setProductQuantity({ id: basketId, quantity: basketCount - quantity })
    }
  }

  const onPlusClick = (quantity: number) => {
    if (basketCount > 0 && basketId) {
      setProductQuantity({ id: basketId, quantity: basketCount + quantity })
    } else {
      addToBasket({ productId: product?.id, quantity: productQ })
    }
  }

  return (
    <>
      <tr
        onClick={() => {
          setIsClicked(true)
        }}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        key={product.id}
        style={isLoadingCard && isClicked ? { position: 'relative', opacity: '0.5' } : { position: 'relative' }}
      >
        <td>
          <div className='products-list__photo'>
            <Popup
              trigger={() => (
                <div>
                  <img src={product.pictureDetail || defaultImage} alt='' width='35' height='35' />
                </div>
              )}
              on={['hover', 'focus']}
              position='right center'
            >
              <div className='products-list__full-photo'>
                <PhotoHover pictureUrl={product.pictureDetail || defaultImage} />
              </div>
            </Popup>
          </div>
        </td>
        <td>{product.externalId}</td>
        <td>{product?.article}</td>
        <td style={{ paddingTop: 3, paddingBottom: 3 }}>
          <Labels labels={product.labels} />
        </td>
        <td>
          <Link to={product.pageUrl} className='products-list__link'>
            <TextFormat stringNumber={1} text={product.name} isInner />
          </Link>
        </td>
        <td>
          <AmountBlock amount={+product?.location?.amount} unitOfMeasure={product.unitOfMeasure} />
        </td>
        <td>{!isMsk && <AmountBlock amount={+product.stock?.amount} unitOfMeasure={product.unitOfMeasure} />}</td>
        <td>{product.tm}</td>
        <td className='products-list__price'>
          <PriceBlock
            priceDiscount={product.price?.priceDiscount}
            fullPrice={product.price?.price ? product.price.price : 0}
            percentDiscount={product.price?.percentDiscount}
            layout='table'
          />
        </td>
        <td>
          <div className='products-list__quantity-selector' onClick={event => event.stopPropagation()}>
            <ButtonQ
              onChange={setProductQ}
              productId={product?.id}
              value={productQ}
              defaultControlValue={+product?.packages[0]}
              packages={product?.packages}
              unitOfMeasure={product?.unitOfMeasure}
              hover={hover}
              onMinusClick={onMinusClick}
              onPlusClick={onPlusClick}
            />
          </div>
        </td>
        <td>
          <div className='products-list__btn' onClick={event => event.stopPropagation()}>
            <BasketButton
              onClick={onBasketClick}
              count={basketCount}
              unitOfMeasure={product.unitOfMeasure}
              hover={hover}
              isAddBasketLoading={isAddBasketLoading}
            />
          </div>
        </td>
        <ClipLoader color='black' loading={isLoadingCard && isClicked} css={override} size={15} />
      </tr>
    </>
  )
}
