import React, { FC } from 'react'
import newIco from '../../assets/svg/New.svg'
import saleIco from '../../assets/svg/Sale.svg'
import hitIco from '../../assets/svg/Hit.svg'
import departmentIco from '../../assets/svg/Department.svg'
import { ProductLabel } from '../../types/models'
import './Labels.scss'

interface LabelsProps {
  labels: ProductLabel[]
}

export const Labels: FC<LabelsProps> = ({ labels }) => {
  const labelsIcons = labels.map(label => {
    switch (label) {
      case 'new':
        return newIco
      case 'sale':
        return saleIco
      case 'department':
        return departmentIco
      case 'hit':
        return hitIco
      default:
        return null
    }
  })

  return (
    <div className='labels'>
      {labelsIcons.map(label => label && <img className='labels__item' key={label} src={label} alt='ico' />)}
    </div>
  )
}
