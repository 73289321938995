import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { URL } from '../settings/endpoints'
import { IFavoriteRes } from '../types/models/favorite/IFavoriteRes'
import { axiosBaseQuery } from './config'

export const favoriteApi = createApi({
  reducerPath: 'favoriteApi',
  baseQuery: axiosBaseQuery({ baseUrl: URL }),
  tagTypes: ['Favorite'],
  endpoints: build => ({
    getFavorite: build.query<IFavoriteRes, null>({
      query: () => ({
        url: '/favorite',
      }),
      providesTags: result => ['Favorite'],
    }),
    postFavorite: build.mutation<{}, number>({
      query: id => ({
        url: `/favorite/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Favorite'],
    }),
    deleteFavorite: build.mutation<unknown, number>({
      query: id => ({
        url: `/favorite/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Favorite'],
    }),
  }),
})
