import {
  IBreadCrumb,
  IProduct,
  IProductDetails,
  IProductQ,
  IProductRes,
  IProductSelector,
  IProductSorting,
  ISection,
  ProductLabel,
} from '../../types/models'
import { IPagination } from '../../types/commons'

export type IFilters = {
  sectionId: number
  active: 'Y' | 'N'
  subsection: 'Y' | 'N'
}

export interface ProductState {
  filterDirection: IProductSorting | null
  product: IProductDetails
  products: IProduct[]
  sections: ISection[]
  expandedSectionsId: number[]
  pagination: IPagination
  filters: IFilters
  isLoading: boolean
  productQ: IProductQ
  productBreadCrumbs: IBreadCrumb[]
  selectors: IProductSelector[]
  totalRows: number | null
}

export const initialState: ProductState = {
  filterDirection: {
    name: 'По популярности',
    value: 'shows',
    field: 'function',
    order: 'Shows',
    default: true,
    icon: null,
  },
  product: {} as IProductDetails,
  products: [
    {
      id: null,
      sort: null,
      barcode: '',
      name: '',
      code: '',
      sectionId: null,
      erpCategoryId: null,
      picture: '',
      new: false,
      pictureDetail: '',
      morePictures: [''],
      packages: [''],
      pageUrl: '',
      labels: ['' as ProductLabel],
      externalId: null,
      article: '',
      producer: '',
      superSale: '',
      forTender: '',
      previewText: '',
      tm: '',
      unitOfMeasure: '',
      stock: {
        amount: null,
        locationId: null,
        productId: null,
      },
      location: {
        amount: null,
        locationId: null,
        productId: null,
      },
      price: {
        ruleId: null,
        productId: null,
        priceGroupId: null,
        priceBase: null,
        price: null,
        priceDiscount: null,
        percentDiscount: null,
        percentExtra: null,
        currency: '',
      },
    },
  ],
  sections: [] as ISection[],
  expandedSectionsId: [],
  pagination: {
    totalRows: null,
    totalPages: null,
    currentPage: 1,
    perPage: 20,
  },
  filters: {
    sectionId: null,
    active: 'Y',
    subsection: 'Y',
  },
  selectors: [] as IProductSelector[],
  isLoading: false,
  productQ: {
    0: null,
  },
  productBreadCrumbs: [{ id: null, level: null, parentId: null, type: null, name: null, code: null, pageUrl: null }],
  totalRows: null,
}

export enum ActionTypes {
  SET_FILTER_DIRECTION = 'SET_FILTER_DIRECTION',
  GET_SECTIONS = 'GET_SECTIONS',
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_PRODUCT = 'SET_PRODUCT',
  SET_SELECTORS_LIST = 'SET_SELECTORS_LIST',
  SET_PER_PAGE = 'SET_PER_PAGE',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_SECTION_ID = 'SET_SECTION_ID',
  SET_EXPANDED_SECTION_ID = 'SET_EXPANDED_SECTION_ID',
  IS_LOADING = 'IS_LOADING',
  SET_PRODUCT_Q = 'SET_PRODUCT_Q',
  SET_PRODUCT_BREAD_CRUMBS = 'SET_PRODUCT_BREAD_CRUMBS',
  SET_TOTAL_ROWS = 'SET_TOTAL_ROWS',
}

export interface ActionSetBreadCrumbs {
  type: ActionTypes.SET_PRODUCT_BREAD_CRUMBS
  payload: IBreadCrumb[]
}

export interface ActionSetFilterDirection {
  type: ActionTypes.SET_FILTER_DIRECTION
  payload: IProductSorting
}

export interface ActionGetSections {
  type: ActionTypes.GET_SECTIONS
  payload: ISection[]
}

export interface ActionSetProducts {
  type: ActionTypes.SET_PRODUCTS
  payload: IProductRes['result']
}

export interface ActionSetProduct {
  type: ActionTypes.SET_PRODUCT
  payload: IProductDetails
}

export interface ActionSetExpandedSectionId {
  type: ActionTypes.SET_EXPANDED_SECTION_ID
  payload: number[]
}

export interface ActionSetProductQ {
  type: ActionTypes.SET_PRODUCT_Q
  payload: IProductQ
}

export interface ActionSetProductSelectors {
  type: ActionTypes.SET_SELECTORS_LIST
  payload: IProductSelector[]
}

export interface ActionPayloadBoolean {
  type: ActionTypes.IS_LOADING
  payload: boolean
}

export interface ActionPayloadNumber {
  type: ActionTypes.SET_CURRENT_PAGE | ActionTypes.SET_PER_PAGE | ActionTypes.SET_SECTION_ID
  payload: number
}

export interface ActionSetTotalRows {
  type: ActionTypes.SET_TOTAL_ROWS
  payload: number | null
}

export type CatalogAction =
  | ActionSetFilterDirection
  | ActionGetSections
  | ActionSetProducts
  | ActionPayloadBoolean
  | ActionSetProduct
  | ActionPayloadNumber
  | ActionSetExpandedSectionId
  | ActionSetProductQ
  | ActionSetBreadCrumbs
  | ActionSetProductSelectors
  | ActionSetTotalRows

export const productReducer = (state = initialState, action: CatalogAction): ProductState => {
  switch (action.type) {
    case ActionTypes.SET_FILTER_DIRECTION:
      return { ...state, filterDirection: action.payload }
    case ActionTypes.GET_SECTIONS:
      return { ...state, sections: action.payload }
    case ActionTypes.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload.data,
        pagination: action.payload.pagination,
      }
    case ActionTypes.SET_PRODUCT:
      return { ...state, product: action.payload }
    case ActionTypes.SET_SECTION_ID:
      return { ...state, filters: { ...state.filters, sectionId: action.payload } }
    case ActionTypes.SET_EXPANDED_SECTION_ID:
      return { ...state, expandedSectionsId: action.payload }
    case ActionTypes.SET_SELECTORS_LIST:
      return { ...state, selectors: action.payload }
    case ActionTypes.SET_CURRENT_PAGE:
      return { ...state, pagination: { ...state.pagination, currentPage: action.payload } }
    case ActionTypes.SET_PER_PAGE:
      return { ...state, pagination: { ...state.pagination, perPage: action.payload } }
    case ActionTypes.IS_LOADING:
      return { ...state, isLoading: action.payload }
    case ActionTypes.SET_PRODUCT_Q:
      return { ...state, productQ: { ...state.productQ, ...action.payload } }
    case ActionTypes.SET_PRODUCT_BREAD_CRUMBS:
      return { ...state, productBreadCrumbs: action.payload }
    case ActionTypes.SET_TOTAL_ROWS:
      return { ...state, totalRows: action.payload }
    default:
      return state
  }
}
