import React, { useEffect, useState } from 'react'
import './FilterDirection.scss'
import { shallowEqual } from 'react-redux'
import upArrow from '../../assets/svg/ico/filterDirection/upArrow.svg'
import downArrow from '../../assets/svg/ico/filterDirection/downArrow.svg'
import { useActions } from '../../hooks/useActions'
import { Select } from '../../commons/Select/Select'
import ProductService from '../../api/ProductService'
import { IProductSorting } from '../../types/models'
import { useTypedSelector } from '../../hooks/useTypedSelector'

export const FilterDirection = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { setFilterDirection } = useActions()
  const [filters, setFilters] = useState<IProductSorting[]>([] as IProductSorting[])
  const filterDirection = useTypedSelector(state => state.productReducer.filterDirection, shallowEqual)

  useEffect(() => {
    const fetchProductStrong = async () => {
      const { data } = await ProductService.getProductSorting()
      setFilters(data.result)
      const defaultSort = data.result.find(item => item.default)
      if (defaultSort) {
        setFilterDirection(defaultSort)
      }
    }
    fetchProductStrong()
  }, [])

  return (
    <>
      <Select
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        trigger={
          <button
            className='filter-direction__btn'
            onClick={() => {
              setIsOpen(true)
            }}
          >
            {filterDirection?.name || 'Сортировка'}
          </button>
        }
      >
        <div className='filter-direction'>
          {filters.map((filter, index) => (
            <div
              className='dropdown__item'
              onClick={() => {
                setFilterDirection(filter)
                setIsOpen(false)
              }}
              key={index}
            >
              <span>{filter.name}</span>
              {filter.value !== 'default' && filter.icon && (
                <img src={filter.icon === 'upArrow' ? upArrow : downArrow} alt='ico' />
              )}
            </div>
          ))}
        </div>
      </Select>
    </>
  )
}
