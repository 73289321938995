import React, { useEffect, useState } from 'react'
import './Forgot.scss'
import { Field, Formik, FormikHelpers } from 'formik'
import { Box } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import loader from '../../assets/svg/loaders/rectangleBlackLoader.svg'
import { Axios } from '../../axios'
import { FORGOT_CONFIRM } from '../../settings/endpoints'
import { useCustomSnackbar } from '../../hooks/useSnackbar'

interface Values {
  password: string
  confirmation: string
}

export const Forgot = (): JSX.Element => {
  document.title = 'Восстановление пароля'
  const { successSnack, errorSnack } = useCustomSnackbar()
  const history = useHistory()
  const { search } = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [checkword, setCheckword] = useState('')
  const [login, setLogin] = useState('')

  useEffect(() => {
    const queryArray = search.split('&')
    if (queryArray.length === 2) {
      setCheckword(queryArray[0].split('=')[1])
      setLogin(queryArray[1].split('=')[1])
    } else {
      history.push('/login')
    }
  }, [])

  const initialValues: Values = {
    password: '',
    confirmation: '',
  }

  const handleSubmit = async (values: Values, { resetForm }: FormikHelpers<Values>) => {
    if (!checkword || !login) {
      return
    }
    setIsLoading(true)
    try {
      const res = await Axios.post(FORGOT_CONFIRM(checkword, login), {
        password: values.password,
        confirmation: values.confirmation,
      })

      if (res.data.result) {
        resetForm()
        successSnack('Пароль изменен')
        history.push('/login')
      }
    } catch (e: unknown) {
      if (axios.isAxiosError(e)) {
        const errorMessage = e?.response?.data?.message || 'Произошла ошибка'
        errorSnack(errorMessage)
        console.error(e?.response)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const validate = values => {
    const errors = {}

    if (!values.password) {
      errors['password'] = 'Введите пароль'
    }
    if (values.password && values.password.length < 10) {
      errors['password'] =
        'Пароль должен быть не менее 10 символов длинной, содержать символы верхнего и нижнего регистра (A-Z, a-z) и знаки пунктуации'
    }
    if (!values.confirmation) {
      errors['confirmation'] = 'Введите подтверждение пароля'
    }

    if (values.confirmation !== values.password) {
      errors['confirmation'] = 'Пароли должны совпадать'
    }
    return errors
  }

  return (
    <div className='forgot-page'>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
        {({ handleSubmit, isSubmitting, errors, touched }) => (
          <form onSubmit={handleSubmit} className='forgot-page__form'>
            <Box sx={{ fontSize: 16, mb: 2, textAlign: 'center' }}>Придумайте новый пароль</Box>
            <Box>
              <Box component='label' htmlFor='password' sx={{ mb: 1, display: 'inline-block' }}>
                Пароль
              </Box>
              <Field
                id='password'
                className={touched.password && errors.password ? 'input input_error' : 'input'}
                type='password'
                name='password'
              />
              {touched.password && errors.password && (
                <Box component='span' sx={{ color: '#f23b2f' }}>
                  {errors.password}
                </Box>
              )}
            </Box>
            <Box>
              <Box component='label' htmlFor='confirmation' sx={{ mb: 1, display: 'inline-block' }}>
                Подтвердите пароль
              </Box>
              <Field
                id='confirmation'
                className={touched.confirmation && errors.confirmation ? 'input input_error' : 'input'}
                type='password'
                name='confirmation'
              />
              {touched.confirmation && errors.confirmation && (
                <Box component='span' sx={{ color: '#f23b2f' }}>
                  {errors.confirmation}
                </Box>
              )}
            </Box>
            <div className='submit'>
              <button type='submit' className='submit__btn' disabled={isSubmitting}>
                {isLoading ? <img src={loader} alt='' height='30' width='30' /> : 'Отправить'}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}
