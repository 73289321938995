import { AxiosResponse } from 'axios'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { Axios } from '../axios'
import {
  IProductBreadCrumbsRes,
  IProductDetailsRes,
  IProductLocationRes,
  IProductRes,
  IProductSelectorRes,
  IProductSortingRes,
  IProductStockRes,
} from '../types/models'
import { GET_PRODUCT_BREADCRUMBS, GET_PRODUCT_SELECTORS, GET_PRODUCT_SORTING, URL } from '../settings/endpoints'
import { axiosBaseQuery } from './config'

export default class ProductService {
  static async getProductBreadCrumbs(id: number): Promise<AxiosResponse<IProductBreadCrumbsRes>> {
    return Axios.get<IProductBreadCrumbsRes>(GET_PRODUCT_BREADCRUMBS(id))
  }

  static async getProductSorting(): Promise<AxiosResponse<IProductSortingRes>> {
    return Axios.get<IProductSortingRes>(GET_PRODUCT_SORTING)
  }

  static async getProductSelectors(): Promise<AxiosResponse<IProductSelectorRes>> {
    return Axios.get<IProductSelectorRes>(GET_PRODUCT_SELECTORS)
  }
}

interface IProductQueryData {
  sectionId: number
  currentPage: number
  perPage: number
  active: null
  subsection: null
  selector: string[]
  sort: string
  filter: any
}

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: axiosBaseQuery({ baseUrl: URL }),
  endpoints: build => ({
    getProducts: build.query<IProductRes, IProductQueryData>({
      query: data => ({
        url: '/product',
        params: data,
      }),
    }),
    getProductById: build.query<IProductDetailsRes, number>({
      query: id => ({
        url: `/product/${id}`,
      }),
    }),
    getProductLocation: build.query<IProductLocationRes, number[]>({
      query: ids => {
        return {
          url: '/product/location',
          params: {
            id: ids,
          },
        }
      },
    }),
    getProductStock: build.query<IProductStockRes, number[]>({
      query: ids => {
        return {
          url: '/product/stock',
          params: {
            id: ids,
          },
        }
      },
    }),
  }),
})
