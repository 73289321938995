import React, { useState } from 'react'
import './SectionsHidden.scss'
import { CSSTransition } from 'react-transition-group'
import Box from '@mui/material/Box'
import ico1 from '../../assets/svg/ico/sideBarIco/image 1.svg'
import Frame_15 from '../../assets/svg/Frame_15.svg'
import Frame_22 from '../../assets/svg/Frame_22.svg'
import Frame_21 from '../../assets/svg/Frame_21.svg'
import Frame_23 from '../../assets/svg/Frame_23.svg'
import Frame_16 from '../../assets/svg/Frame_16.svg'

import { SectionsFull } from '../SectionsFull'
import { ISection } from '../../types/models'
import { ExitIco } from '../../commons/Icons'

export interface ISectionHidden {
  sections: ISection[]
  expandSectionId: number[]
  expandSection: (id: number) => void
  isSingleLevel?: boolean
}

const getImgSection = (name: string): string => {
  // if (name === 'Учеба и творчество') {
  //   return Frame_15
  // }
  // if (name === 'Подарки и сувениры') {
  //   return Frame_22
  // }
  // if (name === 'Техника и расходные материалы') {
  //   return Frame_21
  // }
  // if (name === 'Хозтовары и продукты') {
  //   return Frame_23
  // }
  // if (name === 'Офисные товары') {
  //   return Frame_16
  // }
  return ico1
}

export const SectionsHidden: React.FC<ISectionHidden> = ({
  sections,
  expandSectionId,
  expandSection,
  isSingleLevel,
}) => {
  const [singleSectionId, setSingleSectionId] = useState(null)
  const [showMenu, setShowMenu] = useState(false)

  const hideSection = () => {
    setShowMenu(false)
  }

  return (
    <div className='sections-hidden' onMouseLeave={hideSection}>
      <div className='sections-hidden__container'>
        {sections.map(section => (
          <div
            className='sections-hidden__item'
            onMouseOver={() => {
              setShowMenu(true)
              setSingleSectionId(section.id)
            }}
            key={section.id}
          >
            <img
              src={section.ico || getImgSection(section.name)}
              onError={error => {
                error.currentTarget.src = getImgSection(section.name)
              }}
              alt='ico'
            />
          </div>
        ))}
      </div>

      <CSSTransition in={showMenu} timeout={200} classNames='catalog-nav' unmountOnExit>
        <div className='catalog-nav-container'>
          <Box
            position={'absolute'}
            p='15px'
            width='44px'
            height='44px'
            role='button'
            onClick={hideSection}
            sx={{ cursor: 'pointer', right: '1.8rem', top: 0, 'svg, g': {fill: 'black !important'} }}
          >
            <ExitIco />
          </Box>
          <SectionsFull
            singleSectionId={singleSectionId}
            sections={sections}
            singleSection
            hideSection={hideSection}
            expandSection={expandSection}
            expandSectionId={expandSectionId}
            isSingleLevel={isSingleLevel}
          />
        </div>
      </CSSTransition>
    </div>
  )
}
