import React from 'react'
import { Box, Typography } from '@mui/material'
import { DefaultButton } from '../../commons/mui/Button/DefaultButton'

interface DeleteModal {
  onSubmit: () => void
  onCancel: () => void
}

export const CancelOrderModal: React.FC<DeleteModal> = ({ onSubmit, onCancel }) => {
  return (
    <Box width='310px' p='30px 20px 20px 20px'>
      <Typography variant='h4' textAlign='center'>
        Вы действительно хотите отменить заказ?
      </Typography>
      <Box mt='31px' display='flex' columnGap='10px'>
        <DefaultButton onClick={() => onSubmit()} color='secondary' fullWidth>
          Да
        </DefaultButton>
        <DefaultButton onClick={() => onCancel()} color='primary' fullWidth>
          Отмена
        </DefaultButton>
      </Box>
    </Box>
  )
}
