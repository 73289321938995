import React, { ChangeEvent, useEffect, useState } from 'react'
import './orderForm.scss'
import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import DatePicker from '@mui/lab/DatePicker'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { StyledSwitch } from '../../commons/mui/Styled'
import { orderApi } from '../../api/OrderService'
import { DefaultButton } from '../../commons/mui/Button/DefaultButton'
import { DateInputIcon } from '../../commons/Icons'
import { AddressAutocomplete } from '../../components/addressAutocomplete'
import { IMakeOrderForm, IMakeOrderOption, TBasketList } from '../../types/models'
import { useActions } from '../../hooks/useActions'
import Loader from '../../commons/Loader/Loader'
import { useGaTracker } from '../../hooks/useGaTracker'
import { useCustomSnackbar } from '../../hooks/useSnackbar'
import { formatNumber } from '../../utils/formatNumber'
import { IErrorResponse } from '../../types/commons'
import { optionFormat } from '../../utils/optionFormat'
import OrderSurvey from '../orderSurvey/OrderSurvey'

interface IOrderForm {
  productUniqueCount: number
  productCount: number
  totalPrice: number
  formData: IMakeOrderForm[]
  setOpenModal: (isOpen: boolean) => void
  refetchBasket?: any | null
  basketList: TBasketList
}

export const OrderForm: React.FC<IOrderForm> = ({
  formData,
  setOpenModal,
  productUniqueCount,
  productCount,
  totalPrice,
  refetchBasket = null,
  basketList,
}) => {
  const history = useHistory()
  const { fetchBasket } = useActions()
  const [date, setDate] = useState<Date | null>(new Date())
  const [address, setAddress] = useState<IMakeOrderOption>({
    value: '',
    text: '',
  })
  const [pickup, setPickup] = useState<boolean>(false)
  const [addressError, setAddressError] = useState<boolean>(false)
  const { ReactGA } = useGaTracker()

  const [addOrder, result] = orderApi.usePostOrderMutation()
  const { control, register, handleSubmit, setValue } = useForm()

  const { errorSnack } = useCustomSnackbar()

  const gaPurchase = (orderId: number) => {
    let revenue = 0
    basketList.forEach(item => {
      revenue += item.price
      ReactGA.plugin.require('ec')
      ReactGA.plugin.execute('ec', 'addProduct', {
        id: item.id,
        name: item.name,
        brand: item.tm,
        quantity: item.quantity,
        price: item.price,
      })
    })

    ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
      id: orderId,
      affiliation: 'Informat',
      revenue,
    })
  }

  const onSubmit = async data => {
    if (!pickup && !address.text) {
      setAddressError(true)
      return
    }

    const dataFormat = Object.fromEntries(
      Object.entries<any>(data).map(
        ([key, v]) => (v === true && [key, 'Y']) || (v === false && [key, 'N']) || [key, v],
      ),
    )

    for (let f of formData) {
      if (f.required && !(f.name in dataFormat)) {
        dataFormat[f.name] = f.value
      }
    }

    if (address.value.toString()) {
      dataFormat.ADDR_ID = address.value.toString()
    }

    if (address.text) {
      dataFormat.ADDRESS = address.text
    }

    await addOrder(dataFormat)
  }

  const onAddressChange = (newAddress: IMakeOrderOption) => {
    setAddressError(false)
    setAddress(newAddress)
  }

  const pickupChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setAddressError(false)
    const { checked } = event.target
    setPickup(checked)
    setAddress({
      value: '',
      text: 'Самовывоз',
    })
  }

  const menuItemsBuild = (options: IMakeOrderOption[]) => {
    return options.map(option => (
      <MenuItem sx={{ maxWidth: 370, whiteSpace: 'initial' }} key={option.value} value={option.value}>
        {optionFormat(option.text)}
      </MenuItem>
    ))
  }

  useEffect(() => {
    if (result.isSuccess) {
      gaPurchase(result.data.result)
      setOpenModal(false)
      fetchBasket()
      if (refetchBasket) {
        refetchBasket()
      }
      history.push('/orders', { openSurvey: result.data.result.toString() })
    } else if (result.isError) {
      const errorMessage = (result.error as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', result.error)
    }
  }, [result])

  return (
    <>
      <Loader isLoading={result.isLoading} />
      <div className='order-form'>
        <div className='order-form__title'>Подтверждение заказа</div>
        <div className='order-form__table'>
          <div className='left-col'>
            <div>Кол-во уникальных позиций:</div>
            <div>Кол-во товаров общее: </div>
            {/*<div>Общий вес заказа:</div>*/}
            <div>Общая стоимость заказов:</div>
          </div>
          <div className='right-col'>
            <div>{productUniqueCount}</div>
            <div>{productCount}</div>
            {/*<div>??кг</div>*/}
            <div>{formatNumber(totalPrice)} ₽</div>
          </div>
        </div>
        <form className='form-block' autoComplete='no' onSubmit={handleSubmit(onSubmit)}>
          {formData
            ?.filter(i => i.hidden === false)
            .map(item => (
              <Box mb='20px' key={item.id}>
                {(item.type === 'text' && (
                  <FormControl fullWidth>
                    <InputLabel shrink>{item.description}</InputLabel>
                    <TextField
                      placeholder={item.placeholder || ''}
                      type={item.type}
                      autoComplete='off'
                      name={item.name}
                      {...register(item.name)}
                      required={item.required}
                    />
                  </FormControl>
                )) ||
                  (item.type === 'select' &&
                    (item.name === 'ADDR_ID' ? (
                      <>
                        <InputLabel shrink focused={false}>
                          {item.description}
                        </InputLabel>
                        <AddressAutocomplete
                          error={addressError}
                          disabled={pickup}
                          options={item.options || []}
                          selectedAddress={address}
                          isPickup={pickup}
                          menuItemsBuild={menuItemsBuild}
                          onAddressChange={onAddressChange}
                          placeholder={item.placeholder}
                        />
                        <FormControlLabel
                          sx={{ marginTop: 1 }}
                          control={<StyledSwitch name={item.name} checked={pickup} onChange={pickupChangeHandler} />}
                          label='Самовывоз'
                        />
                      </>
                    ) : (
                      <FormControl fullWidth>
                        <InputLabel shrink focused={false}>
                          {item.description}
                        </InputLabel>
                        <Controller
                          control={control}
                          name={item.name}
                          render={({ field: { onChange, value: controllerValue } }) => (
                            <>
                              <Select
                                value={controllerValue}
                                onChange={event => {
                                  onChange(event.target.value)
                                }}
                                displayEmpty
                                renderValue={() => {
                                  return controllerValue ? (
                                    <span>
                                      {optionFormat(item.options.find(i => i.value === controllerValue)?.text || '')}
                                    </span>
                                  ) : (
                                    <div>{item.placeholder ? item.placeholder : 'Выберите из списка'}</div>
                                  )
                                }}
                              >
                                {menuItemsBuild(item.options || [])}
                              </Select>
                            </>
                          )}
                        />
                      </FormControl>
                    ))) ||
                  (item.type === 'date' && (
                    <FormControl fullWidth>
                      <InputLabel shrink>{item.description}</InputLabel>
                      <DatePicker
                        label='Выберите день'
                        openTo='year'
                        views={['day']}
                        value={date}
                        mask='__.__.____'
                        {...register(item.name)}
                        onChange={newValue => {
                          setDate(newValue)
                        }}
                        components={{
                          OpenPickerIcon: () => <DateInputIcon />,
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={false}
                            inputProps={{ ...params.inputProps, placeholder: 'Выберите день' }}
                            autoComplete='no'
                            required={item.required}
                          />
                        )}
                      />
                    </FormControl>
                  )) ||
                  (item.type === 'checkbox' && (
                    <FormControlLabel
                      control={
                        <StyledSwitch
                          name={item.name}
                          onChange={e => setValue(item.name, e.target.checked ? 'Y' : 'N')}
                          {...register(item.name)}
                          defaultValue='N'
                        />
                      }
                      label={item.description}
                    />
                  ))}
              </Box>
            ))}
          <div className='button-block'>
            <DefaultButton type='submit' name='N' onClick={() => setValue('status', 'N')}>
              Подтвердить заказ
            </DefaultButton>
            <DefaultButton type='submit' name='DR' color='secondary' onClick={() => setValue('status', 'DR')}>
              Сформировать черновик
            </DefaultButton>
          </div>

          <div className='description'>
            Заказ "Черновик" будет сформирован, но не отправлен в нашу систему приема заказов.
          </div>
        </form>
      </div>
    </>
  )
}
