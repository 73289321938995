import { ReactNode } from 'react'
import { shallowEqual } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'

interface PrivateRouteProps extends RouteProps {
  children: ReactNode
}

export const PrivateRoute = ({ children, ...otherProps }: PrivateRouteProps): JSX.Element => {
  const authorization = useTypedSelector(state => state.authReducer.authorization, shallowEqual)

  return (
    <Route
      {...otherProps}
      render={({ location }) =>
        authorization === '' && location.pathname !== '/forgot/confirm' ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  )
}
