import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { clearTokens, getTokens, setAccessToken } from '../utils/tokens'
import { LOGIN, LOGOUT } from '../settings/endpoints'

export const AXIOS_TIMEOUT = 20000

export const Axios = axios.create({
  timeout: AXIOS_TIMEOUT,
  withCredentials: true,
})
export const cancelSource = axios.CancelToken.source()

let refreshTokenRequest = null

export const logout = async () => {
  clearTokens()
  await Axios.get(LOGOUT)
  window.location.pathname = '/login'
}

const requestValidAccessToken = async () => {
  let { accessToken } = getTokens()
  if (!accessToken) {
    window.location.pathname = '/login'
    return
  }
  const decode = jwtDecode<{ name: string; exp: number }>(accessToken)
  if (!decode?.exp || decode?.exp <= Math.floor(Date.now() * 0.001)) {
    try {
      if (!refreshTokenRequest) {
        refreshTokenRequest = Axios.get<{ result: { Authorization: string } }>(LOGIN, { withCredentials: true })
      }
      const res = await refreshTokenRequest
      refreshTokenRequest = null
      setAccessToken(res.data.result.Authorization)
      return res.data.result.Authorization
    } catch (e: unknown) {
      await logout()
    }
  }

  return accessToken
}

Axios.interceptors.request.use(
  async config => {
    if (config.url.indexOf('/login') !== -1 || config.url.indexOf('/forgot') !== -1) {
      return config
    }

    const accessToken = await requestValidAccessToken()
    config.headers = {
      ...config.headers,
      Authorization: accessToken,
    }
    config.cancelToken = cancelSource.token
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

Axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      clearTokens()
      window.location.pathname = '/login'
    }

    if (error.code === 'ECONNABORTED') {
      cancelSource.cancel('timeout')
      let isReload = confirm('Превышен лимит ожидания ответа сервера. Перезагрузить страницу?')
      if (isReload) {
        window.location.reload()
      }
    }
    return Promise.reject(error)
  },
)
