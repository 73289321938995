import React, { useState } from 'react'
import './OrderProductsList.scss'
import { TextFormat } from '../../commons/TextFromat/TextFormat'
import { IProduct, IProductLocation, IStock, TOrderList } from '../../types/models'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { isMsk } from '../../utils/isMsk'
import { formatNumber } from '../../utils/formatNumber'

interface OrderProductsList {
  handleOpen: () => void
  orderList: TOrderList
  orderProducts: { [id: number]: IProduct }
  productLocation: IProductLocation
  productStock: IStock
}

export const OrderProductsList: React.FC<OrderProductsList> = ({
  handleOpen,
  orderList,
  orderProducts,
  productLocation,
  productStock,
}) => {
  const { city } = useTypedSelector(state => state.authReducer.user.location)
  const [isPhotoHover, setIsPhotoHover] = useState(false)

  return (
    <table className='order-list'>
      <thead>
        <tr>
          <th>Фото</th>
          <th>Код</th>
          <th>Артикул</th>
          <th>Название</th>
          <th>Бренд</th>
          <th>{isMsk(city) ? 'В наличии' : 'Ваш город'}</th>
          <th>{!isMsk(city) && 'Склад (Мск)'}</th>
          <th>
            <div>Кол-во</div>
          </th>
          <th>Цена за шт.</th>
          <th>Цена Итого</th>
        </tr>
      </thead>
      <tbody>
        {orderList?.map(product => (
          <tr key={product.id} onClick={handleOpen}>
            <td>
              <div
                onMouseEnter={() => setIsPhotoHover(true)}
                onMouseLeave={() => setIsPhotoHover(false)}
                className='photo'
              >
                <img src={orderProducts[product.productId].pictureDetail} alt='img' width='35' height='35' />
                {/*<CSSTransition in={isPhotoHover} timeout={200} classNames='show-el' unmountOnExit>*/}
                {/*  <div className='photo__full-photo'>*/}
                {/*    <PhotoHover />*/}
                {/*  </div>*/}
                {/*</CSSTransition>*/}
              </div>
            </td>
            <td>{product.externalId}</td>
            <td className='article'>{orderProducts[product.productId].article}</td>
            <td>
              <TextFormat stringNumber={1} text={product.name} />
            </td>
            <td className='order-list__tm'>
              <span>{orderProducts[product.productId].tm}</span>
            </td>
            <td className='order-list__location'>
              <span>{productLocation && productLocation[product.productId]?.amount} (шт)</span>
            </td>
            <td>
              {!isMsk(city) && (
                <div className='order-list__stock'>
                  {productStock && productStock[product.productId]?.amount} (
                  {orderProducts[product.productId].unitOfMeasure})
                </div>
              )}
            </td>
            <td className='order-list__quantity'>{product.quantity}</td>
            <td className='order-list__price'>{formatNumber(product.price)}</td>
            <td className='order-list__total-price'>{formatNumber(product.totalPrice)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
