import { createTheme } from '@mui/material'
import { CSSProperties } from 'react'
import { DownArrowSelect } from '../commons/Icons'

declare module '@mui/material/styles' {
  interface Palette {
    yellow?: CSSProperties['color']
    borderYellow?: CSSProperties['color']
    borderGray?: CSSProperties['color']
    bgGray?: CSSProperties['color']
  }

  interface PaletteOptions {
    yellow?: CSSProperties['color']
    borderYellow?: CSSProperties['color']
    borderGray?: CSSProperties['color']
    bgGray?: CSSProperties['color']
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeightBold: 700,
    fontWeightRegular: 600,
    fontWeightMedium: 500,
    fontWeightLight: 400,
    fontSize: 14,
    h1: {
      fontSize: 24,
      fontWeight: 700,
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
    },
    h3: {
      fontSize: 18,
      fontWeight: 600,
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  palette: {
    yellow: '#FEDB43',
    bgGray: '#F7F7F7',
    borderYellow: '#F2CB3F',
    borderGray: '#ECECEC',
    text: {
      primary: '#000',
      secondary: '#A5A5A5',
    },
    primary: {
      main: '#F2CB3F',
    },
    secondary: {
      main: '#E5E5E5',
      light: '#E5E5E5',
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          height: '40px ',
          '.MuiOutlinedInput-root': {
            paddingRight: 0,
          },
        },
        input: {
          padding: '0 !important',
        },
        inputRoot: {
          paddingLeft: '15px',
          paddingRight: 0,
        },
        endAdornment: {
          paddingRight: '5px',
        },
      },
      defaultProps: {
        clearIcon: null,
        popupIcon: DownArrowSelect(),
        noOptionsText: 'Не найдено',
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'rgba(0, 0, 0, 0.2)',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          color: '#000',
          marginBottom: '10px',
          position: 'static',
          '-webkit-transform': 'unset',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          height: '40px',
          fontSize: '14px',

          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E5E5E5',
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E5E5E5',
          },
        },
      },
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
        autoComplete: 'no',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '40px',
        },
        input: {
          padding: '0 15px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 'unset',
          marginLeft: 'unset',
          columnGap: '10px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          height: '40px',
          padding: '0 5px',
          fontSize: '14px',

          '& fieldset': {
            borderColor: 'black',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E5E5E5',
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E5E5E5',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '40px',
          textTransform: 'none',
          fontSize: 14,
          fontWeight: 600,
          padding: '10px 15px',
          lineHeight: '18px',
          color: 'black',
          borderRadius: '4px',
          minWidth: 'unset',
          opacity: '1 !important',
          border: '1px solid #E5E5E5',
        },
        containedPrimary: {
          background: '#FEDB43',
          borderColor: '#F2CB3F',
          '&:hover': {
            backgroundColor: '#FEDB43',
          },
        },
        containedSecondary: {
          background: '#FFF',
          borderColor: '1px solid #E5E5E5',
          '&:hover': {
            background: '#FFF',
          },
        },
        outlinedPrimary: {
          background: '#FEDB43',
          borderColor: '#F2CB3F',
          '&:hover': {
            backgroundColor: '#FEDB43',
          },
        },
        outlinedSecondary: {
          background: '#FFF',
          borderColor: '1px solid #E5E5E5',
          '&:hover': {
            background: '#FFF',
          },
        },
      },
    },
  },
})
