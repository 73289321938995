import { useEffect, useState } from 'react'
import { basketApi } from '../api/BasketService'
import { orderApi } from '../api/OrderService'
import { Axios } from '../axios'
import { GET_PRODUCT_ANALOGS, GET_PRODUCT_IDS, POST_PRODUCT_LOCATION, POST_PRODUCT_STOCK } from '../settings/endpoints'
import { IErrorResponse } from '../types/commons'
import {
  IAddBasketRes,
  IBasket,
  IBasketSortVariant,
  IProduct,
  IProductAnalogs,
  IProductAnalogsRes,
  IProductIds,
  IProductIdsRes,
  IProductLocation,
  IProductLocationRes,
  IProductStockRes,
  IStock,
} from '../types/models'
import { useRecalculate } from './useRecalculate'
import { useCustomSnackbar } from './useSnackbar'

export const useBasket = () => {
  const { errorSnack, infoSnack } = useCustomSnackbar()

  const [currentSort, setCurrentSort] = useState<IBasketSortVariant>({
    value: 'id_asc',
    field: 'id',
    order: 'ASC',
  })
  const [isProductIdsFetching, setIsProductIdsFetching] = useState(false)
  const [products, setProducts] = useState<IProductIds>({} as IProductIds)
  const [isLocationFetching, setIsLocationFetching] = useState(false)
  const [productLocation, setProductLocation] = useState<IProductLocation>({})
  const [productStock, setProductStock] = useState<IStock>({})
  const [isStockFetching, setIsStockFetching] = useState(false)
  const [isAnalogsFetching, setIsAnalogsFetching] = useState(false)
  const [productAnalogs, setProductAnalogs] = useState<IProductAnalogs | null>(null)
  const [currentWarningProduct, setCurrentWarningProduct] = useState<IProduct>(null)
  const [warningProductId, setWarningProductId] = useState<number | null>(null)
  const [isEmpty, setIsEmpty] = useState(true)

  const {
    data: orderData,
    error: getMakeOrderError,
    isError: isGetMakeOrderError,
  } = orderApi.useGetMakeOrderFormQuery(null)

  const {
    data: basketData,
    isFetching: isBasketFetching,
    error: basketDataError,
    isError: isBasketDataError,
    refetch,
  } = basketApi.useGetBasketQuery(currentSort.value)
  const [trigger, result] = basketApi.useLazyGetBasketQuery()

  const [deleteProduct, { isLoading: isDeleteLoading, error: errorDeleteProduct, isError: isErrorDeleteProduct }] =
    basketApi.useDeleteProductBasketMutation()

  const [addToBasket, { isLoading: isAddBasketLoading, error: addToBasketError, isError: isAddToBasketError }] =
    basketApi.usePostProductBasketMutation()

  const [
    setProductQuantity,
    { isLoading: isSetProductQuantityLoading, error: setProductQuantityError, isError: isSetProductQuantityError },
  ] = basketApi.usePutProductBasketMutation()

  const [refetchBasket, { error: refetchBasketError, isError: isRefetchBasketError }] =
    basketApi.useRefetchBasketMutation()

  const basket =
    basketData?.result ??
    ({
      list: [],
      product: {},
    } as IBasket)

  const { recalculate, isRecalculateLoading } = useRecalculate()

  const productWithLocation = Object.values(products ?? []).map((item: IProduct) => ({
    ...item,
    location: productLocation[item.id],
    stock: productStock[item.id],
  }))
  const warningProducts = productWithLocation.filter(
    item => (!item.location || item.location?.amount === 0) && (!item.stock || item.stock?.amount === 0),
  )
  const isWarning = warningProducts.length > 0

  const isLoading =
    isBasketFetching ||
    isDeleteLoading ||
    isLocationFetching ||
    isProductIdsFetching ||
    isStockFetching ||
    isAddBasketLoading ||
    isSetProductQuantityLoading ||
    isRecalculateLoading ||
    isAnalogsFetching

  const setCurrentSortHandler = (payload: IBasketSortVariant) => {
    setCurrentSort({ ...payload })
  }

  const openAnalogsModalHandler = async (productId: number) => {
    setWarningProductId(productId)
    await fetchProductAnalogs(productId)
    setCurrentWarningProduct(warningProducts.filter(product => product.id === productId)[0] || null)
  }

  const closeAnalogsModalHandler = () => {
    setProductAnalogs(null)
    setWarningProductId(null)
    setCurrentWarningProduct(null)
  }

  const productReplacementHandler = async (newProductId: number) => {
    if (warningProductId) {
      const findDeleteProduct = basket?.list?.find(item => item.productId === warningProductId)

      if (findDeleteProduct) {
        const addResponse = await addToBasket({ productId: newProductId, quantity: findDeleteProduct.quantity || 1 })
        if ((addResponse as { data: IAddBasketRes }).data?.result) {
          await deleteProduct(findDeleteProduct.id || null)
          setCurrentWarningProduct(null)
          setWarningProductId(null)
          await reloadProducts()
        }
      }
    }
  }

  const reloadProducts = async () => {
    const productIds = basket.list.map(list => list.productId)
    if (productIds.length !== 0) {
      setIsProductIdsFetching(true)
      Axios.post<IProductIdsRes>(GET_PRODUCT_IDS, {
        id: productIds,
      }).then(res => {
        setProducts({ ...res.data.result })
        setIsProductIdsFetching(false)
      })
    }
    if (basket.list?.length) {
      setIsLocationFetching(true)
      Axios.post<IProductLocationRes>(POST_PRODUCT_LOCATION, {
        id: basket.list.map(el => el.productId),
      }).then(res => {
        setProductLocation({ ...res.data.result })
        setIsLocationFetching(false)
      })

      setIsStockFetching(true)
      Axios.post<IProductStockRes>(POST_PRODUCT_STOCK, {
        id: basket.list.map(el => el.productId),
      }).then(res => {
        setProductStock({ ...res.data.result })
        setIsStockFetching(false)
      })
    }
  }

  const fetchProductAnalogs = async (productId: number, page = 1) => {
    setIsAnalogsFetching(true)
    try {
      const {
        data: { result },
      } = await Axios.get<IProductAnalogsRes>(GET_PRODUCT_ANALOGS(productId), {
        params: {
          currentPage: page,
        },
      })
      if (result) {
        setProductAnalogs(result)
        setCurrentWarningProduct(warningProducts.filter(product => product.id === warningProductId)[0] || null)
      } else {
        infoSnack('Аналогов для данного товара нет')
      }
    } catch (e) {
      errorSnack(e.message)
    } finally {
      setIsAnalogsFetching(false)
    }
  }

  useEffect(() => {
    if (isAddToBasketError) {
      const errorMessage = (addToBasketError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', addToBasketError)
    }
  }, [addToBasketError, errorSnack, isAddToBasketError])

  useEffect(() => {
    if (isSetProductQuantityError) {
      const errorMessage = (setProductQuantityError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', setProductQuantityError)
    }
  }, [errorSnack, isSetProductQuantityError, setProductQuantityError])

  useEffect(() => {
    if (isErrorDeleteProduct) {
      const errorMessage = (errorDeleteProduct as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', errorDeleteProduct)
    }
  }, [errorDeleteProduct, errorSnack, isErrorDeleteProduct])

  useEffect(() => {
    if (isGetMakeOrderError) {
      const errorMessage = (getMakeOrderError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', getMakeOrderError)
    }
  }, [errorSnack, getMakeOrderError, isGetMakeOrderError])

  useEffect(() => {
    if (isBasketDataError) {
      const errorMessage = (basketDataError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', basketDataError)
    }
  }, [basketDataError, errorSnack, isBasketDataError])

  useEffect(() => {
    if (isRefetchBasketError) {
      const errorMessage = (refetchBasketError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', refetchBasketError)
    }
  }, [errorSnack, isRefetchBasketError, refetchBasketError])

  useEffect(() => {
    setIsEmpty(basket?.list?.length === 0)
  }, [basket?.list?.length])

  return {
    basket,
    products,
    productLocation,
    productStock,
    productAnalogs,
    deleteProduct,
    addToBasket,
    setProductQuantity,
    currentWarningProduct,
    recalculate,
    isLoading,
    warningProducts,
    isWarning,
    refetchBasket,
    currentSort,
    warningProductId,
    orderData,
    isEmpty,
    closeAnalogsModalHandler,
    openAnalogsModalHandler,
    setCurrentSortHandler,
    reloadProducts,
    fetchProductAnalogs,
    productReplacementHandler,
    refetch,
    trigger,
    result,
  }
}
