import React, { useCallback, useEffect, useState } from 'react'
import './Order.scss'
import { useHistory, useParams } from 'react-router-dom'
import { BackgroundLayout } from '../../layouts/backgroundLayout'
import { OrderToolbar } from '../../containers/orderToolbar'
import { OrderDetails } from '../../containers/orderDetails'
import { OrderProductsList } from '../../containers/OrderProductsList'
import { orderApi } from '../../api/OrderService'
import Loader from '../../commons/Loader/Loader'
import { Axios } from '../../axios'
import { POST_PRODUCT_LOCATION, POST_PRODUCT_STOCK, REORDER } from '../../settings/endpoints'
import { IErrorResponse, IResponce } from '../../types/commons'
import { basketApi } from '../../api/BasketService'
import { useCustomSnackbar } from '../../hooks/useSnackbar'
import { IProductLocation, IProductLocationRes, IProductStockRes, IStock } from '../../types/models'

export const Order = () => {
  const { errorSnack } = useCustomSnackbar()
  const [isFullProductCard, setIsFullProductCard] = useState(false)
  const [reorderFetching, setReorderFetching] = useState(false)
  const [productLocation, setProductLocation] = useState<IProductLocation>({})
  const [productStock, setProductStock] = useState<IStock>({})
  const params = useParams<{ id: string }>()
  const {
    data: resData,
    isLoading: isOrderLoading,
    isSuccess: isOrderSuccess,
    error: resDataError,
    isError: isResDataError,
  } = orderApi.useGetOrderByIdQuery(+params.id)
  const [putArchive, { isLoading: isPutArchiveLoading, error: putArchiveError, isError: isPutArchiveError }] =
    orderApi.usePutArchiveOrderMutation()
  const [
    deleteArchive,
    { isLoading: isDeleteArchiveLoading, error: deleteArchiveError, isError: isDeleteArchiveError },
  ] = orderApi.useDeleteArchiveOrderMutation()
  const [cancelOrder, { isLoading: isCancelOrderLoading, error: cancelOrderError, isError: isCancelOrderError }] =
    orderApi.useCancelOrderMutation()

  document.title = `Заказ №${params.id}`

  basketApi.useGetBasketQuery(null, {
    skip: !reorderFetching,
  })
  const history = useHistory()

  const { status, product, list, data } = resData?.result ?? {}

  useEffect(() => {
    if (list?.length) {
      Axios.post<IProductLocationRes>(POST_PRODUCT_LOCATION, {
        id: list.map(el => el.productId),
      }).then(res => {
        setProductLocation({ ...res.data.result })
      })
    }
  }, [list?.length])

  useEffect(() => {
    if (list?.length) {
      Axios.post<IProductStockRes>(POST_PRODUCT_STOCK, {
        id: list.map(el => el.productId),
      }).then(res => {
        setProductStock({ ...res.data.result })
      })
    }
  }, [list?.length])

  useEffect(() => {
    if (isResDataError) {
      const errorMessage = (resDataError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', resDataError)
    }
  }, [errorSnack, isResDataError, resDataError])

  useEffect(() => {
    if (isPutArchiveError) {
      const errorMessage = (putArchiveError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', putArchiveError)
    }
  }, [errorSnack, isPutArchiveError, putArchiveError])

  useEffect(() => {
    if (isDeleteArchiveError) {
      const errorMessage = (deleteArchiveError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', deleteArchiveError)
    }
  }, [deleteArchiveError, errorSnack, isDeleteArchiveError])

  useEffect(() => {
    if (isCancelOrderError) {
      const errorMessage = (cancelOrderError as { status: number; data: IErrorResponse }).data.message
      errorSnack(errorMessage)
      console.error('Ошибка: ', cancelOrderError)
    }
  }, [cancelOrderError, errorSnack, isCancelOrderError])

  const orderStatus = status?.find(status => data.statusId === status.statusId)

  const handleOpen = useCallback(() => {
    setIsFullProductCard(!isFullProductCard)
  }, [isFullProductCard])

  const handleReorder = async () => {
    setReorderFetching(true)
    await Axios.get<IResponce>(REORDER(+params.id))
    setReorderFetching(false)
    history.push('/basket')
  }

  return (
    <BackgroundLayout>
      <Loader
        isLoading={
          isOrderLoading || isDeleteArchiveLoading || isPutArchiveLoading || isCancelOrderLoading || reorderFetching
        }
      />
      <div className='order'>
        <div className='order__container'>
          <OrderToolbar
            orderId={+params.id}
            status={orderStatus}
            isArchive={data?.isArchived}
            deleteArchive={deleteArchive}
            putArchive={putArchive}
            cancelOrder={cancelOrder}
            repeatOrder={handleReorder}
          />
          <OrderProductsList
            handleOpen={handleOpen}
            orderList={list}
            orderProducts={product}
            productLocation={productLocation}
            productStock={productStock}
          />
          {/*<div className='basket-download-btn'>
            <div className='basket-download-btn__item'>
              <span>Состав корзины</span>
              <BasketDownload />
            </div>
            <div className='basket-download-btn__item'>
              <span>C характеристикми</span>
              <BasketDownload />
            </div>
          </div>*/}
        </div>
        <div className='order__details'>
          <OrderDetails order={data} productCount={list?.length} />
        </div>
      </div>
    </BackgroundLayout>
  )
}
