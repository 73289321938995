import React, { createRef, useState } from 'react'
import { Box, Chip, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { ISectionFiltersProps } from '../../types/models/section/filters/ISectionFiltersProps'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { filterSlice } from '../../redux/reducers/filterReducer'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import './MultiselectFilter.scss'

interface MultiselectFilterProps {
  prop: ISectionFiltersProps
  onCloseMultiselectFilter: (y: number) => void
}

const ITEM_HEIGHT = 60
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const MultiselectFilter = ({ prop, onCloseMultiselectFilter }: MultiselectFilterProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const { allFilters } = useTypedSelector(state => state.filterReducer)
  const { setFilter, unsetFilter, deleteDuplicates } = filterSlice.actions
  const [selected, setSelected] = useState<number[]>(
    allFilters.filter(i => i[0] === prop.id.toString())?.map(v => Number(v[1])) || [],
  )
  const filterProp = Object.entries(prop.values).sort((a, b) =>
    a[1].value.trim().toLowerCase() < b[1].value.trim().toLowerCase() ? -1 : 1,
  )
  const ref = createRef<null | HTMLDivElement>()

  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event
    setSelected(typeof value === 'string' ? value.split(',').map(i => Number(i)) : value)
  }

  const getYRef = () => {
    const rect = ref.current?.getBoundingClientRect()
    const clientHeight = ref.current?.clientHeight

    return rect.y + clientHeight / 2 || 0
  }

  const handleClose = () => {
    onCloseMultiselectFilter(getYRef())
  }

  const handleDelete = (htmlValueAlt: number) => {
    const find = filterProp.find(v => (v[1].htmlValueAlt === htmlValueAlt ? v[1] : null))
    if (find && find[1]) {
      dispatch(unsetFilter([prop.id, find[1].htmlValueAlt, find[1].value]))
    }
    setSelected(selected.filter(s => s !== htmlValueAlt))
    onCloseMultiselectFilter(getYRef())
  }

  return (
    <Select
      ref={ref}
      className='multiselect-filter'
      sx={{ minHeight: 40, height: 'auto', py: 0.5 }}
      fullWidth
      displayEmpty
      multiple
      value={selected}
      onChange={handleChange}
      onClose={handleClose}
      MenuProps={MenuProps}
      renderValue={renderSelected => {
        return selected.length !== 0 ? (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {renderSelected.map(value => (
              <Chip
                clickable
                key={value}
                label={filterProp.find(p => p[1].htmlValueAlt === value)[1].value}
                variant='outlined'
                onDelete={() => handleDelete(value)}
                onMouseDown={event => {
                  event.stopPropagation()
                }}
              />
            ))}
          </Box>
        ) : (
          <div>Выберите из списка</div>
        )
      }}
    >
      {filterProp.map(v => (
        <MenuItem
          onClick={() => {
            if (allFilters.filter(i => i[1].includes(v[1].htmlValueAlt)).length !== 0) {
              dispatch(unsetFilter([prop.id, v[1].htmlValueAlt, v[1].value]))
            } else {
              dispatch(setFilter({ [prop.id]: { [v[1].htmlValueAlt]: v[1].value } }))
              dispatch(deleteDuplicates({}))
            }
          }}
          key={v[1].htmlValueAlt}
          value={v[1].htmlValueAlt}
        >
          {v[1].value}
        </MenuItem>
      ))}
    </Select>
  )
}
