import React from 'react'

interface AmountBlock {
  amount: number
  unitOfMeasure: string
}

export const AmountBlock: React.FC<AmountBlock> = ({ amount, unitOfMeasure }) => {
  return <div style={amount ? null : { color: '#F23B2F' }}>{amount ? `${amount} (${unitOfMeasure})` : 'НЕТ'}</div>
}
