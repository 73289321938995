import { Autocomplete, Button, Divider, Menu, MenuItem, TextField } from '@mui/material'
import axios from 'axios'
import { FC, useState, MouseEvent, useEffect } from 'react'
import { useDebounce } from '../../hooks/useDebounce'
import { useCustomSnackbar } from '../../hooks/useSnackbar'
import { IMakeOrderOption } from '../../types/models'
import { ISuggestion, ISuggestionResponse } from '../../types/models/ISuggestions'
import { optionFormat } from '../../utils/optionFormat'
import Arrow from './DownArrow.svg'
import './AddressAutocomplete.scss'

interface AddressAutocompleteProps {
  disabled?: boolean
  options: IMakeOrderOption[]
  selectedAddress: IMakeOrderOption
  menuItemsBuild: (options: IMakeOrderOption[]) => JSX.Element[]
  onAddressChange: (option: IMakeOrderOption) => void
  isPickup: boolean
  placeholder?: string
  error: boolean
}

export const AddressAutocomplete: FC<AddressAutocompleteProps> = ({
  disabled,
  selectedAddress,
  options,
  isPickup,
  onAddressChange,
  error,
}) => {
  const { errorSnack } = useCustomSnackbar()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')

  const debounceInputValue = useDebounce(inputValue, 300)

  const open = Boolean(anchorEl)

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const fetchAddresses = async (q: string) => {
    const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
    const token = '25ae3b9135c1233f479d17b2e3741e4361eba780'
    if (!token) return

    try {
      setIsLoading(true)
      const response = await axios.post<ISuggestionResponse>(
        url,
        {
          query: q,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Token ' + token,
          },
        },
      )
      if (response.status === 200 && response.data) {
        setSuggestions(response.data.suggestions)
      }
    } catch (e) {
      errorSnack('При загрузке адресов произошла ошибка')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (debounceInputValue) {
      fetchAddresses(debounceInputValue)
    }
  }, [debounceInputValue])

  return (
    <Autocomplete
      classes={{
        inputRoot: 'address-autocomplete',
      }}
      value={isPickup ? 'Самовывоз' : selectedAddress}
      freeSolo
      onChange={(_, value, reason, details) => {
        if (!value) return

        if (typeof value == 'string') {
          onAddressChange({
            value: '',
            text: value,
          })
        } else {
          onAddressChange({
            value: value.value,
            text: value.text,
          })
        }
      }}
      clearOnBlur={false}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      disabled={disabled}
      loading={isLoading}
      loadingText='Загрузка...'
      filterOptions={x => x}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option
        } else {
          return option.text
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          placeholder='Введите адрес доставки'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {options.length > 0 && (
                  <>
                    <Divider variant='fullWidth' orientation='vertical' />
                    <Button disabled={disabled} onClick={handleOpen}>
                      <img src={Arrow} alt='' />
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      onClose={handleClose}
                    >
                      {options.map(option => (
                        <MenuItem
                          onClick={() => {
                            onAddressChange(option)
                            handleClose()
                          }}
                          sx={{ maxWidth: 370, whiteSpace: 'initial' }}
                          key={option.value}
                          value={option.value}
                        >
                          {optionFormat(option.text)}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              </>
            ),
          }}
        />
      )}
      options={suggestions.map(s => s.value)}
    />
  )
}
