import React, { useState } from 'react'
import './OrderToolbar.scss'
import { NavLink } from 'react-router-dom'
import { CancelIcon, PutArchiveIcon, RepeatIcon } from '../../commons/Icons'
import { DefaultButton } from '../../commons/mui/Button/DefaultButton'
import { IOrderStatus } from '../../types/models'
import { StyledPaper } from '../../commons/mui/Styled'
import { BasicModal } from '../../commons/mui/BasicModal'
import { CancelOrderModal } from '../../components/Order/CancelOrderModal'

interface OrderToolbar {
  orderId: number
  isArchive: boolean
  status: IOrderStatus
  putArchive: (id: number) => unknown
  deleteArchive: (id: number) => unknown
  cancelOrder: (id: number) => unknown
  repeatOrder: () => void
}

const CANCELED_STATUSES = ['I', 'DR', 'DF', 'D']

export const OrderToolbar: React.FC<OrderToolbar> = ({
  orderId,
  status,
  isArchive,
  putArchive,
  deleteArchive,
  cancelOrder,
  repeatOrder,
}) => {
  const [isCancelModal, setIsCancelModal] = useState(false)

  return (
    <>
      <div className='order-toolbar'>
        <div className='order-toolbar__container'>
          <div className='title-block'>
            <div className='bread-crumbs'>
              <NavLink to='/orders' className='back-link'>
                Мои заказы /
              </NavLink>
              &nbsp;
              <span className='order-id'>{orderId}</span>
            </div>
            {status && <span className='status'>{status.name}</span>}
          </div>

          <div className='buttons'>
            <DefaultButton
              color='secondary'
              startIcon={<PutArchiveIcon />}
              sx={{ paddingLeft: '20px', paddingRight: '20px' }}
              onClick={() => (isArchive ? deleteArchive(orderId) : putArchive(orderId))}
            >
              {isArchive ? 'Достать из архива' : 'В архив'}
            </DefaultButton>
            <DefaultButton onClick={repeatOrder} color='secondary' startIcon={<RepeatIcon />}>
              Повторить
            </DefaultButton>
            {status && CANCELED_STATUSES.indexOf(status.statusId) === -1 && (
              <DefaultButton onClick={() => setIsCancelModal(true)} color='secondary' startIcon={<CancelIcon />}>
                Отменить заказ
              </DefaultButton>
            )}

            <BasicModal open={isCancelModal} handleClose={() => setIsCancelModal(false)}>
              <StyledPaper>
                <CancelOrderModal
                  onSubmit={() => {
                    cancelOrder(orderId)
                    setIsCancelModal(false)
                  }}
                  onCancel={() => setIsCancelModal(false)}
                />
              </StyledPaper>
            </BasicModal>
          </div>
        </div>
      </div>
    </>
  )
}
