import { Dispatch } from 'redux'
import {
  ActionBooleanPayload,
  ActionSetBasket,
  ActionSetBasketLocation,
  ActionSetProductQ,
  ActionTypes,
  BasketAction,
} from '../reducers/basketReducer'
import { IBasket, IBasketRes, IProductLocation } from '../../types/models'
import { Axios } from '../../axios'
import { GET_BASKET } from '../../settings/endpoints'

export const basketActions = {
  setBasket: (basket: IBasket): ActionSetBasket => ({ type: ActionTypes.SET_BASKET, payload: basket }),

  setBasketLocation: (location: IProductLocation): ActionSetBasketLocation => ({
    type: ActionTypes.SET_BASKET_LOCATION,
    payload: location,
  }),

  setBasketProductQ: (productId: number, productQ: number): ActionSetProductQ => ({
    type: ActionTypes.SET_BASKET_PRODUCT_Q,
    payload: {
      productId: productId,
      productQ: productQ,
    },
  }),

  setIsBasketFetched: (isFetching: boolean): ActionBooleanPayload => ({
    type: ActionTypes.SET_IS_BASKET_FETCHED,
    payload: isFetching,
  }),

  setIsLoading: (isLoading: boolean): ActionBooleanPayload => ({
    type: ActionTypes.SET_IS_LOADING,
    payload: isLoading,
  }),

  fetchBasket: () => {
    return async (dispatch: Dispatch<BasketAction>) => {
      try {
        const response = await Axios.get<IBasketRes>(GET_BASKET)

        dispatch(basketActions.setBasket(response.data.result))

        dispatch(basketActions.setIsBasketFetched(true))
      } catch (err) {
        return err
      }
    }
  },
}
