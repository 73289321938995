import React, { useEffect } from 'react'
import './Main.scss'
import { mainApi } from '../../api/MainService'
import { Widget } from '../../components/Widget'
import Loader from '../../commons/Loader/Loader'
import { useCustomSnackbar } from '../../hooks/useSnackbar'
import { IErrorResponse } from '../../types/commons'
import { CatalogContainer } from '../../containers/catalogContainer'

export const Main = (): JSX.Element => {
  const { data, isLoading, isError, error } = mainApi.useGetWidgetsQuery()

  const { errorSnack } = useCustomSnackbar()

  useEffect(() => {
    if (isError) {
      const errorInfo = error as { status: number; data: IErrorResponse }
      const errorMessage = errorInfo?.data?.message
      console.error('Ошибка: ', error)
      errorSnack(errorMessage)
      if (!errorInfo?.status && !errorInfo?.data) {
        window.location.reload()
      }
    }
  }, [error, errorSnack, isError])

  const widgetArray = data?.result || []

  return (
    <CatalogContainer isToolbar catalogSearchOnly>
      <>
        <Loader isLoading={isLoading} />
        <div className='main-page'>
          {widgetArray.map((widget, index) => (
            <div key={index} className='main-page__widget'>
              <Widget title={widget.title} products={widget.data} />
            </div>
          ))}
        </div>
      </>
    </CatalogContainer>
  )
}
