import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Box } from '@mui/material'
import { CatalogToolbar } from '../catalogToolbar'
import { sectionApi } from '../../api/SectionService'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { CatalogFilter } from '../catalogFilter'
import { ModalRightSide } from '../../commons/modals/modalRightSide'
import { SideBar } from '../sideBar'
import { Axios } from '../../axios'
import { GET_SECTIONS } from '../../settings/endpoints'
import { useActions } from '../../hooks/useActions'
import { useCustomSnackbar } from '../../hooks/useSnackbar'
import { ISection, TCatalogLayout } from '../../types/models'

interface PageProps {
  isToolbar?: boolean
  skipFilters?: boolean
  layout?: TCatalogLayout
  children: ReactElement
  setLayout?: (layout: TCatalogLayout) => void
  isNotSectionFetching?: boolean
  sections?: ISection[]
  isSidebarSingleLevel?: boolean
  catalogSearchOnly?: boolean
}

export const CatalogContainer = ({
  isToolbar = false,
  catalogSearchOnly,
  sections,
  isNotSectionFetching = false,
  isSidebarSingleLevel,
  layout = 'grid',
  skipFilters,
  children,
  setLayout,
}: PageProps): JSX.Element => {
  const [isStockInfo, setIsStockInfo] = useState(false)
  const [isFilter, setIsFilter] = useState(false)

  const { filters, selectors: productSelectors } = useTypedSelector(state => state.productReducer)
  const { bannersCount } = useTypedSelector(state => state.infoReducer)
  const { data: filtersData } = sectionApi.useGetSectionFiltersQuery(filters.sectionId || 0, { skip: skipFilters })
  const { getSections } = useActions()

  const isLaptop = useMediaQuery({ maxWidth: 1440 })

  const { errorSnack } = useCustomSnackbar()

  const openStockInfo = useCallback(() => {
    setIsStockInfo(!isStockInfo)
  }, [isStockInfo])

  useEffect(() => {
    if (!isNotSectionFetching) {
      Axios.get(GET_SECTIONS, { params: { active: 'Y', view: 'tree' } })
        .then(res => getSections(res.data.result))
        .catch(err => {
          const errorMessage = err?.response?.data?.message || 'Произошла ошибка'
          if (err?.response?.status) {
            errorSnack(errorMessage)
            console.error('Ошибка: ', err?.response)
          }
        })
    } else if (sections?.length) {
      getSections(sections)
    }
  }, [isNotSectionFetching, sections?.length])

  return (
    <Box>
      {isToolbar && (
        <>
          <CatalogToolbar
            searchOnly={catalogSearchOnly}
            isFiltersAvailable={filtersData !== undefined || productSelectors?.length > 0}
            layout={layout}
            setLayout={setLayout}
            openStockInfo={openStockInfo}
            openFilter={setIsFilter}
          />
          <ModalRightSide isOpen={isFilter} handleOpen={setIsFilter}>
            <CatalogFilter filters={filtersData?.result} setIsOpen={setIsFilter} />
          </ModalRightSide>
        </>
      )}
      <Box display='flex'>
        <SideBar isToolbar={isToolbar} isLaptop={isLaptop} isSingleLevel={isSidebarSingleLevel} />
        <Box
          sx={{ overflowY: 'auto' }}
          height={
            isToolbar
              ? `calc(100vh - ${bannersCount ? 120 + bannersCount * 60 : 120}px)`
              : `calc(100vh - ${bannersCount ? 60 + bannersCount * 60 : 60}px)`
          }
          width='100%'
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}
