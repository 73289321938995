import React, { useState } from 'react'
import ReactImageMagnify from 'react-image-magnify'
import './ImageSlider.scss'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import defaultImage from '../../assets/png/defaultImage.png'

interface ICheckbox {
  pictureDetail: string
  morePicture?: string[]
}

export const ImageSlider: React.FC<ICheckbox> = ({ pictureDetail, morePicture }) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const pictures = morePicture ? [pictureDetail].concat(morePicture) : [pictureDetail]

  const handleClick = (value: number) => {
    if (value >= 0 && value < pictures.length) setPhotoIndex(value)
  }
  return (
    <div className='image-slider'>
      <div className='slider-photo'>
        <div className='slider-photo__back-btn' onClick={() => handleClick(photoIndex - 1)} />
        <SwitchTransition mode='out-in'>
          <CSSTransition classNames='show-el' in={true} timeout={200} unmountOnExit key={photoIndex}>
            <ReactImageMagnify
              smallImage={{
                isFluidWidth: false,
                width: 350,
                height: 350,
                src: pictures[photoIndex] || defaultImage,
                alt: 'img',
              }}
              largeImage={{
                src: pictures[photoIndex] || defaultImage,
                width: 1000,
                height: 1000,
              }}
              imageStyle={{
                objectFit: 'contain',
              }}
              enlargedImageContainerStyle={{
                zIndex: 99999,
              }}
            />
          </CSSTransition>
        </SwitchTransition>
        <div className='slider-photo__next-btn' onClick={() => handleClick(photoIndex + 1)} />
      </div>
      <div className='carousel-photo'>
        {pictures &&
          pictures.map(
            (picture, index) =>
              picture && (
                <img
                  key={index}
                  src={picture}
                  width='60'
                  height='60'
                  alt='img'
                  className={
                    index === photoIndex ? 'carousel-photo__img' + ' carousel-photo__img_active' : 'carousel-photo__img'
                  }
                  onClick={() => handleClick(index)}
                />
              ),
          )}
      </div>
    </div>
  )
}
