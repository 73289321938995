import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { ProductSelectorCode } from '../../types/models'

interface filterState {
  filter: {}
  isSubmit: boolean
  selectors: string[]
  allFilters: any[]
}

export const initialState: filterState = {
  filter: {},
  isSubmit: true,
  selectors: ['aval'],
  allFilters: [],
}

const equals = (a, b) => {
  if (JSON.stringify(a) === JSON.stringify(b)) {
    return true
  } else {
    return false
  }
}

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setAllFilters: (state, action: PayloadAction<any[]>) => {
      state.allFilters.splice(0, state.allFilters.length, ...action.payload)
    },
    setFilter: (state, action: PayloadAction<{}>) => {
      state.filter = { ...state.filter, ...action.payload }
      let proxyFilters = []
      for (let key in action.payload) {
        if (key === 'p19') {
          proxyFilters.push(['p19', Object.keys(action.payload[key])[0], Object.values(action.payload[key])[0]])
          proxyFilters.push(['p19', Object.keys(action.payload[key])[1], Object.values(action.payload[key])[1]])
        } else {
          proxyFilters.push([key, Object.keys(action.payload[key])[0], Object.values(action.payload[key])[0]])
        }
      }
      state.allFilters = [...state.allFilters, proxyFilters.length === 1 ? proxyFilters[0] : proxyFilters]
    },

    deleteDuplicates: (state, action: PayloadAction<{}>) => {
      if (action.payload !== 'price') {
        let a = current(state.allFilters)
        let prox = JSON.stringify(a)
        let proxymity = JSON.parse(prox)
        for (let i = 0; i < proxymity.length; i++) {
          let value = proxymity[i]
          for (let j = 0; j < proxymity.length; j++) {
            if (equals(value, proxymity[j]) && j !== i) {
              proxymity[j] ? (proxymity[j] = undefined) : null
            }
          }
        }

        state.allFilters = proxymity.filter(i => i !== undefined)
      } else {
        let array = JSON.parse(JSON.stringify(current(state.allFilters)))
        const notPricesArray = array.filter(i => i.length === 3)
        let pricesArray = array.filter(i => i.length === 2)
        pricesArray = array.pop()
        if (pricesArray) {
          state.allFilters = [...notPricesArray, pricesArray]
        }
      }
    },
    unsetFilter: (state, action: PayloadAction<{}>) => {
      const tmp = { [action.payload[0]]: { [action.payload[1]]: action.payload[2] } }
      state.filter = { ...state.filter, tmp }

      state.allFilters = state.allFilters.filter(i => !i[1].includes(action.payload[1]))
    },
    setSelector: (state, action: PayloadAction<ProductSelectorCode>) => {
      state.selectors.push(action.payload)
    },
    deleteSelector: (state, action: PayloadAction<ProductSelectorCode>) => {
      state.selectors = state.selectors.filter(selector => selector !== action.payload)
    },
    resetSelectors: (state, action: PayloadAction<null>) => {
      state.selectors = []
    },

    resetFilter: (state, action: PayloadAction<null>) => {
      state.filter = {}
      state.allFilters = []
    },
    setIsSubmit: (state, action: PayloadAction<boolean>) => {
      state.isSubmit = action.payload
    },
  },
})

export const filterReducer = filterSlice.reducer
