import { useEffect, useState } from 'react'
import { searchApi } from '../../api/SearchService'
import Loader from '../../commons/Loader/Loader'
import { Widget } from '../../components/Widget'
import { CatalogContainer } from '../../containers/catalogContainer'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useQueryParams } from '../../hooks/useQueryParams'
import './Search.scss'
import { useCustomSnackbar } from '../../hooks/useSnackbar'
import { setQueryValue } from '../../redux/reducers/searchReducer'
import { IErrorResponse } from '../../types/commons'

export const Search = (): JSX.Element => {
  const query = useQueryParams()
  const { errorSnack } = useCustomSnackbar()
  const dispatch = useAppDispatch()

  const q = query.get('q')

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(20)

  const { data, isFetching, isError, error } = searchApi.useGetSearchQuery({
    query: q,
    currentPage,
    perPage,
  })

  const onCurrentPageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const onPerPageChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  useEffect(() => {
    if (isError) {
      const errorMessage = (error as { status: number; data: IErrorResponse })?.data?.message || 'Произошла ошибка'
      console.error('Ошибка: ', error)
      errorSnack(errorMessage)
    }
  }, [error, errorSnack, isError])

  useEffect(() => {
    return () => {
      dispatch(setQueryValue(''))
    }
  }, [])

  return (
    <CatalogContainer
      isToolbar
      catalogSearchOnly
      sections={data?.result?.sections}
      isNotSectionFetching
      isSidebarSingleLevel
    >
      <>
        <Loader isLoading={isFetching} />
        <div className='search-page'>
          <Widget
            title={data?.result?.products?.length ? 'Результаты поиска' : isFetching ? 'Поиск...' : 'Ничего не найдено'}
            products={data?.result?.products || []}
            pagination={{
              totalRows: data?.result?.pagination?.totalRows,
              currentPage,
              perPage,
              totalPages: data?.result?.pagination?.totalPages,
            }}
            onPageChange={onCurrentPageChange}
            onPerPageChange={onPerPageChange}
          />
        </div>
      </>
    </CatalogContainer>
  )
}
