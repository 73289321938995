import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import { isProduction } from '../utils/isProduction'

export const useGaTracker = () => {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  const gaKey = 'UA-75343187-3'

  useEffect(() => {
    if (gaKey && isProduction) {
      ReactGA.initialize(gaKey)
      setInitialized(true)
    }
  }, [gaKey])

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [initialized, location])

  return {
    ReactGA,
  }
}
