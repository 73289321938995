import React from 'react'
import { ClipLoader } from 'react-spinners'
import './FilterNote.scss'
import { css } from '@emotion/react'
import { getNoun } from '../../utils/getNoun'

interface FilterNoteProps {
  coordsY: number
  count: number
  onClick: () => void
  isLoading?: boolean
}

const override = css`
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
`

export const FilterNote = ({ coordsY, count, isLoading = false, onClick }: FilterNoteProps): JSX.Element => {
  return (
    <div style={{ top: coordsY, opacity: isLoading ? 0.8 : 1 }} onClick={onClick} className='filter-note'>
      <ClipLoader color='black' loading={isLoading} css={override} size={25} />
      {count > 0
        ? `${getNoun(count, 'Выбран', 'Выбрано', 'Выбрано')} ${count} ${getNoun(count, 'товар', 'товара', 'товаров')}`
        : 'Таких товаров нет'}
    </div>
  )
}
