import React, { useCallback, useEffect, useState } from 'react'
import './Basket.scss'
import { useMediaQuery } from 'react-responsive'
import { Box, Typography } from '@mui/material'
import { AnalogsModal } from '../../components/basket/AnalogsModal'
import { BasketToolbar } from '../../components/basket/BasketToolbar'
import { BasketItem } from '../../components/basket/BasketItem'
import { BasketOrderDetails } from '../../components/basket/BasketOrderDetails'
import { OrderForm } from '../../containers/orderForm'
import Loader from '../../commons/Loader/Loader'
import { BasicModal } from '../../commons/mui/BasicModal'
import { StyledPaper } from '../../commons/mui/Styled'
import { useBasket } from '../../hooks/useBasket'
import { WarningModal } from '../../components/basket/WarningModal'
import { DeleteModal } from '../../components/basket/DeleteModal'
import { DefaultButton } from '../../commons/mui/Button/DefaultButton'
import { useTypedSelector } from '../../hooks/useTypedSelector'

export const Basket = () => {
  document.title = 'Моя корзина'
  const {
    fetchProductAnalogs,
    basket,
    products,
    productAnalogs,
    productLocation,
    productStock,
    deleteProduct,
    addToBasket,
    setProductQuantity,
    currentWarningProduct,
    recalculate,
    isLoading,
    warningProducts,
    isWarning,
    refetchBasket,
    currentSort,
    warningProductId,
    orderData,
    setCurrentSortHandler,
    closeAnalogsModalHandler,
    openAnalogsModalHandler,
    productReplacementHandler,
    isEmpty,
    reloadProducts,
  } = useBasket()

  const [isFull, setIsFull] = useState(false)
  const [isForm, setIsForm] = useState(false)
  const [deleteId, setDeleteId] = useState<number>(null)

  const isLaptop = useMediaQuery({ maxWidth: 1440 })
  const isDesktop = useMediaQuery({ minWidth: 1920 })
  const isIndividual = true // Будут роли

  const { bannersCount } = useTypedSelector(state => state.infoReducer)

  const deleteProductHandler = async () => {
    deleteProduct(deleteId)
    setDeleteId(null)
  }

  const openFull = useCallback(() => {
    setIsFull(!isFull)
  }, [isFull])

  const openForm = useCallback(() => {
    setIsForm(!isForm)
  }, [isForm])

  const productAnalogsPageChange = async (page: number) => {
    if (warningProductId) {
      await fetchProductAnalogs(warningProductId, page)
    }
  }

  useEffect(() => {
    reloadProducts()
  }, [basket.list?.length])

  return (
    <>
      <div className='basket' style={{ height: `calc(100vh - ${bannersCount ? 120 + bannersCount * 60 : 120}px)` }}>
        <div className='basket__order-form-modal'>
          <BasicModal open={isForm} handleClose={() => setIsForm(false)}>
            <StyledPaper>
              {isWarning && (
                <WarningModal
                  deleteId={deleteId}
                  warningProducts={warningProducts}
                  setDeleteId={setDeleteId}
                  deleteProduct={deleteProductHandler}
                  basketList={basket.list}
                  handleClose={() => setIsForm(false)}
                  setIsAnalogsForm={openAnalogsModalHandler}
                />
              )}
              {isEmpty && (
                <Box width='310px' p='30px 20px 20px 20px'>
                  <Typography variant='h4' textAlign='center'>
                    Добавьте товар в корзину.
                  </Typography>
                  <Box mt='31px' display='flex' columnGap='10px'>
                    <DefaultButton fullWidth onClick={() => setIsForm(false)} color='primary'>
                      Ок
                    </DefaultButton>
                  </Box>
                </Box>
              )}
              {!isWarning && !isEmpty && (
                <OrderForm
                  formData={orderData?.result}
                  setOpenModal={setIsForm}
                  productCount={basket.listCount}
                  productUniqueCount={basket.listCount}
                  totalPrice={basket.priceTotal}
                  refetchBasket={refetchBasket}
                  basketList={basket.list}
                />
              )}
            </StyledPaper>
          </BasicModal>
          <BasicModal open={deleteId !== null} handleClose={() => setDeleteId(null)}>
            <StyledPaper>
              <DeleteModal onSubmit={deleteProductHandler} onCancel={() => setDeleteId(null)} />
            </StyledPaper>
          </BasicModal>
          <BasicModal open={!!currentWarningProduct && !!productAnalogs} handleClose={closeAnalogsModalHandler}>
            <StyledPaper>
              {!!currentWarningProduct && !!productAnalogs && (
                <AnalogsModal
                  product={currentWarningProduct}
                  basketList={basket.list}
                  specifications={productAnalogs.details}
                  analogProducts={productAnalogs.data}
                  pagination={productAnalogs.pagination}
                  pageChange={productAnalogsPageChange}
                  cancelHandler={closeAnalogsModalHandler}
                  productReplacement={productReplacementHandler}
                />
              )}
            </StyledPaper>
          </BasicModal>
        </div>
        <Loader isLoading={isLoading} />
        <BasketToolbar
          openForm={openForm}
          isDesktop={isDesktop}
          isLaptop={isLaptop}
          isFull={isFull}
          totalPrice={basket.priceTotal}
          productCount={basket.listCount}
          priceVat={basket.priceVat}
          discountPercent={basket.discountPercent}
          priceNotDiscount={basket.priceNotDiscount}
          priceSaved={basket.priceSaved}
          recalculate={recalculate}
        />
        <div className='basket__wrapper'>
          <div className='basket-content'>
            <div className='basket-content__item'>
              {/* {products !== undefined && ( */}
              <BasketItem
                addToBasket={addToBasket}
                setProductQuantity={setProductQuantity}
                isFull={isFull}
                openFull={openFull}
                isIndividual={isIndividual}
                basket={basket}
                location={productLocation ?? []}
                stock={productStock ?? []}
                isWarning={isWarning}
                setDeleteId={setDeleteId}
                basketProducts={products}
                currentSort={currentSort}
                setCurrentSortHandler={setCurrentSortHandler}
                openForm={openForm}
              />
              {/* )} */}
            </div>
          </div>
          <div className='details-container'>
            {!isLaptop && (
              <BasketOrderDetails
                openForm={openForm}
                productCount={basket.listCount}
                totalPrice={basket.priceTotal}
                priceVat={basket.priceVat}
                discountPercent={basket.discountPercent}
                priceNotDiscount={basket.priceNotDiscount}
                priceSaved={basket.priceSaved}
                recalculate={recalculate}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
