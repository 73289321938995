import { useState } from 'react'
import { Axios } from '../axios'
import { IBasketRecalculateRes } from '../types/models'
import { RECALCULATE } from '../settings/endpoints'
import { useCustomSnackbar } from './useSnackbar'

export const useRecalculate = () => {
  const { infoSnack } = useCustomSnackbar()
  const [isRecalculateLoading, setIsRecalculateLoading] = useState(false)

  const recalculate = async () => {
    setIsRecalculateLoading(true)
    const res = await Axios.get<IBasketRecalculateRes>(RECALCULATE)
    setIsRecalculateLoading(false)
    if (res.data.result) {
      infoSnack('Цены пересчитаны!')
    }
  }

  return {
    recalculate,
    isRecalculateLoading,
  }
}
