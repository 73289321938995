import * as React from 'react'
import { ReactElement } from 'react'
import Popover, { PopoverProps } from '@mui/material/Popover'

interface HoverPopover {
  trigger: ReactElement
  anchorOrigin?: PopoverProps['anchorOrigin']
  transformOrigin?: PopoverProps['transformOrigin']
}

export const HoverPopover: React.FC<HoverPopover> = ({ children, trigger, anchorOrigin, transformOrigin }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      <div aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup='true'>
        {trigger}
      </div>
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none',
          boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
          margin: '10px',
        }}
        PaperProps={{ sx: { boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)', pointerEvents: 'auto' } }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        elevation={0}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {children}
      </Popover>
    </div>
  )
}
