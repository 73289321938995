import React from 'react'
import './SideBar.scss'
import { shallowEqual } from 'react-redux'

import { Box } from '@mui/material'
import { SectionsFull } from '../../components/SectionsFull'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { SectionsHidden } from '../../components/SectionsHidden'
import { useActions } from '../../hooks/useActions'

interface SideBarProps {
  isLaptop: boolean
  isToolbar: boolean
  isSingleLevel?: boolean
}

export const SideBar = ({ isLaptop, isToolbar, isSingleLevel }: SideBarProps) => {
  const { setExpandedSectionId } = useActions()
  const { sections, expandedSectionsId } = useTypedSelector(state => state.productReducer, shallowEqual)
  const { bannersCount } = useTypedSelector(state => state.infoReducer)

  const expandHelper = (id: number) => {
    if (!expandedSectionsId.includes(id)) {
      setExpandedSectionId([...expandedSectionsId, id])
    } else {
      const filteredIds = expandedSectionsId.filter(prevId => prevId !== id)
      setExpandedSectionId(filteredIds)
    }
  }

  const bannerHeight = bannersCount ? 60 * bannersCount : 0

  return (
    <div className='side-bar'>
      {isLaptop ? (
        // <Box top={isToolbar ? 120 : 60} className='side-bar__hidden'>
        <Box
          top={isToolbar ? 120 + bannerHeight : 60 + bannerHeight}
          className='side-bar__hidden'
          sx={{ height: `calc(100% - ${isToolbar ? 120 + bannerHeight : 60 + bannerHeight}px)` }}
        >
          <SectionsHidden
            sections={sections}
            expandSection={expandHelper}
            expandSectionId={expandedSectionsId}
            isSingleLevel={isSingleLevel}
          />
        </Box>
      ) : (
        <div className='side-bar__full'>
          <SectionsFull
            sections={sections}
            expandSection={expandHelper}
            expandSectionId={expandedSectionsId}
            isSingleLevel={isSingleLevel}
          />
        </div>
      )}
    </div>
  )
}
