import React, { useCallback, useState } from 'react'
import './BasketToolbar.scss'
import { CSSTransition } from 'react-transition-group'
import { Search } from '../../search'
import { ModalRightSide } from '../../../commons/modals/modalRightSide'
import { UploadExcelButton } from '../../uploadExcelButton'
import { BasketOrderDetails } from '../BasketOrderDetails'
import { DefaultButton } from '../../../commons/mui/Button/DefaultButton'

interface BasketToolbarProps {
  isDesktop: boolean
  isLaptop: boolean
  isFull: boolean
  productCount: number
  totalPrice: number
  priceVat: number
  discountPercent: number
  priceNotDiscount: number
  priceSaved: number
  openForm: () => void
  recalculate: () => Promise<void>
}

export const BasketToolbar = ({
  isDesktop,
  isLaptop,
  isFull,
  openForm,
  productCount,
  totalPrice,
  priceVat,
  priceNotDiscount,
  priceSaved,
  discountPercent,
  recalculate,
}: BasketToolbarProps) => {
  const [isDetails, setIsDetails] = useState(false)

  const openDetails = useCallback(() => {
    setIsDetails(!isDetails)
  }, [isDetails])

  const openFormHandler = async () => {
    await recalculate()
    openForm()
  }

  return (
    <div className='basket-toolbar'>
      <div className='basket-toolbar__left-col'>
        <div className='search-btn'>
          <Search />
        </div>
        <CSSTransition in={isFull && isDesktop} timeout={200} classNames='show-el' unmountOnExit>
          <button className='add-barcode-btn'>Добавить товар по штрих коду</button>
        </CSSTransition>

        <UploadExcelButton />
        {/*{!isDesktop && (*/}
        {/*  <div className='debt-btn'>*/}
        {/*    <Debt isDesktop={isDesktop} />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>

      {isLaptop && (
        <div className='basket-toolbar__right-col'>
          <DefaultButton onClick={openDetails} color='secondary'>
            Детали заказа
          </DefaultButton>
          <DefaultButton onClick={openFormHandler} color='primary' variant='outlined'>
            Сформировать заказ
          </DefaultButton>
          <div className='order-details-modal'>
            <ModalRightSide isOpen={isDetails} handleOpen={openDetails}>
              <BasketOrderDetails
                openForm={openForm}
                productCount={productCount}
                totalPrice={totalPrice}
                priceVat={priceVat}
                priceSaved={priceSaved}
                priceNotDiscount={priceNotDiscount}
                discountPercent={discountPercent}
                recalculate={recalculate}
              />
            </ModalRightSide>
          </div>
        </div>
      )}
    </div>
  )
}
