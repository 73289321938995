import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { URL } from '../settings/endpoints'
import { INewsDetailRes, INewsListQuery, INewsListRes } from '../types/models/news'
import { axiosBaseQuery } from './config'

export const newsApi = createApi({
  reducerPath: 'newsApi',
  baseQuery: axiosBaseQuery({ baseUrl: URL }),
  endpoints: build => ({
    getNewsList: build.query<INewsListRes, INewsListQuery>({
      query: data => ({
        url: '/news/list',
        params: data,
      }),
    }),

    getNewsById: build.query<INewsDetailRes, string>({
      query: data => ({
        url: '/news/detail',
        params: {
          id: data,
        },
      }),
    }),
  }),
})
