import React, { ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import './Search.scss'
import { Box, Fade, Typography } from '@mui/material'
import { Link, useHistory } from 'react-router-dom'
import { searchApi } from '../../api/SearchService'
import { useActions } from '../../hooks/useActions'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useDebounce } from '../../hooks/useDebounce'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import searchIco from '../../assets/svg/SeachIco.svg'
import { filterSlice } from '../../redux/reducers/filterReducer'
import { setQueryValue } from '../../redux/reducers/searchReducer'
import { SearchCompletionsItem } from './SearchCompletionsItem'

interface SearchProps {
  historyLimit?: number
}

export const Search = ({ historyLimit = 3 }: SearchProps) => {
  const history = useHistory()
  const rootRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const dispatch = useAppDispatch()
  const { resetFilter } = filterSlice.actions
  const { setSectionId } = useActions()
  const { queryValue } = useTypedSelector(state => state.searchReducer)
  const [getHistory, searchHistory] = searchApi.useLazyGetSearchHistoryQuery()
  const [getCompletions, searchCompletions] = searchApi.useLazyGetSearchCompletionsQuery()
  const [openHistory, setOpenHistory] = useState<boolean>(false)
  const [openCompletions, setOpenCompletions] = useState<boolean>(false)

  const debouncedQueryValue = useDebounce(queryValue, 300)

  useOnClickOutside(rootRef, () => {
    setOpenCompletions(false)
  })

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setQueryValue(event.target.value))
  }

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      setOpenCompletions(false)
      if (inputRef.current) {
        inputRef.current.blur()
      }
      history.push({
        pathname: '/search',
        search: `?${new URLSearchParams({ q: queryValue }).toString()}`,
      })
    }
  }

  const onInputFocus = async (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value) {
      await getCompletions({ query: value })
      setOpenCompletions(true)
    } else {
      await getHistory()
      setOpenHistory(true)
    }
  }

  const onInputBlur = () => {
    setOpenHistory(false)
  }

  const onLinkHistoryClick = historyValue => {
    dispatch(setQueryValue(historyValue))
  }

  const onCategoryClick = (id: number, pageUrl: string) => {
    dispatch(resetFilter())
    setSectionId(id)
    setOpenCompletions(false)
    setOpenHistory(false)
    history.push(pageUrl)
  }

  const onOfferClick = () => {
    setOpenCompletions(false)
    setOpenHistory(false)
  }

  useEffect(() => {
    const fetchCompletions = async () => {
      if (debouncedQueryValue) {
        await getCompletions({ query: debouncedQueryValue })
        setOpenCompletions(true)
      }
    }
    fetchCompletions().catch(err => {
      console.log(err)
    })
  }, [debouncedQueryValue])

  return (
    <Box className='search' ref={rootRef}>
      <div className='search__input-block'>
        <input
          ref={inputRef}
          type='text'
          placeholder='Артикул, код, название...'
          id='title-search-input'
          name='q'
          value={queryValue}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
        <img src={searchIco} alt='searchIco' />
        <Fade in={openHistory && !!searchHistory.data?.result?.length && !queryValue} timeout={350}>
          <div className='search-box search-history'>
            <ul>
              {searchHistory.data?.result?.map(
                (historyItem, index) =>
                  index < historyLimit && (
                    <li key={index} className='search-history__item'>
                      <Link
                        className='search-history__link'
                        to={`/search?q=${historyItem.text.value}`}
                        onClick={() => onLinkHistoryClick(historyItem.text.value)}
                      >
                        <Typography sx={{ textTransform: 'capitalize' }} fontSize={16}>
                          {historyItem.text.value}
                        </Typography>
                        <Typography sx={{ opacity: 0.4 }} component='span'>
                          История поиска
                        </Typography>
                      </Link>
                    </li>
                  ),
              )}
            </ul>
          </div>
        </Fade>
        <Fade in={openCompletions && !!searchCompletions.data?.result && !!queryValue} timeout={350}>
          <div className='search-box search-completions'>
            <div className='search-completions__left'>
              <Typography p={1} fontWeight={600} sx={{ opacity: 0.6 }}>
                Категории
              </Typography>
              <ul>
                {searchCompletions.data?.result?.categories.map(category => (
                  <li key={category.id}>
                    <Typography
                      className='search-completions__category'
                      dangerouslySetInnerHTML={{ __html: category.text.html }}
                      onClick={() => onCategoryClick(category.id, category.url)}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div className='search-completions__right'>
              {searchCompletions.data?.result?.offers.map(offer => (
                <SearchCompletionsItem key={offer.id} offer={offer} onOfferClick={onOfferClick} />
              ))}
            </div>
          </div>
        </Fade>
      </div>
    </Box>
  )
}
