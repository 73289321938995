import React, { ChangeEvent, FormEvent, useState } from 'react'
import './ForgotPassword.scss'
import { Box, Paper } from '@mui/material'
import axios from 'axios'
import loader from '../../assets/svg/loaders/rectangleBlackLoader.svg'
import { Axios } from '../../axios'
import { FORGOT } from '../../settings/endpoints'
import { isEmail } from '../../utils/isEmail'
import { useCustomSnackbar } from '../../hooks/useSnackbar'

interface ForgotPasswordProps {
  submitEmail: () => void
}

export const ForgotPassword = ({ submitEmail }: ForgotPasswordProps): JSX.Element => {
  const { successSnack, errorSnack } = useCustomSnackbar()
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleBlur = () => {
    if (email && isEmail(email)) {
      setError(false)
    } else {
      setError(true)
    }
  }

  const resetForm = () => {
    setEmail('')
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!email || !isEmail(email)) {
      setError(true)
      return
    }
    setIsLoading(true)
    try {
      const res = await Axios.post(FORGOT, { email })
      if (res.data.result) {
        resetForm()
        successSnack('Ссылка для восстановления пароля отправлена на указанную почту')
        submitEmail()
      }
    } catch (e: unknown) {
      if (axios.isAxiosError(e)) {
        errorSnack('Email не найден')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Box component='form' onSubmit={handleSubmit}>
        <Box sx={{ fontSize: 18, mb: 2, textAlign: 'center' }}>
          Укажите Email, на который будет отправлена ссылка <br /> для восстановления пароля
        </Box>
        <Box component='label' mb={1} display='inline-block'>
          Email
          <Box component='span' sx={{ color: '#f23b2f' }}>
            &nbsp;*
          </Box>
        </Box>
        <div className={error ? 'forgot-password__input forgot-password__input_error' : 'forgot-password__input'}>
          <input placeholder='Email' name='title' value={email} onChange={handleChangeEmail} onBlur={handleBlur} />
          {error && <span className='forgot-password__error'>Укажите корректный email</span>}
        </div>
        <button type='submit' className='forgot-password__btn' disabled={isLoading}>
          {isLoading ? <img src={loader} alt='' height='30' width='30' /> : 'Отправить'}
        </button>
      </Box>
    </Paper>
  )
}
