import React from 'react'
import { Button, ButtonProps } from '@mui/material'

interface DefaultButton {
  boxShadow?: boolean
}

export const DefaultButton: React.FC<ButtonProps & DefaultButton> = ({ boxShadow = true, ...props }) => {
  return (
    <Button
      {...props}
      sx={{ boxShadow: boxShadow ? '0px 1px 2px rgba(0, 0, 0, 0.05)' : 'none', ...props.sx }}
      variant='outlined'
    >
      {props.children}
    </Button>
  )
}
