import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { ILoginRes } from '../types/models/login/ILoginRes'
import { URL } from '../settings/endpoints'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: URL }),
  endpoints: build => ({
    login: build.mutation<ILoginRes, {}>({
      query: loginData => ({
        url: '/login',
        method: 'POST',
        body: loginData,
      }),
    }),
  }),
})
