import React, { useMemo } from 'react'
import './OrdersList.scss'
import { useHistory } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { Column } from 'react-table'
import { PrintIcon } from '../../commons/Icons'
import { IOrder, IOrderStatus } from '../../types/models'
import { Table } from '../../commons/Table'
import { CustomCheckbox } from '../../commons/mui/Checkbox/CustomCheckbox'
import { IPagination } from '../../types/commons'
import { TableTools } from '../bottomTools'
import { formatNumber } from '../../utils/formatNumber'

interface OrdersList {
  handlePrint: (id: number) => Promise<void>
  orders: IOrder[]
  orderStatuses: IOrderStatus[]
  selected: IOrder[]
  setSelected: React.Dispatch<React.SetStateAction<IOrder[]>>
  setCurrentPage: (value: number) => unknown
  setPerPage: (value: number) => unknown
  pagination: IPagination
}

export const OrdersList: React.FC<OrdersList> = ({
  handlePrint,
  orders,
  orderStatuses,
  selected,
  setSelected,
  pagination,
  setCurrentPage,
  setPerPage,
}) => {
  const history = useHistory()
  // const checkedAll = (isChecked: boolean) => {
  //   const checkedOrders = {}
  //   orders.forEach(order => (checkedOrders[order.id] = isChecked))
  // }

  const columns = useMemo(
    () =>
      [
        {
          id: 'status',
          Cell: ({ row }) => (
            <div
              className='status'
              style={{ backgroundColor: orderStatuses.find(status => row?.statusId === status.statusId)?.color }}
            />
          ),
        },
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => <CustomCheckbox {...getToggleAllRowsSelectedProps()} />,
          Cell: ({ row }) => {
            return (
              <Box height='100%' display='flex' alignItems='center' onClick={e => e.stopPropagation()}>
                <CustomCheckbox
                  {...row.getToggleRowSelectedProps()}
                  checked={!!selected.find(item => item.id === row.original.id)}
                />
              </Box>
            )
          },
        },
        {
          Header: 'Заказ',
          Cell: ({ row }) => row.original.id,
        },
        {
          Header: 'Статус',
          accessor: 'statusId',
          Cell: ({ row }) => orderStatuses.find(status => row.original?.statusId === status.statusId)?.name ?? '',
        },
        {
          Header: 'Создан',
          accessor: 'dateCreate',
        },
        {
          Header: 'Сотрудник',
          Cell: props => props.row.original.props.find(prop => prop.code === 'CONTACT_PERSON')?.value ?? '',
        },
        {
          Header: 'Сумма',
          Cell: ({ row }) => formatNumber(row.original.price) ?? '' + ' ₽',
        },
        {
          Header: 'Желаемая дата получения',
          Cell: ({ row }) => row.original.props?.find(prop => prop.code === 'DATE_DELIVERY')?.value ?? '',
        },
        {
          Header: 'Дата отгрузки',
        },
        {
          Header: 'Дата получения',
          accessor: 'dateDelivery',
        },
        {
          Header: 'Комментарий',
          accessor: 'userComments',
        },
        {
          Header: 'Печать',
          Cell: props => {
            const { id } = props.row.original
            return (
              <Button
                color='secondary'
                variant='outlined'
                fullWidth={true}
                onClick={e => {
                  e.stopPropagation()
                  handlePrint(id)
                }}
                style={{ width: '65px' }}
              >
                <PrintIcon />
              </Button>
            )
          },
        },
      ] as Column<IOrder>[],
    [selected],
  )

  return (
    <div className='orders-list'>
      <Table
        columns={columns}
        data={orders}
        onRowClick={id => history.push(`order/${id}`)}
        isFooter={false}
        setSelected={setSelected}
      />
      <Box mt='20px' display='flex' justifyContent='center'>
        <TableTools pagination={pagination} onPerPageChange={setPerPage} onPageChange={setCurrentPage} />
      </Box>
      {/*<table>*/}
      {/*  <thead>*/}
      {/*    <tr>*/}
      {/*      <th />*/}
      {/*      <th>*/}
      {/*        <div className='orders-list__checkbox'>*/}
      {/*          <CheckBox style='checkbox' onChange={isChecked => checkedAll(isChecked)} checked={false} />*/}
      {/*        </div>*/}
      {/*      </th>*/}
      {/*      <th>Заказ</th>*/}
      {/*      <th>Статус</th>*/}
      {/*      <th>Создан</th>*/}
      {/*      <th>Сотрудник</th>*/}
      {/*      <th>Сумма </th>*/}
      {/*      <th>Желаемая дата получения</th>*/}
      {/*      <th>Дата отгрузки</th>*/}
      {/*      <th>Дата получения</th>*/}
      {/*      <th>Комментарий</th>*/}
      {/*      <th>Печать</th>*/}
      {/*    </tr>*/}
      {/*  </thead>*/}
      {/*  <tbody>*/}
      {/*    {orders?.map(order => (*/}
      {/*      <tr onClick={() => history.push(`order/${order.id}`)} key={order.id}>*/}
      {/*        <td>*/}
      {/*          <div*/}
      {/*            className='status'*/}
      {/*            style={{ backgroundColor: orderStatuses.find(status => order?.statusId === status.statusId)?.color }}*/}
      {/*          />*/}
      {/*        </td>*/}
      {/*        <td onClick={e => e.stopPropagation()}>*/}
      {/*          <div className='orders-list__checkbox'>*/}
      {/*            <CheckBox style='checkbox' onChange={() => {}} checked={false} />*/}
      {/*          </div>*/}
      {/*        </td>*/}
      {/*        <td>{order.id}</td>*/}
      {/*        <td>{orderStatuses.find(status => order?.statusId === status.statusId)?.name}</td>*/}
      {/*        <td>{order.dateCreate}</td>*/}
      {/*        <td>{order.props.find(prop => prop.code === 'CONTACT_PERSON')?.value}</td>*/}
      {/*        <td>{order.price} ₽</td>*/}
      {/*        <td>{order.dateDelivery}</td>*/}
      {/*        <td />*/}
      {/*        <td />*/}
      {/*        <td>{order.userComments}</td>*/}
      {/*        <td*/}
      {/*          onClick={e => {*/}
      {/*            e.stopPropagation()*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <div className='print-btn'>*/}
      {/*            <Button color='secondary' variant='outlined' fullWidth={true} onClick={() => handlePrint(order.id)}>*/}
      {/*              <PrintIcon />*/}
      {/*            </Button>*/}
      {/*          </div>*/}
      {/*        </td>*/}
      {/*      </tr>*/}
      {/*    ))}*/}
      {/*  </tbody>*/}
      {/*</table>*/}
      {/*<div className='inscription'>Итого 7 заказов на сумму 123₽</div>*/}
    </div>
  )
}
