import React from 'react'
import './ModalDefault.scss'
import { CSSTransition } from 'react-transition-group'
import { ExitButtonWhite } from '../../../components/exitButtonWhite'

interface ModalDefault {
  isOpen: boolean
  handleOpen: (isOpen: boolean) => void
}

export const ModalDefault: React.FC<ModalDefault> = ({ children, isOpen, handleOpen }) => {
  return (
    <CSSTransition in={isOpen} timeout={200} classNames='show-el' unmountOnExit>
      <div className='modal-default' onClick={() => handleOpen(false)}>
        <div className='modal-default__window'>
          <div className='modal-default__content' onClick={e => e.stopPropagation()}>
            {children}
          </div>
          <ExitButtonWhite handleClick={() => handleOpen(false)} />
        </div>
      </div>
    </CSSTransition>
  )
}
