let BASE_URL =
  (process.env.REACT_APP_ENVIRONMENT === 'stage' && 'https://api-stage.informat.ru/v1') ||
  (process.env.REACT_APP_ENVIRONMENT === 'development' && 'https://api.vmxhost.intravision.ru/v1') ||
  (process.env.REACT_APP_ENVIRONMENT === 'devAPI' && 'https://dev.xhost1.intravision.ru/v1') ||
  (process.env.REACT_APP_ENVIRONMENT === 'production' && 'https://api.informat.ru/v1')

export const URL = `${BASE_URL}`

//Public
export const LOGIN = `${URL}/login`
export const FORGOT = `${URL}/forgot`
export const FORGOT_CONFIRM = (checkword: string, login: string) =>
  `${URL}/forgot/confirm?checkword=${checkword}&login=${login}`

//IUser
export const GET_USER = `${URL}/user`
export const LOGOUT = `${URL}/logout`

//Section
export const GET_SECTIONS = `${URL}/section`
export const GET_SECTION = (id: number) => `${URL}/section/${id}`

//Product
export const GET_PRODUCTS = `${URL}/product`
export const GET_PRODUCT_SORTING = `${URL}/product/sorting`
export const POST_PRODUCT_STOCK = `${URL}/product/stock`
export const GET_PRODUCT_SELECTORS = `${URL}/product/selector`
export const POST_PRODUCT_LOCATION = `${URL}/product/location`
export const GET_PRODUCT = (id: number) => `${URL}/product/${id}`
export const GET_PRODUCT_IDS = `${URL}/product/ids`
export const GET_PRODUCT_BREADCRUMBS = (id: number) => `${URL}/product/${id}/breadcrumbs`
export const POST_PRODUCT_PRICE = `${URL}/product/price`
export const GET_PRODUCT_ANALOGS = (id: number) => `${URL}/product/${id}/analogs`

//UrlRewrite
export const GET_URL_REWRITE = `${URL}/urlrewrite/catalog`

//Basket
export const GET_BASKET = `${URL}/basket`
export const POST_BASKET = `${URL}/basket`
export const DELETE_BASKET = (id: number) => `${URL}/basket/${id}`
export const PUT_PRODUCT = (id: number) => `${URL}/basket/${id}`
export const RECALCULATE = `${URL}/basket/recalculate`
export const IMPORT = `${URL}/basket/import`

//Order
export const GET_ORDERS = `${URL}/order`
export const GET_ORDER = (id: number) => `${URL}/order/${id}`
export const GET_ORDERS_PROPS = `${URL}/order/props`
export const GET_ORDER_PRODUCTS = `${URL}/order/list`
export const REORDER = (id: number) => `${URL}/order/${id}/reorder`

//Info
export const BACKGROUND = `${URL}/info/background`
export const FEEDBACK = `${URL}/info/feedback`
export const INFORMER = `${URL}/info/informer`

//Goodscode
export const GOODSCODE = (path: string) => `${URL}/${path}`

//Store

//Location

//Price Group
