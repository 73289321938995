import './App.scss'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { shallowEqual } from 'react-redux'
import { useEffect } from 'react'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'
import { SnackbarProvider } from 'notistack'
import { Banner } from './components/banner'
import { PrivateRoute } from './components/privateRoute'
import { Header } from './containers/header'
import { Catalog } from './pages/catalog'
import { Goodscode } from './pages/goodscode'
import { News } from './pages/news'
import { NewsDetail } from './pages/newsDetail'
import { Search } from './pages/search'
import { Basket } from './pages/basket'
import { Orders } from './pages/orders'
import { Order } from './pages/order'
import { Login } from './pages/login'
import { useTypedSelector } from './hooks/useTypedSelector'
import { useActions } from './hooks/useActions'
import { fetchUser } from './redux/actions/authActions'
import { useAppDispatch } from './hooks/useAppDispatch'
import { getInformers } from './redux/actions/infoActions'
import { theme } from './settings/theme'
import { useGaTracker } from './hooks/useGaTracker'
import { Feedback } from './pages/feedback'
import { Forgot } from './pages/forgot'
import { Main } from './pages/main'

export const App = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { fetchBasket } = useActions()
  const authorization = useTypedSelector(state => state.authReducer.authorization, shallowEqual)
  const { bannersCount } = useTypedSelector(state => state.infoReducer)

  useGaTracker()

  useEffect(() => {
    if (authorization !== '') {
      dispatch(fetchUser())
      fetchBasket()
      dispatch(getInformers())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorization])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <div className='app-wrapper'>
            {location.pathname === ('' || '/') && <Redirect to='/' />}
            {location.pathname !== '/forgot/confirm' && <Header />}
            {location.pathname !== '/forgot/confirm' && location.pathname !== '/login' && bannersCount > 0 && (
              <Banner />
            )}
            <PrivateRoute exact path='/'>
              <Main />
            </PrivateRoute>
            <Route exact path='/login' component={Login} />
            <PrivateRoute exact path='/catalog/:section?/:subsection?/:topLvlSection?/:productSlug?'>
              <Catalog />
            </PrivateRoute>
            <PrivateRoute exact path='/basket'>
              <Basket />
            </PrivateRoute>
            <PrivateRoute exact path='/orders'>
              <Orders />
            </PrivateRoute>
            <PrivateRoute exact path='/order/:id'>
              <Order />
            </PrivateRoute>
            <PrivateRoute exact path='/news'>
              <News />
            </PrivateRoute>
            <PrivateRoute exact path='/news/:id'>
              <NewsDetail />
            </PrivateRoute>
            <PrivateRoute exact path='/search'>
              <Search />
            </PrivateRoute>
            <PrivateRoute exact path='/feedback'>
              <Feedback />
            </PrivateRoute>
            <PrivateRoute exact path='/forgot/confirm'>
              <Forgot />
            </PrivateRoute>
            <PrivateRoute exact path='/goodscode/:id'>
              <Goodscode />
            </PrivateRoute>
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  )
}
