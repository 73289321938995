import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BANNER_HIDE_IDS, IInformer } from '../../types/models/info'
import { getInformers } from '../actions/infoActions'

interface InfoState {
  informers: IInformer[]
  isLoading: boolean
  hideIds: number[]
  bannersCount: number
}

const initialState: InfoState = {
  informers: [],
  bannersCount: 0,
  isLoading: false,
  hideIds: (JSON.parse(localStorage.getItem(BANNER_HIDE_IDS)) || []) as number[],
}

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    hideBanner: (state, action: PayloadAction<number>) => {
      state.hideIds.push(action.payload)
      const newBanners = state.informers.filter(banner => banner.id !== action.payload)
      state.informers = newBanners
      state.bannersCount = newBanners.length
      localStorage.setItem(BANNER_HIDE_IDS, JSON.stringify(state.hideIds))
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getInformers.pending, state => {
        state.isLoading = true
      })
      .addCase(getInformers.fulfilled, (state, action: PayloadAction<IInformer[]>) => {
        const newBanners = action.payload.filter(banner => !state.hideIds.includes(banner.id))

        state.isLoading = false
        state.informers = newBanners
        state.bannersCount = newBanners.length
      })
      .addCase(getInformers.rejected, state => {
        state.isLoading = false
      })
  },
})

export const infoReducer = infoSlice.reducer
export const { hideBanner } = infoSlice.actions
