import React, { ReactElement } from 'react'
import { ButtonUnstyled, ButtonUnstyledProps } from '@mui/material'
import { styled } from '@mui/material/styles'

interface TextButton extends ButtonUnstyledProps {
  startIco?: ReactElement
  endIco?: ReactElement
}

export const Button = styled(ButtonUnstyled)(({ theme }) => ({
  display: 'flex',
  columnGap: '10px',
  fontSize: '14px',
  fontWeight: theme.typography.fontWeightRegular,
  alignItems: 'center',
}))

export const TextButton: React.FC<TextButton> = ({ children, startIco, endIco, ...props }) => {
  return (
    <Button {...props}>
      {startIco}
      {children}
      {endIco}
    </Button>
  )
}
