import { Autocomplete } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  outline: 'none !important',
  '.Mui-focused': {
    borderColor: '1px solid black',
  },
  '& .MuiOutlinedInput-root': {
    height: '40px',
    padding: '0 5px',
    fontSize: '14px',

    '& fieldset': {
      borderColor: 'black',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E5E5E5',
    },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E5E5E5',
    },
  },
}))
