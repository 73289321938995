import React from 'react'
import './OrderFilter.scss'
import { StyledSwitch } from '../../commons/mui/Styled'

export const OrderFilter = () => {
  return (
    <div className='order-filter'>
      <div className='order-filter__container'>
        <span className='order-filter__title'>Фильтр</span>
        <div className='order-filter__item'>
          <span className='title'>Юридическое лицо</span>
          <button className='select-button'>
            <span className='select-button__text'>Выберите ридическое лицо...</span>
          </button>
        </div>

        <div className='order-filter__item'>
          <span className='title'>Сотрудник</span>
          <button className='select-button'>
            <span className='select-button__text select-button__text_active'>Тетрадкина Махрипа Хрипулаевная</span>
          </button>
        </div>

        <div className='order-filter__item'>
          <div className='date-container'>
            <div className='left-col'>
              <span className='title'>Дата конца</span>
              <div className='date-picker'>
                <span>дд/мм/гг</span>
              </div>
            </div>
            <div className='right-col'>
              <span className='title'>Дата конца</span>
              <div className='date-picker'>
                <span>дд/мм/гг</span>
              </div>
            </div>
          </div>
        </div>

        <div className='order-filter__toggle-block'>
          <div className='item'>
            <StyledSwitch onChange={null} checked={false} />
            <span>Только активные заказы</span>
          </div>
        </div>

        <div className='catalog-filter__btn-block'>
          <div className='filter-button-block'>
            <button className='filter-button-block__btn-apply'>Применить</button>
            <button className='filter-button-block__btn-reset'>Сбросить</button>
          </div>
        </div>
      </div>
    </div>
  )
}
