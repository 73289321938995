export const formatText = (text: string): string => {
  if (text) {
    const map = {
      '&amp;': '&',
      '&quot;': '"',
      '&apos;': '\'',
      '&gt;': '>',
      '&lt;': '<'
    }
    for(const key in map) {
      text = text.replaceAll(key,map[key])
    }
  }

  return text ? text : ''
}
