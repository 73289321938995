import React, { FC, useEffect, useState } from 'react'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { StyledPaper } from '../../commons/mui/Styled'
import { BasicModal } from '../../commons/mui/BasicModal'
import logo from '../../assets/png/surveyLogo.svg'
import surveyThanks from '../../assets/png/surveyThanks.svg'
import { ExitIco, SurveyIcon } from '../../commons/Icons'
import { DefaultButton } from '../../commons/mui/Button/DefaultButton'
import { IOrderSurvey } from '../../types/models'

interface IProps {
  open: string | null
  setOpen: (bl: string | null) => void
  postSurvey: (dt: IOrderSurvey) => void
  isPostSurveySuccess?: boolean
}

const OrderSurvey: FC<IProps> = props => {
  const history = useHistory()
  const { open, setOpen, postSurvey, isPostSurveySuccess = false } = props
  const [hovered, setHovered] = useState(null)
  const [finish, setFinish] = useState<null | number>(null)
  const rateList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const { control, register, handleSubmit, getValues } = useForm()

  const onSubmit = async data => {
    if (typeof data?.range === 'number') {
      postSurvey({ order_id: open, rating: data?.range, comment: data?.comment })
    }
  }

  useEffect(() => {
    if (isPostSurveySuccess) {
      const range = getValues('range')
      history.replace('/orders')
      setFinish(range)
    }
  }, [isPostSurveySuccess])

  return (
    <BasicModal open={!!open} handleClose={() => setOpen(null)} exitButton={false}>
      <StyledPaper
        radius='big'
        sx={{
          borderRadius: '30px',
          backgroundColor: theme => '#FFCE21',
        }}
        maxWidth='900px'
        height={'515px'}
        position={'relative'}
        hideOverflowX={true}
      >
        <Box
          position={'absolute'}
          right={'45px'}
          top={'38px'}
          width='25px'
          height='25px'
          role='button'
          onClick={() => setOpen(null)}
          style={{ cursor: 'pointer' }}
        >
          <ExitIco />
        </Box>
        <form style={{ height: '100%' }} autoComplete='no' onSubmit={handleSubmit(onSubmit)}>
          {typeof finish !== 'number' ? (
            <Stack
              paddingRight={'80px'}
              paddingLeft={'80px'}
              direction={'column'}
              alignItems={'center'}
              sx={{ height: '100%' }}
            >
              <Box sx={{ mx: 'auto', width: 'min-content', mt: '30px', mb: '10px', px: '10px', height: '100%' }}>
                <Box sx={{ width: 'min-content', mx: 'auto' }}>
                  <img
                    src={logo}
                    alt={'logo'}
                    width={'206px'}
                    height={'62px'}
                    style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '35px' }}
                  />
                </Box>
                <Typography textAlign={'center'} variant={'h2'} fontSize={'30px'} fontWeight={600}>
                  {'Порекомендуете ли вы данный сайт INFORMAT другим клиентам, коллегам, партнерам, друзьям?'}
                </Typography>
                <Controller
                  control={control}
                  name={'range'}
                  render={({ field }) => (
                    <Stack mt={'35px'} px={'9px'} pb={'30px'} mb={'20px'} position={'relative'} direction={'row'}>
                      {rateList?.map(p => (
                        <Box
                          sx={{ cursor: 'pointer', '.filled': { 'path:first-of-type': { fill: '#fbb03b' } } }}
                          onClick={() => field.onChange(p)}
                          onMouseOver={() => setHovered(p)}
                          onMouseLeave={() => setHovered(null)}
                          paddingRight={'10px'}
                        >
                          <Box
                            sx={{ position: 'relative' }}
                            className={(typeof hovered === 'number' ? hovered >= p : field?.value >= p) ? 'filled' : ''}
                            width={'52px'}
                            height={'50px'}
                          >
                            <Typography
                              sx={{
                                userSelect: 'none',
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                display: 'block',
                                textAlign: 'center',
                                fontSize: '25.61px',
                                lineHeight: '52px',
                                fontWeight: 600,
                                m: 'auto',
                                alignContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {p}
                            </Typography>
                            <SurveyIcon />
                          </Box>
                        </Box>
                      ))}
                      <Typography
                        position={'absolute'}
                        width={'min-content'}
                        noWrap={true}
                        left={0}
                        bottom={0}
                        fontWeight={'600'}
                        fontSize={'16px'}
                      >
                        Точно нет
                      </Typography>
                      <Typography
                        position={'absolute'}
                        fontWeight={'600'}
                        width={'min-content'}
                        noWrap={true}
                        right={0}
                        bottom={0}
                        mr={'10px'}
                        fontSize={'16px'}
                      >
                        Точно да
                      </Typography>
                    </Stack>
                  )}
                />
                <Controller
                  control={control}
                  name={'comment'}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        maxWidth: '687px',
                        '.MuiInputBase-root': {
                          borderRadius: '15px',
                          height: 'unset',
                          fontSize: '18px',
                        },
                        '& fieldset': { border: '0px !important' },
                        '.MuiOutlinedInput-root': {
                          backgroundColor: 'white !important',
                          boxShadow: '10px 8px 10px rgba(0, 0, 0, 0.3)',
                        },
                      }}
                      {...field}
                      multiline={true}
                      minRows={2}
                      maxRows={2}
                    />
                  )}
                />
              </Box>
              <DefaultButton
                type={'submit'}
                color='primary'
                sx={{
                  height: '48px',
                  padding: '15px 45px',
                  borderRadius: '7.2px',
                  boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',
                  mb: '30px',
                  color: theme => theme.palette.primary.main,
                  backgroundColor: 'black',
                  textTransform: 'uppercase',
                  fontSize: '30px',
                  '&:hover': {
                    backgroundColor: 'black',
                  },
                }}
              >
                Отправить
              </DefaultButton>
            </Stack>
          ) : (
            <Stack direction={'column'} sx={{ mx: 'auto' }}>
              <Typography
                sx={{ textTransform: 'uppercase' }}
                textAlign={'center'}
                variant={'h2'}
                mt={'50px'}
                fontSize={'30px'}
                fontWeight={600}
              >
                Спасибо за ваш отзыв!
              </Typography>
              <Stack direction={'row'} gap={'10px'} mt={'25px'} mb={'7px'} sx={{ mx: 'auto' }}>
                {rateList?.map(p => (
                  <Box sx={{ cursor: 'default', '.filled': { 'path:first-of-type': { fill: '#fbb03b' } } }}>
                    <Box
                      sx={{ position: 'relative' }}
                      className={finish >= p ? 'filled' : ''}
                      width={'52px'}
                      height={'50px'}
                    >
                      <Typography
                        sx={{
                          userSelect: 'none',
                          position: 'absolute',
                          left: 0,
                          right: 0,
                          top: 0,
                          bottom: 0,
                          display: 'block',
                          textAlign: 'center',
                          fontSize: '25.61px',
                          lineHeight: '52px',
                          fontWeight: 600,
                          m: 'auto',
                          alignContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {p}
                      </Typography>
                      <SurveyIcon />
                    </Box>
                  </Box>
                ))}
              </Stack>
              <Box mt={'25px'}>
                <img src={surveyThanks} width={'901px'} height={'321px'} alt={'surveyThanks'} />
              </Box>
            </Stack>
          )}
        </form>
      </StyledPaper>
    </BasicModal>
  )
}

export default OrderSurvey
