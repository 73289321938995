import { Box, FormControlLabel, Typography } from '@mui/material'
import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import { DefaultButton } from '../../commons/mui/Button/DefaultButton'
import { CustomCheckbox } from '../../commons/mui/Checkbox/CustomCheckbox'
import { TextFormat } from '../../commons/TextFromat/TextFormat'
import { IPagination } from '../../types/commons'
import { IBasketItem, IProduct, IProductAnalog, ISpecification } from '../../types/models'
import { formatNumber } from '../../utils/formatNumber'

interface AnalogsModalProps {
  product: IProduct
  basketList: IBasketItem[]
  specifications: ISpecification[]
  analogProducts: IProductAnalog[]
  pagination: IPagination
  pageChange: (page: number) => void
  cancelHandler: () => void
  productReplacement: (newProductId: number) => void
}

export const AnalogsModal = ({
  product,
  basketList,
  specifications,
  analogProducts,
  pagination,
  pageChange,
  cancelHandler,
  productReplacement,
}: AnalogsModalProps): JSX.Element => {
  const [isDetails, setIsDetails] = useState<boolean>(true)

  const basketItem = basketList.find(item => item.productId === product.id)
  if (!basketItem) {
    return null
  }

  const handleChangePage = ({ selected }) => {
    pageChange(selected + 1)
  }

  return (
    <Box width={analogProducts.length ? 680 : 550} p='20px'>
      {analogProducts?.length ? (
        <>
          <Box pb='20px' borderBottom='1px solid #E0E0E0'>
            <Box display='flex' alignItems='center' mb='20px'>
              <img src={product.pictureDetail} alt='img' height='35px' width='35px' />
              <Typography ml='10px' fontWeight={600} fontSize={14} lineHeight='18px' letterSpacing='1%'>
                <TextFormat text={basketItem.name} stringNumber={1} isInner />
              </Typography>
            </Box>
            {isDetails && (
              <Typography fontSize={12} mb='10px'>
                Подобраны аналоги по точному совпадению следующих характеристик:
              </Typography>
            )}
            <Box
              display='flex'
              justifyContent={specifications.length && isDetails ? 'space-between' : 'flex-end'}
              alignItems='flex-end'
            >
              {isDetails && (
                <div>
                  {specifications.map(specification => (
                    <Box
                      key={specification.id}
                      display='flex'
                      alignItems='center'
                      fontSize={12}
                      lineHeight='15px'
                      letterSpacing='0.01em'
                    >
                      <Typography color='#808080'>{specification.name}:</Typography>
                      <Typography ml='5px'>{specification.value}</Typography>
                    </Box>
                  ))}
                </div>
              )}
              <Box display='flex' alignItems='center'>
                <FormControlLabel
                  control={
                    <CustomCheckbox checked={isDetails} onChange={event => setIsDetails(event.target.checked)} />
                  }
                  label='Детали'
                />
                <Typography fontSize={12} lineHeight='15px' color='#A5A5A5' m='0 20px'>
                  {basketItem.externalId}
                </Typography>
                <Box fontWeight={600} fontSize={16}>
                  {formatNumber(basketItem.price)} ₽
                </Box>
              </Box>
            </Box>
          </Box>
          <Box py='30px'>
            <Box display='flex' mb='35px'>
              {analogProducts.map(product => (
                <Box
                  key={product.id}
                  sx={{
                    width: 'calc(33.33% - 12px)',
                    '&:last-child': {
                      marginRight: 0,
                    },
                  }}
                  marginRight='16px'
                  style={{ cursor: 'pointer' }}
                  onClick={() => productReplacement(product.id)}
                >
                  <Box display='flex' justifyContent='center' mb='10px'>
                    <img src={product.pictureDetail} alt='img' height='35px' width='35px' />
                  </Box>
                  <Typography fontWeight={600} fontSize={12} lineHeight='17px' mb='9px'>
                    <TextFormat text={product.name} stringNumber={2} />
                  </Typography>
                  {isDetails && (
                    <Box mb='10px'>
                      {product.specifications.map(specification => (
                        <Box
                          key={specification.id}
                          display='flex'
                          alignItems='center'
                          fontSize={12}
                          lineHeight='15px'
                          letterSpacing='0.01em'
                        >
                          <Typography color='#808080'>{specification.name}:</Typography>
                          <Typography ml='5px'>{specification.value}</Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography fontSize={12} lineHeight='15px' color='#A5A5A5'>
                      {product.externalId}
                    </Typography>
                    {product.price && (
                      <Box fontWeight={600} fontSize={16}>
                        {formatNumber(product.price.price)} ₽
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
            {pagination.totalPages > 1 && (
              <Box display='flex' justifyContent='center'>
                <ReactPaginate
                  pageCount={pagination.totalPages}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handleChangePage}
                  forcePage={pagination.currentPage - 1}
                  breakLabel='...'
                  previousLabel='Назад'
                  previousLinkClassName='paginator__back'
                  nextLabel='Вперед'
                  nextLinkClassName='paginator__next'
                  containerClassName='paginator'
                  pageLinkClassName='paginator__page button'
                  breakLinkClassName='paginator__page button'
                  activeLinkClassName='paginator__page_active'
                />
              </Box>
            )}
          </Box>
          <DefaultButton fullWidth color='secondary' onClick={cancelHandler}>
            Отменить замену
          </DefaultButton>
        </>
      ) : (
        <Box>Аналогов нет</Box>
      )}
    </Box>
  )
}
