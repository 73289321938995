import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { IBasketItem, IProduct } from '../../types/models'
import { DeleteIcon } from '../../commons/Icons'
import { DefaultButton } from '../../commons/mui/Button/DefaultButton'
import { TextFormat } from '../../commons/TextFromat/TextFormat'

interface WarningModal {
  warningProducts: IProduct[]
  deleteId: number
  setDeleteId: (id: number) => void
  handleClose: () => void
  deleteProduct: () => unknown
  setIsAnalogsForm: (productId: number) => void
  basketList: IBasketItem[]
}

const useStyles = makeStyles(() => ({
  item: {
    '&:last-child': {
      border: 'none',
    },
  },
}))

export const WarningModal: React.FC<WarningModal> = ({
  warningProducts,
  setDeleteId,
  basketList,
  setIsAnalogsForm,
}) => {
  const classes = useStyles()

  useEffect(() => {
    return () => {
      setDeleteId(null)
    }
  }, [])

  return (
    <Box width='550px' p='30px 20px 20px 20px'>
      <Typography variant='h2' textAlign='center'>
        Внимание!
      </Typography>
      <Box mt='15px' pb='30px' borderBottom='1px solid rgba(0, 0, 0, 0.1)'>
        <Typography variant='body1' textAlign='center'>
          Корзина содержит товары, которые влияют на статус заказа.
        </Typography>
      </Box>
      {warningProducts.map(product => {
        const basketItem = basketList?.find(item => item.productId === product.id)
        return (
          <Box
            key={product.id}
            p='20px 0'
            display='flex'
            justifyContent='space-between'
            borderBottom='1px solid'
            borderColor='borderGray'
            className={classes.item}
          >
            <Box display='flex'>
              <img src={product.pictureDetail} alt='img' height='35px' width='35px' />
              <Box p='0 29px 0 14px'>
                <Box>
                  <TextFormat stringNumber={3} text={product.name} isInner />
                </Box>
                <Box color='text.secondary' mt='10px'>
                  {product.externalId}
                </Box>
              </Box>
            </Box>
            <Box flex='none' display='flex' columnGap='10px'>
              <DefaultButton
                onClick={() => {
                  setDeleteId(basketItem.id)
                }}
                color='secondary'
                boxShadow={false}
              >
                <DeleteIcon />
              </DefaultButton>
              <DefaultButton onClick={() => setIsAnalogsForm(product.id)}>Заменить на аналог</DefaultButton>
            </Box>
          </Box>
        )
      })}
      {/*<Box mt='30px' display='flex' columnGap='10px'>*/}
      {/*  <DefaultButton fullWidth color='secondary'>*/}
      {/*    Вынести в отдельный отчет*/}
      {/*  </DefaultButton>*/}
      {/*  <DefaultButton fullWidth color='secondary'>*/}
      {/*    Оставить заказ без изменений*/}
      {/*  </DefaultButton>*/}
      {/*</Box>*/}
    </Box>
  )
}
