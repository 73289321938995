import { FC } from 'react'
import { Link, useParams } from 'react-router-dom'
import { newsApi } from '../../api/NewsService'
import Loader from '../../commons/Loader/Loader'
import { BackgroundLayout } from '../../layouts/backgroundLayout'
import './NewsDetail.scss'

export const NewsDetail: FC = () => {
  const { id } = useParams<{ id: string }>()

  const { data, isFetching } = newsApi.useGetNewsByIdQuery(id, {
    skip: !id,
  })

  const detail = data?.result?.data
  const prev = data?.result?.prev
  const next = data?.result?.next

  return (
    <BackgroundLayout>
      <Loader isLoading={isFetching} />
      <div className='news-detail'>
        <div className='news-detail__wrapper'>
          <div className='column-side'>
            {prev && (
              <>
                <div className='column-side__wrapper'>
                  <Link to={`/news/${prev.id}`}>
                    <img src={prev.picture} alt='' />
                  </Link>
                  <div className='column-side__name'>
                    <Link to={`/news/${prev.id}`}>{prev.name}</Link>
                  </div>
                  <span>{prev.activeFrom.split(' ')[0]}</span>
                </div>
                <div className='arrows'>
                  <Link to={`/news/${prev.id}`}>
                    <span className='left'></span>
                  </Link>
                </div>
              </>
            )}
          </div>
          <div className='column'>
            <div className='news-detail__title'>
              <h2>{detail?.name}</h2>
            </div>
            <div className='text-box'>
              {detail?.activeFrom && <span className='news-detail__date'>{detail.activeFrom.split(' ')[0] || ''}</span>}
              <img src={detail?.detailPicture} alt='' />
              <div className='news-detail__content' dangerouslySetInnerHTML={{ __html: detail?.detailText || '' }} />
            </div>
          </div>
          <div className='column-side'>
            {next && (
              <>
                <div className='column-side__wrapper'>
                  <Link to={`/news/${next.id}`}>
                    <img src={next.picture} alt='' />
                  </Link>
                  <div className='column-side__name'>
                    <Link to={`/news/${next.id}`}>{next.name}</Link>
                  </div>
                  <span>{next.activeFrom.split(' ')[0]}</span>
                </div>
                <div className='arrows'>
                  <Link to={`/news/${next.id}`}>
                    <span className='right'></span>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </BackgroundLayout>
  )
}
