import React, { useState } from 'react'
import './BasketOrderDetails.scss'
import { DefaultButton } from '../../../commons/mui/Button/DefaultButton'
import { formatNumber } from '../../../utils/formatNumber'

interface BasketOrderDetails {
  openForm: (isOpen: boolean) => void
  productCount: number
  totalPrice: number
  priceVat: number
  discountPercent: number
  priceNotDiscount: number
  priceSaved: number
  recalculate: () => Promise<void>
}

export const BasketOrderDetails: React.FC<BasketOrderDetails> = ({
  openForm,
  productCount,
  totalPrice,
  priceVat,
  priceSaved,
  discountPercent,
  priceNotDiscount,
  recalculate,
}) => {
  const [isWow, setIsWow] = useState(false)

  const toggleWow = value => {
    setIsWow(value)
  }

  const openFormHandler = async () => {
    await recalculate()
    openForm(true)
  }

  return (
    <div className='basket-order-details'>
      <div className='basket-order-details__container'>
        <div className='title'>Детали заказа</div>
        <table className='table'>
          <thead>
            <tr>
              <th>Детали заказа:</th>
              {/*<th>Вес</th>*/}
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Моя корзина</td>
              {/*<td>##кг</td>*/}
              <td>{formatNumber(totalPrice)} ₽</td>
            </tr>
          </tbody>
        </table>
        {/*<div className='flex-table'>*/}
        {/*  <div className='flex-table__row'>*/}
        {/*    <div>Подитог</div>*/}
        {/*    <div>## ###</div>*/}
        {/*  </div>*/}
        {/*  <div className='flex-table__row'>*/}
        {/*    <div>Скидка</div>*/}
        {/*    <div>## ###</div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className='bonus-block'>*/}
        {/*  <div className='bonus-block__toggle'>*/}
        {/*    <div className='toggle'>*/}
        {/*      <StyledSwitch onChange={toggleWow} checked={isWow} />*/}
        {/*      <span>Использовать бонусы</span>*/}
        {/*    </div>*/}
        {/*    <span className='wow-color'>12 233 ВАУ</span>*/}
        {/*  </div>*/}
        {/*  {isWow && <div className='bonus-block__input'>Укажите кол-во бонусов для списания</div>}*/}
        {/*</div>*/}
        <div className='flex-table'>
          <div className='flex-table__row'>
            <div>Без учета скидок</div>
            <div>{formatNumber(priceNotDiscount)} ₽</div>
          </div>
          <div className='flex-table__row'>
            <div>Сумма скидки в рублях</div>
            <div>{formatNumber(priceSaved)} ₽</div>
          </div>
          <div className='flex-table__row'>
            <div>% скидки</div>
            <div>{discountPercent}</div>
          </div>
          <div className='flex-table__row'>
            <div>НДС</div>
            <div>{formatNumber(priceVat)} ₽</div>
          </div>
          <div className='flex-table__row'>
            <div>Итого к оплате</div>
            <div>{formatNumber(totalPrice)} ₽</div>
          </div>
          {/*<div className='flex-table__row'>*/}
          {/*  <div>Будет начисленно ВАУ</div>*/}
          {/*  <div className='wow-color'>123 123 ВАУ</div>*/}
          {/*</div>*/}
        </div>
        <div className='btn-container'>
          <DefaultButton onClick={openFormHandler} fullWidth color='primary'>
            Сформировать заказ
          </DefaultButton>
        </div>
      </div>
    </div>
  )
}
