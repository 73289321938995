import { Dispatch } from 'redux'
import qs from 'qs'
import {
  ActionGetSections,
  ActionPayloadBoolean,
  ActionPayloadNumber,
  ActionSetBreadCrumbs,
  ActionSetExpandedSectionId,
  ActionSetFilterDirection,
  ActionSetProduct,
  ActionSetProductQ,
  ActionSetProducts,
  ActionSetProductSelectors,
  ActionSetTotalRows,
  ActionTypes,
  CatalogAction,
  IFilters,
} from '../reducers/productReducer'
import { Axios, AXIOS_TIMEOUT } from '../../axios'
import { GET_PRODUCT, GET_PRODUCTS } from '../../settings/endpoints'
import {
  IBreadCrumb,
  IProductDetails,
  IProductQ,
  IProductRes,
  IProductSelector,
  IProductSorting,
} from '../../types/models'
import { IPagination } from '../../types/commons'
import ProductService from '../../api/ProductService'

const urlDecode = (array: any): string => {
  let result = ''
  for (let i = 0; i < array.length; i++) {
    if (array[i].length === 2 && Array.isArray(array[i][0])) {
      result = `${result}filter%5b${array[i][0][0]}%5d%5B`
      result = `${result}${array[i][0][1]}%5D=${array[i][0][2]}&`
      result = `${result}filter%5b${array[i][1][0]}%5d%5B`
      result = `${result}${array[i][1][1]}%5D=${array[i][1][2]}&`
    } else if (array[i].length === 3) {
      result = `${result}filter%5b${array[i][0]}%5d%5B`
      result = `${result}${array[i][1]}%5D=${array[i][2]}&`
    } else if (array[i].length === 0) {
      result = result
    } else {
      console.error('Unexpected filters, check productActions.ts:43 to find out problem')
    }
  }

  if (result[result.length - 1] === '&') {
    result = result.slice(0, -1)
  }
  return result
}

export const productActions = {
  setFilterDirection: (filter: IProductSorting): ActionSetFilterDirection => ({
    type: ActionTypes.SET_FILTER_DIRECTION,
    payload: filter,
  }),

  setSectionId: (id: number): ActionPayloadNumber => ({
    type: ActionTypes.SET_SECTION_ID,
    payload: id,
  }),

  getSections: (category: any[]): ActionGetSections => ({
    type: ActionTypes.GET_SECTIONS,
    payload: category,
  }),

  setProducts: (products: IProductRes['result']): ActionSetProducts => ({
    type: ActionTypes.SET_PRODUCTS,
    payload: products,
  }),

  setProduct: (product: IProductDetails): ActionSetProduct => ({
    type: ActionTypes.SET_PRODUCT,
    payload: product,
  }),

  setIsLoading: (isProductsFetching: boolean): ActionPayloadBoolean => ({
    type: ActionTypes.IS_LOADING,
    payload: isProductsFetching,
  }),

  setCurrentPage: (currentPage: number): ActionPayloadNumber => ({
    type: ActionTypes.SET_CURRENT_PAGE,
    payload: currentPage,
  }),

  setPerPage: (perPage: number): ActionPayloadNumber => ({
    type: ActionTypes.SET_PER_PAGE,
    payload: perPage,
  }),

  setProductQ: (productQ: IProductQ): ActionSetProductQ => ({
    type: ActionTypes.SET_PRODUCT_Q,
    payload: productQ,
  }),

  setProductBreadcrumbs: (breadCrumbs: IBreadCrumb[]): ActionSetBreadCrumbs => ({
    type: ActionTypes.SET_PRODUCT_BREAD_CRUMBS,
    payload: breadCrumbs,
  }),

  setProductSelectors: (selectors: IProductSelector[]): ActionSetProductSelectors => ({
    type: ActionTypes.SET_SELECTORS_LIST,
    payload: selectors,
  }),

  setExpandedSectionId: (sections: number[]): ActionSetExpandedSectionId => ({
    type: ActionTypes.SET_EXPANDED_SECTION_ID,
    payload: sections,
  }),

  setTotalRows: (count: number | null): ActionSetTotalRows => ({
    type: ActionTypes.SET_TOTAL_ROWS,
    payload: count,
  }),

  fetchProducts: (
    sort?: IProductSorting['value'],
    sectionsId?: IFilters['sectionId'],
    currentPage?: IPagination['currentPage'],
    perPage?: number,
    selectors?: string[],
    filters?: any,
    useNewSerializer?: boolean,
    onlyQuantity = false,
  ) => {
    return async (dispatch: Dispatch<CatalogAction>) => {
      let params =
        useNewSerializer === true
          ? {
              sectionId: sectionsId,
              currentPage: currentPage,
              perPage: perPage,
              selector: selectors,
              sort: sort || null,
            }
          : {
              sectionId: sectionsId,
              currentPage: currentPage,
              perPage: perPage,
              selector: selectors,
              sort: sort || null,
              filter: filters,
            }

      try {
        dispatch(productActions.setIsLoading(true))
        const productsRes = await Axios.get<IProductRes>(GET_PRODUCTS, {
          params: params,
          paramsSerializer: params => {
            if (useNewSerializer) {
              return `${qs.stringify(params)}&${urlDecode(filters)}`
            } else {
              return qs.stringify(params)
            }
          },
          timeout: sectionsId ? AXIOS_TIMEOUT : 120000,
        })
        if (onlyQuantity) {
          dispatch(productActions.setTotalRows(productsRes.data.result.pagination.totalRows))
        } else {
          dispatch(productActions.setProducts(productsRes.data.result))
        }
        dispatch(productActions.setIsLoading(false))
      } catch (err) {
        dispatch(productActions.setIsLoading(false))
        return err
      }
    }
  },

  fetchProduct: (productId: number) => {
    return async (dispatch: Dispatch<CatalogAction>) => {
      try {
        dispatch(productActions.setIsLoading(true))
        const [product, breadCrumbs] = await Promise.all([
          Axios.get(GET_PRODUCT(productId)),
          ProductService.getProductBreadCrumbs(productId),
        ])
        dispatch(productActions.setProductBreadcrumbs(breadCrumbs.data.result))
        dispatch(productActions.setProduct(product.data.result))
        dispatch(productActions.setIsLoading(false))
      } catch (err) {
        dispatch(productActions.setIsLoading(false))
        return err
      }
    }
  },

  fetchSelectors: () => {
    return async (dispatch: Dispatch<CatalogAction>) => {
      try {
        const response = await ProductService.getProductSelectors()
        dispatch(productActions.setProductSelectors(response.data.result))
      } catch (err) {
        return err
      }
    }
  },
}
