import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

interface Props extends BoxProps {
  radius?: 'big' | 'small'
  hideOverflowX?: boolean
}

export const StyledPaper = styled(Box)<Props>(({ theme, radius, hideOverflowX }) => ({
  background: '#FFFFFF',
  boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
  maxHeight: '90vh',
  overflowY: 'auto',
  overflowX: hideOverflowX ?  'hidden' : 'auto',
  borderRadius: (radius === 'big' && '15px') || (radius === 'big' && '4px') || '4px',
}))
