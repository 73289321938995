import React from 'react'
import './Input.scss'
import { Field } from 'formik'

interface ICheckbox {
  id?: string
  name?: string
  formik?: boolean
  style: 'checkbox' | 'toggle'
  onChange?: (value: boolean) => void
  checked?: boolean
}

export const CheckBox: React.FC<ICheckbox> = ({ id, name, formik, style, onChange, checked }) => {
  const classNames = (style === 'checkbox' && 'checkbox-input') || (style === 'toggle' && 'toggle-input')
  if (formik) {
    return <Field id={id} type='checkbox' name={name} className={classNames} />
  } else {
    return (
      <input
        id={id}
        type='checkbox'
        name={name}
        className={classNames}
        onChange={e => onChange(e.target.checked)}
        checked={checked ?? false}
      />
    )
  }
}
