import React from 'react'
import { Checkbox, CheckboxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import checkboxIco from './CheckedIco.svg'
import indeterminateIco from './IndeterminateIco.svg'

const BpIcon = styled('span')(({ theme }) => ({
  position: 'relative',
  borderRadius: 4,
  width: 15,
  height: 15,
  background: '#fff',
  border: `1px solid ${theme.palette.borderGray}`,
  'input:hover ~ &': {},
  'input:disabled ~ &': {},
}))

const BpIndeterminateIcon = styled(BpIcon)(({ theme }) => ({
  '&:before': {
    top: -1,
    left: -1,
    position: 'absolute',
    borderRadius: 4,
    display: 'block',
    border: '1px solid #000000',
    width: 15,
    height: 15,
    background: `${theme.palette.borderGray} url("${indeterminateIco}") no-repeat center`,
    backgroundSize: `7px 7px`,
    content: '""',
  },
}))

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  '&:before': {
    top: -1,
    left: -1,
    position: 'absolute',
    borderRadius: 4,
    display: 'block',
    border: '1px solid #000000',
    width: 15,
    height: 15,
    background: `${theme.palette.yellow} url("${checkboxIco}") no-repeat center`,
    content: '""',
  },
}))

export const CustomCheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      sx={{
        padding: '5px',
      }}
      disableRipple
      color='default'
      checkedIcon={<BpCheckedIcon />}
      indeterminateIcon={<BpIndeterminateIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  )
}
