import React from 'react'
import './PriceBlock.scss'
import { formatNumber } from '../../utils/formatNumber'

export interface PriceBlock {
  percentDiscount: number
  priceDiscount: number
  fullPrice: number
  layout: 'table' | 'card'
}

export const PriceBlock: React.FC<PriceBlock> = ({ percentDiscount, priceDiscount, fullPrice, layout }) => {
  const formatPrice = formatNumber(fullPrice)
  const formatPriceDiscount = formatNumber(priceDiscount)

  if (layout === 'table')
    return (
      <>
        {percentDiscount !== 0 ? (
          <div className='price-block'>
            {percentDiscount && <div className='price-block__percent'>-{percentDiscount?.toFixed(0)}%</div>}
            <div className='price-block__discount'>{formatPriceDiscount}</div>
            <div className='price-block__full'>{formatPrice}</div>
          </div>
        ) : (
          <div>{formatPrice}</div>
        )}
      </>
    )

  if (layout === 'card')
    return (
      <div className='price-block'>
        {percentDiscount !== 0 ? (
          <>
            <div className='price-block__discount price-card'>{formatPriceDiscount}</div>
            <div className='price-block__labels'>
              {percentDiscount && <div className='price-block__percent'>-{percentDiscount?.toFixed(0)}%</div>}
              <div className='price-block__full'>{formatPrice}</div>
            </div>
          </>
        ) : (
          <div className='price-card'>{formatPrice}</div>
        )}
      </div>
    )
}
