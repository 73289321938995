import React from 'react'
import './Loader.scss'
import { Fade, Modal } from '@mui/material'

interface Loader {
  isLoading: boolean
}

const Loader: React.FC<Loader> = ({ isLoading }) => {
  return (
    <Modal open={isLoading}>
      <Fade in={isLoading}>
        <div className='loader-container'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='100px'
            height='100px'
            viewBox='0 0 100 100'
            preserveAspectRatio='xMidYMid'
            className='loader-container__loader'
          >
            <circle
              cx='50'
              cy='50'
              fill='none'
              stroke='#fedb43'
              strokeWidth='10'
              r='35'
              strokeDasharray='164.93361431346415 56.97787143782138'
            >
              <animateTransform
                attributeName='transform'
                type='rotate'
                repeatCount='indefinite'
                dur='1.3157894736842106s'
                values='0 50 50;360 50 50'
                keyTimes='0;1'
              />
            </circle>
          </svg>
        </div>
      </Fade>
    </Modal>
  )
}

export default Loader
