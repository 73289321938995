import React from 'react'
import './StockInfo.scss'

export const StockInfo = () => {
  return (
    <div className='stock-info'>
      <div className='stock-info__item'>
        <span className='stock-info__status_full'>Предостаточно</span>

        <div className='info-list'>
          <div className='info-list__title'>Для заказов с товарами ТОЛЬКО такого статуса:</div>
          <div className='info-list__options-block'>
            <div className='info-list__option-item'>
              <div className='info-list__ico' />
              <div className='info-list__options'>
                Счета на оплату генерируются автоматически и доступны в ЛК (срок действия — 4 дня)
              </div>
            </div>

            <div className='info-list__option-item'>
              <div className='info-list__ico' />
              <div className='info-list__options'>Резервирование на складе производится автоматически (на 4 дня)</div>
            </div>

            <div className='info-list__option-item'>
              <div className='info-list__ico' />
              <div className='info-list__options'>Участие оператора нетребуется</div>
            </div>
          </div>
        </div>
      </div>

      <div className='stock-info__item'>
        <span className='stock-info__status_normal'>Нормально</span>

        <div className='info-list'>
          <div className='info-list__title'>Заказы, в которых есть заказы с такими статусами:</div>
          <div className='info-list__options-block'>
            <div className='info-list__option-item'>
              <div className='info-list__ico' />
              <div className='info-list__options'>
                Счета на оплату генерируются автоматически и доступны в ЛК (на сутки) действия — 4 дня)
              </div>
            </div>

            <div className='info-list__option-item'>
              <div className='info-list__ico' />
              <div className='info-list__options'>Резервирование на складе производится автоматически (на сутки)</div>
            </div>

            <div className='info-list__option-item'>
              <div className='info-list__ico' />
              <div className='info-list__options'>Участие оператора нетребуется</div>
            </div>
          </div>
        </div>
      </div>

      <div className='stock-info__item'>
        <span className='stock-info__status_little'>Уменьшается</span>

        <div className='info-list'>
          <div className='info-list__title'>Заказы, в которых есть заказы с такими статусами:</div>
          <div className='info-list__options-block'>
            <div className='info-list__option-item'>
              <div className='info-list__ico' />
              <div className='info-list__options'>
                Счета на оплату генерируются автоматически и доступны в ЛК (на сутки)
              </div>
            </div>

            <div className='info-list__option-item'>
              <div className='info-list__ico' />
              <div className='info-list__options'>Резервирование на складе производится автоматически (на сутки)</div>
            </div>

            <div className='info-list__option-item'>
              <div className='info-list__ico' />
              <div className='info-list__options'>Участие оператора нетребуется</div>
            </div>
          </div>
        </div>
      </div>

      <div className='stock-info__item'>
        <span className='stock-info__status_few'>Мало</span>

        <div className='info-list'>
          <div className='info-list__title'>
            Если у вас в заказе есть товар с таким статусом, его актуальность будет подтверждаться вручную.
          </div>
        </div>
      </div>
    </div>
  )
}
